/**
 * Функция для генерации data- атрибутов
 */
export const generateAttributes = (attributes) => {
    return attributes.reduce((acc, { key, value }) => {
        acc[key] = value;
        return acc;
    }, {});
};

/**
 * Горизонтальная прокрутка колёсиком мыши.
 */
export const addHorizontalScroll = (element) => {
    const handleWheel = (event) => {
        if (event.deltaY !== 0) {
            event.preventDefault();
            element.scrollLeft += event.deltaY;
        }
    };

    element.addEventListener('wheel', handleWheel);

    return () => {
        element.removeEventListener('wheel', handleWheel);
    };
};

/**
 * Обрезает строку и добавляет ... в конце строки
 */
export const truncateWithEllipsis = (str, maxLength) => {
    if (str.length > maxLength) {

        return str.slice(0, maxLength) + "...";
    }

    return str;
}

/**
 * Обрезает строку
 */
export const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {

        return str.slice(0, maxLength);
    }

    return str;
}

/**
 * Добавить перенос строки, если длина слова превышает
 */
export const addLineBreakIfLongWord = (text, maxLength) => {
    let words = text.split(' ');

    for (let i = 0; i < words.length; i++) {
        if (words[i].length > maxLength) {
            words[i] = words[i].replace(/(.{35})/g, '$1\n');
        }
    }

    return words.join(' ');
}