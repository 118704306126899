export const IconConnectedPeople = ({color = '#000000', size = 100}) => {

    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.28 122.88" width={size} height={size}>
            <title>connected-people</title>
            <path
                d="M49.46,44.65l14.61-7.58a28,28,0,1,1,6.65,10.61c-.54-.54-1-1.1-1.55-1.69L54.45,53.64A28.15,28.15,0,0,1,55,69.5l16.42,8.2a29.16,29.16,0,0,1,2.22-2.5,27.85,27.85,0,1,1-7,11.63l-16.06-8a28.23,28.23,0,0,1-2.88,3.38,27.93,27.93,0,1,1,0-39.5c.62.63,1.22,1.28,1.78,2Zm27.46,66c2.28-2,7.16-2.49,10.11-4.26,2.61-1.56,2.17-3.14,2.15-5.73H85c-10.77,0-3.68.84-2.22-10.86,2.19-16.52,18.84-16.53,21.29,0,1.57,11.26,8.33,10.86-2.21,10.86H97.69c0,2.86-.46,4.33,2.51,5.94,2.22,1.19,7.24,2,9.65,4a22.7,22.7,0,1,0-32.93.09ZM11,77.52c3.06-1.76,7.63-1.31,10.62-3.4A8.15,8.15,0,0,0,22.14,73c.28-.65.53-1.35.7-1.82A22.34,22.34,0,0,1,21,68.61l-1.85-3a5.36,5.36,0,0,1-1-2.7,2.14,2.14,0,0,1,.18-1,1.76,1.76,0,0,1,.64-.74,1.9,1.9,0,0,1,.45-.23,44.32,44.32,0,0,1-.09-5.3,7.25,7.25,0,0,1,.23-1.2,7.06,7.06,0,0,1,3.13-4,10.1,10.1,0,0,1,2.62-1.17c.59-.16-.5-2,.11-2.11,2.94-.3,7.7,2.39,9.76,4.61A7.19,7.19,0,0,1,37,56.41l-.12,4.81h0a1.34,1.34,0,0,1,1,1,4.13,4.13,0,0,1-.51,2.53h0l0,.06-2.11,3.49a18.37,18.37,0,0,1-2.6,3.57c.1.13.19.26.28.4a12.22,12.22,0,0,0,1.38,1.77l.05.06c2.43,1.72,8.35,2.14,10.62,3.4h0a22.71,22.71,0,1,0-33.94,0ZM73.13,42.6l.3-.23c3.06-1.81,7.68-1.34,10.69-3.44a7.74,7.74,0,0,0,.56-1.09c.28-.64.54-1.34.7-1.82a21.79,21.79,0,0,1-1.83-2.61l-1.86-2.95a5.39,5.39,0,0,1-1-2.69,2.11,2.11,0,0,1,.18-1,1.76,1.76,0,0,1,.64-.74,2.12,2.12,0,0,1,.45-.23,48.94,48.94,0,0,1-.09-5.3,7.25,7.25,0,0,1,.23-1.2,7.1,7.1,0,0,1,3.13-4,9.7,9.7,0,0,1,2.63-1.17c.59-.17-.5-2,.1-2.11,3-.31,7.71,2.38,9.76,4.61a7.19,7.19,0,0,1,1.82,4.54L99.38,26h0a1.33,1.33,0,0,1,1,1,4.13,4.13,0,0,1-.51,2.53h0s0,0,0,.06L97.7,33.11a17.64,17.64,0,0,1-2.59,3.57l.28.41a11.56,11.56,0,0,0,1.38,1.77.21.21,0,0,1,0,.06c2.43,1.72,8.35,2.13,10.63,3.4l.09,0,.28.23a22.71,22.71,0,1,0-34.68,0Z"
                fill={color}
            />
        </svg>
    );
}