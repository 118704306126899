import {isSameDay, isThisYear, isToday} from "date-fns";
import {formatInTimeZone} from 'date-fns-tz';

// Текущая дата и время в Лондоне
export const getCurrentTimestampInLondon = () => {
    const now = new Date();
    const timeZone = 'Europe/London'; // Часовой пояс Лондона

    const datePart = formatInTimeZone(now, timeZone, 'yyyy-MM-dd');
    const timePart = formatInTimeZone(now, timeZone, 'HH:mm:ss.SSS');
    const microseconds = '000000'; // Date object only goes down to milliseconds

    return `${datePart} ${timePart}${microseconds}`;
};

// Текущее время Unix-эпохи в UTC
export const getCurrentUnixInUTS = () => {
    return Date.now();
};

// Например: '09 July' или 09 July 2024
export const formatDateDMOrDMY = (dateString)  => {
    const date = new Date(dateString);
    const timeZone = getUserTimeZone(); // Часовой пояс Лондона
    if (isThisYear(date)) {
        return formatInTimeZone(date,timeZone,  'dd MMMM'); // Например: '09 июня'
    } else {
        return formatInTimeZone(date, timeZone, 'dd MMMM yyyy'); // Например: '09 июня 2024'
    }
}

// Например: '14:30' или '09 июля 2024 14:30'
export const formatDateTimeOrDMY = (dateString) => {
    const date = new Date(dateString);
    const timeZone = getUserTimeZone();

    if (isToday(date)) {
        return formatInTimeZone(date, timeZone, 'HH:mm'); // Например: '14:30'
    } else {
        return formatInTimeZone(date, timeZone, 'dd MMMM yyyy HH:mm'); // Например: '09 июля 2024 14:30'
    }
};

// Функция для преобразования времени из Лондона в текущее
export const formatToFullLondonDate = (dateString) => {
    const date = new Date(dateString);
    const timeZone = getUserTimeZone();
    return formatInTimeZone(date, timeZone, 'dd MMMM yyyy HH:mm'); // Например: '09 июля 2024 14:30'
}

export const calculateTimeDifference = (dateString) => {
    const currentDate = new Date(); // текущая дата
    const targetDate = new Date(dateString); // дата, которую вы получили

    // Вычисляем разницу в миллисекундах
    const differenceInMilliseconds = targetDate - currentDate;

    // Переводим миллисекунды в дни, часы и минуты
    const millisecondsInDay = 24 * 60 * 60 * 1000;
    const millisecondsInHour = 60 * 60 * 1000;
    const millisecondsInMinute = 60 * 1000;

    if (differenceInMilliseconds >= millisecondsInDay) {
        // Если разница больше или равна 1 дню
        const differenceInDays = Math.floor(differenceInMilliseconds / millisecondsInDay);
        return { value: differenceInDays, unit: "days" };
    } else if (differenceInMilliseconds >= millisecondsInHour) {
        // Если разница меньше 1 дня, но больше или равна 1 часу
        const differenceInHours = Math.floor(differenceInMilliseconds / millisecondsInHour);
        return { value: differenceInHours, unit: "hours" };
    } else if (differenceInMilliseconds >= millisecondsInMinute) {
        // Если разница меньше 1 часа, но больше или равна 1 минуте
        const differenceInMinutes = Math.floor(differenceInMilliseconds / millisecondsInMinute);
        return { value: differenceInMinutes, unit: "minutes" };
    } else {
        // Если разница меньше 1 минуты
        return { value: 0, unit: "expired" };
    }
};



// Функция для преобразования времени из Лондона в текущее время по часовому поясу пользователя
export const formatDateHM = (dateString)  => {
    const date = new Date(dateString);
    const timeZone = getUserTimeZone();
    return formatInTimeZone(date, timeZone, 'H:mm');
}

// Две даты являются одним днем или разными.
export const isDifferentDay = (date, arrayDate, index) => {
    if (index === 0) return true;
    const currentDate = new Date(date);
    const previousDate = new Date(arrayDate[index - 1].created_at);
    return !isSameDay(currentDate, previousDate);
}

const getUserTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}