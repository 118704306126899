import {
    ArrowUpCircle,
    BoxArrowRight,
    CreditCard2Front,
    Flag,
    Palette, Pencil,
    PeopleFill,
    PersonHeart, PersonPlusFill, PersonSquare, Translate
} from "react-bootstrap-icons";
import {format} from "date-fns";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAllUsersWithFriendshipAndRoomStatus} from "../../redux/selectors/followsSelectors";
import {useAuth} from "../../hooks/useAuth";
import {ToggleButton} from "../buttons/ToggleButton";
import {updateUserCountryRequest, updateUserLanguageRequest} from "../../axios/userRequests";
import {Slide, toast} from "react-toastify";
import {updateUserCountry, updateUserLanguage} from "../../redux/actions/user";
import FlagIcon from 'react-world-flags';
import {truncateWithEllipsis} from "../../utils/htmlHelpers";
import {Tooltip} from "react-tooltip";
import {IconOnlineSales} from "../Icons/IconOnlineSales";
import {selectPopularLanguages} from "../../redux/selectors/languageSelectors";
import {formatToFullLondonDate} from "../../utils/formatDate";

export const AuthProfile = () => {
    const dispatch = useDispatch();
    const offcanvasRef = useRef(null);
    const user = useSelector(state => state.user);
    const countries = useSelector(state => state.countries);
    const [activeEditCountry, setActiveEditCountry] = useState(false);
    const [activeEditLanguage, setActiveEditLanguage] = useState(false);
    const {
        followers_count,
        following_count,
        friends_count
    } = useSelector(state => getAllUsersWithFriendshipAndRoomStatus(state, user.id));
    const languages = useSelector(state => state.languages);
    const popularLanguages = useSelector(selectPopularLanguages);
    const [selectedLanguage, setSelectedLanguage] = useState({id: null, language: null});
    const [selectedCountry, setSelectedCountry] = useState({id: null, code: null, country: null});
    const additionalSuccessAction = () => {
        if (offcanvasRef.current) {
            offcanvasRef.current.click();
        }
    };
    const {logOut} = useAuth(additionalSuccessAction);

    useEffect(() => {
        setSelectedLanguage({id: user.language_id, language: user.language});
        setSelectedCountry({id: user.country_id, code: user.code, country: user.country});
    }, [user]);

    const sendUpdateCountry = async () => {
        setActiveEditCountry(false);
        if (user.country_id === selectedCountry.id) {
            return;
        }
        try {
            await updateUserCountryRequest({country_id: selectedCountry.id});
            dispatch(updateUserCountry(selectedCountry));
            toast.success('Country updated.', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide,
            });
        } catch (e) {
            console.log(e);
            toast.error('Something went wrong...', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide,
            });
        }
    }

    const handleChangeCountry = (event) => {
        console.log(countries)
        const foundCountry = {
            id: countries[Number(event.target.value)].id,
            code: countries[Number(event.target.value)].code,
            country: countries[Number(event.target.value)].country
        };
        setSelectedCountry(foundCountry);
    };


    const sendUpdateLanguage = async () => {
        setActiveEditLanguage(false);
        if (user.language_id === selectedLanguage.id) {
            return;
        }
        try {
            await updateUserLanguageRequest({language_learning_id: selectedLanguage.id});
            dispatch(updateUserLanguage(selectedLanguage));
            toast.success('Language learning updated.', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide,
            });
        } catch (e) {
            console.log(e);
            toast.error('Something went wrong...', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide,
            });
        }
    }

    const handleChangeLanguage = (event) => {
        const foundLang = languages.find(land => land.id === Number(event.target.value))
        setSelectedLanguage(foundLang);
    };

    const renderFullTimeSubscribeTime = () => {
        let str = '';
        if (user.room_subscription_time.unit !== 'expired') {
            str += formatToFullLondonDate(user.room_subscription_end);
        } else {
            str += user.room_subscription_time.unit;
        }
        str += ' / ';
        if (user.assistant_subscription_time.unit !== 'expired') {
            str += formatToFullLondonDate(user.assistant_subscription_end);
        } else {
            str += user.assistant_subscription_time.unit;
        }
        return str;
    }

    return (
        <div className="offcanvas offcanvas-top offcanvas-size-profile bg-dark-modal text-white border-modal"
             tabIndex="-1" id="offcanvasAuthProfile"
             aria-labelledby="offcanvasAuthProfile">
            <div className="offcanvas-body">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="profile-header d-flex align-items-end">
                        <PersonSquare size={30}/> <span className='mx-2'>Profile</span>
                    </div>
                    <div data-bs-theme="dark">
                        <button ref={offcanvasRef} type="button" className="btn-close" data-bs-dismiss="offcanvas"
                                aria-label="Close"></button>
                    </div>
                </div>
                <hr/>
                <div className="center bg-dark-modal">
                    <div className="profile">
                        <div className="profile-image">
                            <img
                                src={user.avatar}
                                alt="Avatar"/>
                        </div>
                        <div className="name">{user.name}</div>
                        <div className="user-auth-info">{user.email}</div>
                        <div className="user-auth-info">Created
                            At: {format(user.created_at, 'yyyy-MM-dd HH:mm:ss')}</div>
                        <hr className="hr-user-info"/>
                        <ul className="auth-user-menu">
                            <li className="d-flex text-white">
                                <div data-tooltip-id="auth-info-subscribe-time"
                                     data-tooltip-content={renderFullTimeSubscribeTime()}>
                                    <CreditCard2Front size={18}/>
                                    <Tooltip id="auth-info-subscribe-time"/>
                                </div>
                                <div className="d-flex justify-content-between text-white w-100">
                                    <span className="mx-2">
                                        {`${user.room_subscription_time.value} ${user.room_subscription_time.unit} / ${user.assistant_subscription_time.value} ${user.assistant_subscription_time.unit}`}
                                    </span>
                                    <div className="edit-user-data text-center"
                                         data-bs-toggle="modal"
                                         data-bs-target="#modalPayment"
                                    >
                                        <IconOnlineSales color={'#fff'} size={18}/>
                                    </div>
                                </div>
                            </li>
                            <li className="d-flex text-white">
                                <div data-tooltip-id="user-select-country"
                                     data-tooltip-content="You can choose your country.">
                                    <Flag size={18}/>
                                    <Tooltip id="user-select-country"/>
                                </div>
                                {!activeEditCountry ?
                                    <div className="d-flex justify-content-between text-white w-100">
                                        <div className='mx-2'
                                             data-tooltip-id="my-country"
                                             data-tooltip-content={user.country}>
                                            <FlagIcon code={user.code} className="mx-1" width='25'
                                                      height='18'/> {truncateWithEllipsis(user.country, 12)}
                                        </div>
                                        <div className="edit-user-data text-center"
                                             onClick={() => setActiveEditCountry(true)}>
                                            <Pencil size={18}/>
                                        </div>
                                        {(user.country.length > 11) && <Tooltip id="my-country"/>}
                                    </div>
                                    :
                                    <div className="d-flex justify-content-between text-white w-100">
                                        <select
                                            className="form-select form-select-sm bg-dark-input text-white"
                                            aria-label="form-select-sm language-select"
                                            value={setSelectedCountry}
                                            onChange={handleChangeCountry}
                                            name="country">
                                            <option selected>{selectedCountry?.country}</option>
                                            {countries?.map((country, index) => (
                                                <option key={index} value={index}>{country.country}</option>
                                            ))}
                                        </select>
                                        <div className="edit-user-data text-center"
                                             onClick={sendUpdateCountry}>
                                            <ArrowUpCircle size={18}/>
                                        </div>
                                    </div>
                                }
                            </li>

                            <li className="d-flex text-white">
                                <div data-tooltip-id="user-select-language"
                                     data-tooltip-content="You can choose a language to learn.">
                                    <Translate size={18}/>
                                    <Tooltip id="user-select-language"/>
                                </div>
                                {!activeEditLanguage ?
                                    <div className="d-flex justify-content-between text-white w-100">
                                        <div className='mx-2'
                                             data-tooltip-id="my-language"
                                             data-tooltip-content={user.language}>
                                            {truncateWithEllipsis(user.language, 16)}
                                        </div>
                                        <div className="edit-user-data text-center"
                                             onClick={() => setActiveEditLanguage(true)}>
                                            <Pencil size={18}/>
                                        </div>
                                        {(user.language.length > 15) && <Tooltip id="my-language"/>}
                                    </div>
                                    :
                                    <div className="d-flex justify-content-between text-white w-100">
                                        <select
                                            className="form-select form-select-sm bg-dark-input text-white"
                                            aria-label="form-select-sm language-select"
                                            value={selectedLanguage}
                                            onChange={handleChangeLanguage}
                                            name="language">

                                            <optgroup label="Selected Language" className="brand-color">
                                                <option selected
                                                        className="text-white">{selectedLanguage?.language}</option>
                                            </optgroup>

                                            <optgroup label="Popular Languages" className="brand-color">
                                                {popularLanguages.map((lang, index) => (
                                                    <option key={`popular-${index}`} value={lang.id}
                                                            className="text-white">{lang.language}</option>
                                                ))}
                                            </optgroup>

                                            <optgroup label="All Languages" className="brand-color">
                                                {languages.map((lang, index) => (
                                                    <option key={`popular-${index}`} value={lang.id}
                                                            className="text-white">{lang.language}</option>
                                                ))}
                                            </optgroup>
                                        </select>
                                        <div className="edit-user-data text-center"
                                             onClick={sendUpdateLanguage}>
                                            <ArrowUpCircle size={18}/>
                                        </div>
                                    </div>
                                }
                            </li>

                            <li><Palette size={18}/> Theme: <div className="d-inline-block auth-theme"><ToggleButton
                                text1='Light' text2='Dark' toggle={false}/></div></li>
                            <hr className="hr-user-menu"/>
                            <li className="user-logout" onClick={logOut}><BoxArrowRight size={18}/> logout</li>
                        </ul>
                    </div>
                    <div className="stats">
                        <div className="box">
                            <span className="parameter text-danger"><PeopleFill size={18}/></span>
                            <span className="value">{friends_count}</span>
                            <span className="parameter">Friends</span>
                        </div>
                        <div className="box">
                            <span className="parameter text-danger"><PersonHeart size={18}/></span>
                            <span className="value">{followers_count}</span>
                            <span className="parameter">Followers</span>
                        </div>
                        <div className="box">
                            <span className="parameter text-danger"><PersonPlusFill size={18}/></span>
                            <span className="value">{following_count}</span>
                            <span className="parameter">Following</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}