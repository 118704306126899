export const loginUser = (user) => ({
    type: 'LOGIN_USER',
    payload: user,
});

export const logoutUser = () => ({
    type: 'LOGOUT_USER',
});

export const updateUserCountry = (country) => ({
    type: 'UPDATE_COUNTRY',
    payload: country
});
export const updateUserLanguage = (language) => ({
    type: 'UPDATE_LANGUAGE',
    payload: language
});

export const updateRoomSubscriptionTime = () => ({
    type: 'UPDATE_ROOM_SUBSCRIPTION_TIME'
});

export const updateRoomSubscriptionEnd = (date) => ({
    type: 'UPDATE_ROOM_SUBSCRIPTION_END',
    payload: date
});

export const updateAssistantSubscriptionTime = () => ({
    type: 'UPDATE_ASSISTANT_SUBSCRIPTION_TIME'
});

export const updateAssistantSubscriptionEnd = (date) => ({
    type: 'UPDATE_ASSISTANT_SUBSCRIPTION_END',
    payload: date
});

export const loadingTrue = () => ({
    type: 'LOADING_TRUE'
});

export const loadingFalse = () => ({
    type: 'LOADING_FALSE'
});