import React, {useContext, useRef, useState} from "react";
import {v4 as uuidV4} from "uuid";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {EnumPeopleList} from "../../enums/PeopleList";
import {YJSContext} from "../../context/YJSContext";
import {Slide, toast} from "react-toastify";
import {IconCreateRoom} from "../Icons/IconCreateRoom";
import {selectPopularLanguages} from "../../redux/selectors/languageSelectors";
import {
    getCurrentTimestampInLondon,
    getCurrentUnixInUTS
} from "../../utils/formatDate";

export const ModalCreateRoom = () => {
    const {addRoom} = useContext(YJSContext);
    const navigate = useNavigate();

    const user = useSelector(state => state.user);
    const levels = useSelector(state => state.levels);
    const languages = useSelector(state => state.languages);
    const popularLanguages = useSelector(selectPopularLanguages);
    const [description, setDescription] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState({id: null, language: 'Choose language'});
    const [selectedLevel, setSelectedLevel] = useState({id: null, level: 'Choose level'});
    const [selectedPeople, setSelectedPeople] = useState(0);
    const [errorForm, setErrorForm] = useState({});
    const closeModalRef = useRef(null);


    const sendForm = async (e) => {
        e.preventDefault();

        let er = false;
        if (selectedLanguage.id === null) {
            setErrorForm(prevState => ({
                ...prevState,
                language: true
            }));
            er = true;
        }
        if (selectedLevel.id === null) {
            setErrorForm(prevState => ({
                ...prevState,
                level: true
            }));
            er = true;
        }
        if (typeof description !== 'string' || description.length > 300) {
            setErrorForm(prevState => ({
                ...prevState,
                description: 'Maximum description length 300 characters'
            }));
            er = true
        } else {
            setErrorForm(prevState => ({
                ...prevState,
                description: null
            }));
        }

        if (er === true) {
            return;
        }

        try {
            setErrorForm({});
            const roomData = {
                room_id: uuidV4(),
                owner: user,
                language_id: selectedLanguage.id,
                language: selectedLanguage.language,
                level_id: selectedLevel.id,
                level: selectedLevel.level,
                description: description,
                quantity: selectedPeople,
                black_list: [],
                access: 1,
                connected_users: [],
                quiz: {isActive: false},
                roulette: {isActive: false},
                updated_at: getCurrentUnixInUTS(),
                created_at: getCurrentTimestampInLondon(),
            }
            addRoom(roomData);

            // Сброс формы после добавления всех комнат
            setSelectedLanguage({});
            setSelectedLevel({});
            setSelectedPeople(0);
            setDescription('');
            closeModalRef.current.click();

            // Навигация на последнюю созданную комнату
            localStorage.setItem('roomAnime', true);
            navigate(`/room/${roomData.room_id}`);
        } catch (err) {
            console.log(err);
            toast.error('Something went wrong...', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide,
            });
        }
    };

    const handleChangeLanguage = (event) => {
        setErrorForm(prevState => ({
            ...prevState,
            language: false
        }));

        const foundLang = languages.find(land => land.id === Number(event.target.value))

        setSelectedLanguage(foundLang);
    };

    const handleChangeLevel = (event) => {
        setErrorForm(prevState => ({
            ...prevState,
            level: false
        }));

        const foundLvl = {id: levels[Number(event.target.value)].id, level: levels[Number(event.target.value)].level};
        setSelectedLevel(foundLvl);
    };

    const handleChangePeople = (event) => {
        if (event.target.value === 'Unlimited') {
            setSelectedPeople(0);
        } else {
            setSelectedPeople(Number(event.target.value));
        }
    };

    return (
        <div className='modal fade' id="createRoomModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content bg-dark-modal text-white border-modal">
                    <div className="modal-header d-flex justify-content-between">
                        <p className="modal-title h1 fs-5 d-flex w-100" id="exampleModalLabel">
                            <IconCreateRoom color={'#fff'} size={40}/> <span className='mx-2 py-1'>Create Room</span>
                        </p>
                        <div data-bs-theme="dark" className="close-btn-modal">
                            <button ref={closeModalRef} type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <form className="needs-validation" onSubmit={sendForm}>
                            <p className="font m-0">Language</p>
                            <select
                                className={`form-select text-white ${!errorForm.language ? 'bg-dark-input' : 'error-input'}`}
                                aria-label="language-select"
                                value={selectedLanguage}
                                onChange={handleChangeLanguage}
                                name="language">

                                <optgroup label="Selected Language" className="brand-color">
                                    <option selected className="text-white">{selectedLanguage?.language}</option>
                                </optgroup>

                                <optgroup label="Popular Languages" className="brand-color">
                                    {popularLanguages.map((lang, index) => (
                                        <option key={`popular-${index}`} value={lang.id}
                                                className="text-white">{lang.language}</option>
                                    ))}
                                </optgroup>

                                <optgroup label="All Languages" className="brand-color">
                                    {languages.map((lang, index) => (
                                        index > 0 && (
                                            <option key={`all-${index}`} value={lang.id}
                                                    className="text-white">{lang.language}</option>
                                        )
                                    ))}
                                </optgroup>
                            </select>
                            <p className="font m-0 mt-3">Level</p>
                            <select
                                className={`form-select text-white ${!errorForm.level ? 'bg-dark-input' : 'error-input'}`}
                                aria-label="level-select"
                                value={selectedLevel}
                                onChange={handleChangeLevel}
                                name="level">
                                <option selected>{selectedLevel?.level}</option>
                                {levels.map((lvl, index) => (
                                    <option key={index} value={index}>{lvl.level}</option>
                                ))}
                            </select>

                            <div className="mt-3">
                                <label className="text-white font form-label" htmlFor="description">Description</label>
                                <input className="form-control text-white bg-dark-input description"
                                       type="text"
                                       placeholder="Description"
                                       aria-label="default input example"
                                       name="Description"
                                       maxLength={300}
                                       id="description"
                                       value={description}
                                       onChange={(e) => setDescription(e.target.value)}/>
                            </div>
                            <p className="font m-0 mt-3">Maximum People</p>
                            <select
                                className="form-select bg-dark-input text-white"
                                aria-label="level-select"
                                value={selectedPeople}
                                onChange={handleChangePeople}
                                name="people">
                                <option selected>{selectedPeople === 0 ? 'Unlimited' : selectedPeople}</option>
                                {EnumPeopleList.map((people, index) => (
                                    <option key={index} value={index}>{people.label}</option>
                                ))}
                            </select>

                            <button className="btn btn-primary mt-5 w-100" type="submit">Create</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}