import React, {createContext, useState} from "react";

export const RoomContext = createContext(null);

export const RoomProvider = ({children}) => {
    const [localShowQuiz, setLocalShowQuiz] = useState(false);
    const [localShowRoulette, setLocalShowRoulette] = useState(false);

    return (
        <RoomContext.Provider value={{
            localShowQuiz,
            setLocalShowQuiz,

            localShowRoulette,
            setLocalShowRoulette,
        }}>
            {children}
        </RoomContext.Provider>
    );
};