import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {Card} from './Card';
import {FilterRoom} from "./FilterRoom";
import {useSnapshot} from "valtio/react";
import {YJSContext} from "../context/YJSContext";
import LazyLoad, {forceCheck} from 'react-lazyload';
import {getRoomHistory} from "../utils/localStorage";
import {useSelector} from "react-redux";
import {IconHistoryRooms} from "./Icons/IconHistoryRooms";
import {IconMyRooms} from "./Icons/IconMyRooms";
import {IconAllRooms} from "./Icons/IconAllRooms";

export const ListRooms = () => {
    const user = useSelector(state => state.user);
    const {state} = useContext(YJSContext);
    const snap = useSnapshot(state);
    const roomsArray = useMemo(() => Object.values(snap).reverse(), [snap]);

    const [selectedLanguage, setSelectedLanguage] = useState("Any");
    const [selectedLevel, setSelectedLevel] = useState("Any");
    const [roomHistory, setRoomHistory] = useState([]);
    const [showMode, setShowMode] = useState("all");

    const handleLanguageChange = useCallback((newLanguage) => {
        setSelectedLanguage(newLanguage);
    }, []);

    const handleLevelChange = useCallback((newLevel) => {
        setSelectedLevel(newLevel);
    }, []);

    // Получаем историю из localStorage при загрузке компонента
    useEffect(() => {
        const storedHistory = getRoomHistory();
        setRoomHistory(storedHistory);
    }, []);

    // Фильтрация комнат по языку и уровню
    const filteredRooms = useMemo(() => {
        return roomsArray.filter(room => {
            const languageMatches = selectedLanguage === "Any" || room.language === selectedLanguage;
            const levelMatches = selectedLevel === "Any" || room.level === selectedLevel;
            return languageMatches && levelMatches;
        });
    }, [roomsArray, selectedLanguage, selectedLevel]);

    // Фильтрация комнат из истории
    const filteredHistoryRooms = useMemo(() => {
        return roomHistory
            .map(roomId => roomsArray.find(room => room.room_id === roomId))
            .filter(Boolean) // Убираем undefined
            .filter(room => {
                const languageMatches = selectedLanguage === "Any" || room.language === selectedLanguage;
                const levelMatches = selectedLevel === "Any" || room.level === selectedLevel;
                return languageMatches && levelMatches;
            });
    }, [roomHistory, roomsArray, selectedLanguage, selectedLevel]);

    // Фильтрация моих комнат (по полю owner.id)
    const myRooms = useMemo(() => {
        return roomsArray.filter(room => room.owner.id === user.id)
            .filter(room => {
                const languageMatches = selectedLanguage === "Any" || room.language === selectedLanguage;
                const levelMatches = selectedLevel === "Any" || room.level === selectedLevel;
                return languageMatches && levelMatches;
            });
    }, [roomsArray, selectedLanguage, selectedLevel, user.id]);

    useEffect(() => {
        forceCheck();
    }, [showMode, filteredRooms, filteredHistoryRooms, myRooms]);

    // Переключение между всеми комнатами, историей и моими комнатами
    const handleShowModeChange = (mode) => {
        setShowMode(mode);
    };

    const displayedRooms = useMemo(() => {
        if (showMode === "history") return filteredHistoryRooms;
        if (showMode === "myRooms") return myRooms;
        return filteredRooms;
    }, [showMode, filteredRooms, filteredHistoryRooms, myRooms]);

    return (
        <div>
            {roomsArray.length !== 0 && (
                <>
                    <FilterRoom
                        selectedLanguage={selectedLanguage}
                        setSelectedLanguage={handleLanguageChange}
                        selectedLevel={selectedLevel}
                        setSelectedLevel={handleLevelChange}
                    />

                    <div>
                        <ul className="nav nav-pills border-bottom d-flex justify-content-between align-items-center"
                            id="pills-tab" role="tablist">
                            <div className="d-flex">
                                <li className="nav-item" onClick={() => handleShowModeChange("all")}>
                                    <p className="social-nav-link pointer active"
                                       data-bs-toggle="pill" role="tab" aria-selected="true">
                                        <IconAllRooms color={'#fff'} size={18}/> All rooms
                                    </p>
                                </li>
                                {user.id &&
                                    <>
                                        <li className="nav-item" role="presentation"
                                            onClick={() => handleShowModeChange("history")}>
                                            <p className="social-nav-link pointer"
                                               data-bs-toggle="pill" role="tab" aria-selected="false">
                                                <IconHistoryRooms color={'#fff'} size={18}/> History
                                            </p>
                                        </li>
                                        <li className="nav-item" role="presentation"
                                            onClick={() => handleShowModeChange("myRooms")}>
                                            <p className="social-nav-link pointer"
                                               data-bs-toggle="pill" role="tab" aria-selected="false">
                                                <IconMyRooms color={'#fff'} size={18}/> My
                                            </p>
                                        </li>
                                    </>
                                }
                            </div>
                        </ul>
                    </div>
                </>
            )}

            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                {roomsArray.length === 0 ? (
                    <div className='d-flex justify-content-center align-items-center loading-spinner text-white'>
                        <p className="text-center">Create the first room and wait for your conversation partner.</p>
                    </div>
                ) : (
                    displayedRooms.map((room) => (
                        <LazyLoad key={room.room_id} height={350} offset={100} once>
                            <div className="col">
                                <Card room={room}/>
                            </div>
                        </LazyLoad>
                    ))
                )}
            </div>
        </div>
    );
};
