import {Header} from "../components/Header";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {VoiceAssistant} from "../components/Assistants/VoiceAssistant";
import {TextAssistant} from "../components/Assistants/TextAssistant";
import {getAssistantChatByUserIdRequest} from "../axios/assistantChatRequest";
import {setAssistantChats} from "../redux/actions/assistantChat";
import {Link, useNavigate} from "react-router-dom";
import {Loading} from "../components/Loading";
import {ArrowLeft} from "react-bootstrap-icons";
import {ButtonSocial} from "../components/buttons/ButtonSocial";

export const Assistant = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const checkChat = useSelector(state => state.assistantChat.check);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const user = useSelector(state => state.user);

    useEffect(() => {
        if (!user.id && user.loading === false) {
            navigate('/');
        }
    }, [user.id, user.loading, navigate])

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (!user.id) return;
        const fetchAssistantChat = async () => {
            if (checkChat) return;
            const res = await getAssistantChatByUserIdRequest();
            dispatch(setAssistantChats(res));
        }

        fetchAssistantChat();

    }, [dispatch, checkChat, user.id]);

    return (
        <div className="container">
            <Header/>

            {!isMobile &&
                <div className="d-flex justify-content-between border-bottom">
                    <Link to={'/'} className="">
                        <div className="pointer btn-back"><ArrowLeft size={35} color={'#fff'}/></div>
                    </Link>
                    <ButtonSocial addClass={"position-assistant-social-button"}/>
                </div>
            }

            <div className="">
                {isMobile ?
                    <React.Fragment>
                        <ul className="nav nav-pills border-bottom d-flex align-items-center" id="pills-tab-assistant"
                            role="tablist">
                            {/* Левая иконка */}
                            <li className="nav-item me-auto" role="presentation">
                                <Link to={'/'} className="">
                                    <div className="pointer btn-back"><ArrowLeft size={35} color={'#fff'}/></div>
                                </Link>
                            </li>

                            {/* Центрированные элементы */}
                            <li className="nav-item" role="presentation">
                                <p className="social-nav-link pointer active" id="voice-assistant-tab"
                                   data-bs-toggle="pill"
                                   data-bs-target="#pills-voice-assistant" role="tab"
                                   aria-controls="pills-voice-assistant" aria-selected="true">
                                    Voice assistant
                                </p>
                            </li>
                            <li className="nav-item" role="presentation">
                                <p className="social-nav-link pointer" id="text-assistant-tab" data-bs-toggle="pill"
                                   data-bs-target="#pills-text-assistant" role="tab"
                                   aria-controls="pills-text-assistant" aria-selected="false">
                                    Text assistant
                                </p>
                            </li>

                            {/* Правая иконка */}
                            <li className="nav-item ms-auto" role="presentation">
                                <ButtonSocial addClass={"position-assistant-social-button"}/>
                            </li>
                        </ul>

                        <div className="tab-content mt-3" id="pills-tab-assistantContent">
                            <div className="tab-pane fade show active" id="pills-voice-assistant" role="tabpanel"
                                 aria-labelledby="voice-assistant-tab" tabIndex="0">
                                {user.loading ?
                                    <div
                                        className="bg-assistant-loader bg-dark-modal d-flex justify-content-center align-content-center">
                                        <Loading/>
                                    </div>
                                    :
                                    <VoiceAssistant/>
                                }
                            </div>
                            <div className="tab-pane fade" id="pills-text-assistant" role="tabpanel"
                                 aria-labelledby="text-assistant-tab"
                                 tabIndex="0">
                                {user.loading ?
                                    <div
                                        className="bg-assistant-loader bg-dark-modal d-flex justify-content-center align-content-center">
                                        <Loading/>
                                    </div>
                                    :
                                    <TextAssistant/>
                                }
                            </div>
                        </div>
                    </React.Fragment>
                    :
                    <div className="row d-md-flex mt-3">
                        <div className="col-md-6">
                            {user.loading ?
                                <div
                                    className="bg-assistant-loader bg-dark-modal d-flex justify-content-center align-content-center">
                                    <Loading/>
                                </div>
                                :
                                <VoiceAssistant caption={true}/>
                            }

                        </div>
                        <div className="col-md-6">
                            {user.loading ?
                                <div
                                    className="bg-assistant-loader bg-dark-modal d-flex justify-content-center align-content-center">
                                    <Loading/>
                                </div>
                                :
                                <TextAssistant caption={true}/>
                            }
                        </div>
                    </div>
                }
            </div>

        </div>
    )
}