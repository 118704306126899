import {useEffect} from "react";

export const useCloseModal = () => {
    useEffect(() => {
        const handlePopState = () => {
            const backdrop = document.querySelector('.modal-backdrop');
            if (backdrop) {
                backdrop.parentNode.removeChild(backdrop);
            }
            // Удаляем класс 'modal-open' с тела документа
            document.body.classList.remove('modal-open');
            // Сбрасываем инлайн-стили, если они были добавлены
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
            const backdrop = document.querySelector('.modal-backdrop');
            if (backdrop) {
                backdrop.parentNode.removeChild(backdrop);
            }
            // Повторно удаляем класс и стили при размонтировании компонента
            document.body.classList.remove('modal-open');
            document.body.style.overflow = '';
            document.body.style.paddingRight = '';
        };
    }, []);
};
