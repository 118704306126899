import React, {useContext, useEffect, useRef} from "react";
import {RoomContext} from "../../context/RoomContext";
import {useDispatch, useSelector} from "react-redux";
import {getQuizTypesRequest} from "../../axios/quizTypesRequest";
import {setQuizTypes} from "../../redux/actions/quizType";
import {getRouletteTypesRequest} from "../../axios/rouletteTypesRequest";
import {setRouletteTypes} from "../../redux/actions/rouletteType";
import {YJSContext} from "../../context/YJSContext";
import {IconGames} from "../Icons/IconGames";
import {ArrowRepeat} from "react-bootstrap-icons";
import {IconQuiz} from "../Icons/IconQuiz";
import {IconManual} from "../Icons/IconManual";

export const ModalGames = React.memo(({room_id, isActiveQuiz, isActiveRoulette}) => {
    const dispatch = useDispatch();
    const {setLocalShowQuiz, setLocalShowRoulette} = useContext(RoomContext);
    const gamesModalRef = useRef(null);
    const quizTypes = useSelector(state => state.quizTypes);
    const rouletteTypes = useSelector(state => state.rouletteTypes);
    const {activateQuiz, activateRoulette} = useContext(YJSContext);

    useEffect(() => {
        const getQuizTypes = async () => {
            // Если массив пустой или содержит только начальное состояние, делаем запрос
            if (!quizTypes || quizTypes.length === 0 || quizTypes[0].id === null) {
                try {
                    const res = await getQuizTypesRequest();
                    dispatch(setQuizTypes(res));
                } catch (e) {
                    console.log(e);
                }
            }
        };

        const getRouletteTypes = async () => {
            // Если массив пустой или содержит только начальное состояние, делаем запрос
            if (!rouletteTypes || rouletteTypes.length === 0 || rouletteTypes[0].id === null) {
                try {
                    const res = await getRouletteTypesRequest();
                    dispatch(setRouletteTypes(res));
                } catch (e) {
                    console.log(e);
                }
            }
        };


        getQuizTypes();
        getRouletteTypes();
    }, [dispatch, quizTypes, rouletteTypes]);

    const startQuiz = () => {
        if (isActiveQuiz) return;

        activateQuiz(room_id)
        setLocalShowQuiz(true);
        gamesModalRef.current.click();
    }

    const startRoulette = () => {
        if (isActiveRoulette) return;

        activateRoulette(room_id);
        setLocalShowRoulette(true);
        gamesModalRef.current.click();
    }

    return (
        <div className="modal fade" id="gamesRoom" tabIndex="-1" aria-labelledby="gamesRoom"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content bg-dark-modal text-white border-modal">
                    <div className="modal-header d-flex justify-content-between">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                            <IconGames color={'#fff'} size={30}/>
                            <span className='mx-2'>Games</span>
                        </h1>
                        <div data-bs-theme="dark" className="close-btn-modal">
                            <button ref={gamesModalRef} type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="d-flex align-items-center">
                            <p
                                className={`${!isActiveQuiz ? 'pointer' : 'not-allowed'} list-games flex-grow-1`}
                                onClick={startQuiz}
                            >
                                <IconQuiz color={'#fff'} size={24} /> Quizzes
                            </p>
                            <div className="pointer icon-games-info"
                                 data-bs-toggle="modal"
                                 data-bs-target="#infoQuiz">
                                <IconManual color={'#fff'} size={24} />
                            </div>
                        </div>

                        <div className="d-flex align-items-center">
                            <p
                                className={`${!isActiveRoulette ? 'pointer' : 'not-allowed'} list-games flex-grow-1`}
                                onClick={startRoulette}
                            >
                                <ArrowRepeat size={26} /> Roulette
                            </p>
                            <div className="pointer icon-games-info"
                                 data-bs-toggle="modal"
                                 data-bs-target="#infoRoulette">
                                <IconManual color={'#fff'} size={24} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});