export const Loading = () => {

    return (
        <div className="loader">
            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 81 45"
                enableBackground="new 0 0 81 45"
                xmlSpace="preserve"
            >
                <circle className="circle1" fill="#1A7FC3" cx="13.5" cy="22.5" r="4.5"/>
                <circle className="circle2" fill="#1A7FC3" cx="31.5" cy="22.5" r="4.5"/>
                <circle className="circle3" fill="#1A7FC3" cx="49.5" cy="22.5" r="4.5"/>
                <circle className="circle4" fill="#1A7FC3" cx="67.5" cy="22.5" r="4.5"/>
            </svg>
            LOADING
        </div>
    );
}