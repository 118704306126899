import {generateAttributes} from "../../utils/htmlHelpers";
import {isMobileDevice} from "../../utils/deviceHelpers";

export const ButtonPurple = ({icon: Icon, iconSize, text, attributes = [], disabled = false, onClick = null, className = '', color = '#fff'}) => {

    return (
        <button
            className={`w-100 ${isMobileDevice() ? 'buttonMobilePurple' : 'btn-dark-default btn-be-purple'} ${className}`}
            type="button"
            disabled={disabled}
            {...generateAttributes(attributes)}
            onClick={onClick}
        >
            {Icon && <Icon size={iconSize} color={color} />} {text}
        </button>
    );
}