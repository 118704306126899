import {BellSlashFill, QuestionCircle, XCircle} from "react-bootstrap-icons";
import React, {useEffect, useState} from "react";
import {useFollows} from "../hooks/useFollows";

export const PopoverSocialConfirmUnfollow = ({id, name}) => {
    const [isOpen, setIsOpen] = useState(false);
    const {unfollow} = useFollows();

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };


    useEffect(() => {
        if (!id) return;
        const handleOutsideClick = (event) => {
            // Проверяем, было ли кликнуто вне popover
            const classClosest = '.social-pop-container' + id;
            if (isOpen && !event.target.closest(classClosest)) {
                setIsOpen(false);
            }
        };

        // Добавляем обработчик события для клика по всему документу
        document.addEventListener('click', handleOutsideClick);

        return () => {
            // Удаляем обработчик события при размонтировании компонента
            document.removeEventListener('click', handleOutsideClick);

        };
    }, [isOpen, id]);

    return (
        <div className={`ms-auto d-flex align-items-center social-pop-container${id}`}>
            <div className="pointer" onClick={toggleOpen}>
                <BellSlashFill size={20} color={'tomato'}/>
            </div>
            <div className={`bg-dark-modal border-bottom wrapper-social-popover ${isOpen ? 'show' : ''}`}>
                <div className="d-flex h-100">
                    <div className="popover-content-block d-flex flex-column text-center text-white p-1">
                        <p className="border-bottom">Are you sure?</p>
                        <p>Do you want to unfollow from <b>{name}</b>?</p>
                        <div className="mt-auto">
                            <button onClick={toggleOpen} className="" type="button">
                                <XCircle size={20}/>
                            </button>
                            <button type="button" onClick={() => unfollow(id)}>
                                <BellSlashFill size={20}/>
                            </button>
                        </div>
                    </div>
                    <div className="popover-icon-block text-center text-white">
                        <QuestionCircle size={60}/>
                    </div>
                </div>
            </div>
        </div>
    );
}