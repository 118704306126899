import React, {useContext, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {YJSContext} from "../../context/YJSContext";
import {QuizScoreboard} from "./QuizScoreboard";
import {QuizQuestionsBlock} from "./QuizQuestionsBlock";

export const QuizInProgress = React.memo(({room_id, quiz}) => {
    const user = useSelector(state => state.user);
    const [localFinish, setLocalFinish] = useState(false);
    const {isUserInQuiz} = useContext(YJSContext);

    const isUserInCurrentQuiz = useMemo(() => isUserInQuiz(room_id, user.id), [room_id, user.id, isUserInQuiz]);

    return (
        <div className="d-flex flex-column quiz-step-join">
            {(isUserInCurrentQuiz && !localFinish) ?
                <QuizQuestionsBlock
                    room_id={room_id}
                    quiz={quiz}
                    setLocalFinish={setLocalFinish}
                    isUserInCurrentQuiz={isUserInCurrentQuiz}
                />
                :
                <div className="d-flex justify-content-center">
                    <p className="quiz-waiting-users">Waiting other users...</p>
                </div>
            }
            <QuizScoreboard users={quiz.users} currentUser={user}/>
        </div>
    );
});