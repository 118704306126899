import React, {useEffect, useRef, useState} from "react";
import {ButtonPurple} from "../buttons/ButtonPurple";
import {addLineBreakIfLongWord} from "../../utils/htmlHelpers";
import {useDispatch, useSelector} from "react-redux";
import {DeleteChatVoiceAssistant} from "../offcanvas/DeleteChatVoiceAssistant";
import {ConversationVoiceAssistant} from "./ConversationVoiceAssistant";
import {ArrowDown, InfoCircle, Telephone} from "react-bootstrap-icons";
import {AnswerRender} from "./AnswerRender";
import {analyzeAudio} from "../../utils/mediaHelpers";
import {messageToVoiceAssistantRequest} from "../../axios/assistantChatRequest";
import {addVoiceMessageToAssistantChat} from "../../redux/actions/assistantChat";
import MicRecorder from "mic-recorder-to-mp3";
import {Slide, toast} from "react-toastify";
import {isMobileDevice} from "../../utils/deviceHelpers";
import {debounce} from "lodash";
import {IconDeleteChat} from "../Icons/IconDeleteChat";
import {IconAI} from "../Icons/IconAI";
import {ModalDescVoiceAssistant} from "../modals/ModalDescVoiceAssistant";

const Mp3Recorder = new MicRecorder({bitRate: 128});
export const VoiceAssistant = ({caption = false}) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const voiceChat = useSelector(state => state.assistantChat.data.voice);
    const [isShowConversation, setIsShowConversation] = useState(false);
    const messagesEndRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [microphone, setMicrophone] = useState(true);
    const [waitingAnswer, setWaitingAnswer] = useState(false);
    const [isAssistantSays, setIsAssistantSays] = useState(false);
    const [isError, setIsError] = useState({isError: false, message: ''});
    const [audioUrl, setAudioUrl] = useState(null);
    const voiceAnswer = useRef();
    const playAnswer = useRef();
    const isShowConversationRef = useRef(isShowConversation);


    useEffect(() => {
        isShowConversationRef.current = isShowConversation;
    }, [isShowConversation]);

    useEffect(() => {
        playAnswer.current.click();
    }, [audioUrl]);

    useEffect(() => {
        return () => {
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
                setStream(null);
            }
        }
    }, [stream]);

    const startCall = async () => {
        if (user.language_id === 1 || !user.language_id) {
            toast.warning('Please select the language you want to learn in your profile settings to proceed.', {
                position: "top-center",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide,
            });
            return;
        }
        try {
            if (isError.isError) {
                setIsError({isError: false, message: ''})
            }
            const mediaStream = await navigator.mediaDevices.getUserMedia({audio: true});
            setStream(mediaStream);
            setIsShowConversation(true);
            setMicrophone(false);
        } catch (e) {
            if (e.name === 'NotAllowedError' || e.name === 'PermissionDeniedError') {
                setIsError({isError: true, message: 'Please allow microphone access in your browser settings.'});
            } else {
                setIsError({
                    isError: true,
                    message: 'Check your microphone. Looks like there are some problems with this.'
                });
            }
        }
    }

    // Начать запись
    const startRecording = async () => {
        try {
            await Mp3Recorder.start();
            setMicrophone(true);
            if (isError.isError) {
                setIsError({isError: false, message: ''})
            }
            setIsAssistantSays(false);
            setAudioUrl(null);
            voiceAnswer?.current?.pause();
        } catch (e) {
            if (e.name === 'NotAllowedError' || e.name === 'PermissionDeniedError') {
                console.error('Доступ к микрофону запрещен пользователем.');
                setIsError({isError: true, message: 'Please allow microphone access in your browser settings.'});
            } else {
                console.error(e);
                setIsError({
                    isError: true,
                    message: 'Check your microphone. Looks like there are some problems with this.'
                });
            }
        }
    };

    // Остановить запись и отправить файл на сервер
    const stopRecording = debounce(async (cancel = false) => {
        try {
            // Останавливаем запись и получаем mp3 данные
            const [buffer, blob] = await Mp3Recorder.stop().getMp3();
            setMicrophone(false);
            if (cancel === true) return;

            // Проверяем громкость записанного аудио
            const isSilent = await analyzeAudio(blob);
            if (isSilent) {
                return;
            }

            setWaitingAnswer(true);

            // Создание FormData для отправки на сервер
            const formData = new FormData();
            formData.append('audio', new Blob(buffer, {type: 'audio/mp3'}), 'recording.mp3');

            const limitedHistory = voiceChat
                .slice(-5)
                .flatMap(({message, answer}) => [
                    {role: "user", content: message},
                    {role: "assistant", content: answer}
                ]);

            const messageData = {
                language: user.language,
                history: limitedHistory
            };

            // Преобразуем `messageData` в строку JSON и добавляем в `formData`
            formData.append('messageData', JSON.stringify(messageData));

            // Отправляем данные на сервер
            const response = await messageToVoiceAssistantRequest(formData);

            // Обрабатываем ответ сервера
            const assistantText = JSON.parse(decodeURIComponent(response.headers['x-assistant-response']));
            dispatch(addVoiceMessageToAssistantChat(assistantText));

            if (!isShowConversationRef.current) {
                setWaitingAnswer(false);
                return;
            }

            // Создание аудиообъекта из полученного ответа
            const audioBlob = new Blob([response.data], {type: 'audio/mpeg'});
            const url = URL.createObjectURL(audioBlob);

            setAudioUrl(url);
            setWaitingAnswer(false);
        } catch (err) {
            console.error(err);
            setWaitingAnswer(false);
            setIsError({isError: true, message: 'Something went wrong... 😬  Try again'});
        }
    }, 300);

    const handleUserClick = () => {
        if (voiceAnswer.current) {
            voiceAnswer.current.muted = true;
            voiceAnswer.current.play().catch((error) => {
                console.error('Ошибка воспроизведения аудио:', error);
            });
            voiceAnswer.current.addEventListener('playing', () => {
                console.log('Аудио воспроизводится');
                setIsAssistantSays(true);
                voiceAnswer.current.muted = false;
            });

            voiceAnswer.current.addEventListener('ended', () => {
                console.log('Аудио завершило воспроизведение');
                setIsAssistantSays(false);
                setAudioUrl(null);
            });
        }
    };

    return (
        <div className={`bg-dark-modal text-assistant-chat ${isMobileDevice() && 'mobile'}`}>
            <div className="d-flex justify-content-between border-bottom p-2">
                <div className="text-white pointer mx-1">
                    <InfoCircle
                        size={28}
                        data-bs-toggle="modal"
                        data-bs-target={`#descVoiceAssistant`}
                    />
                </div>

                {caption && <p className="text-white mb-0">Voice Assistant</p>}

                <div className="pointer"
                     data-bs-toggle="offcanvas" data-bs-target="#offcanvasDeleteChatVoiceAssistant"
                     aria-controls="offcanvasDeleteChatVoiceAssistant">
                    <IconDeleteChat color={'tomato'} size={35}/>
                </div>

            </div>
            <div className="text-assistant-list-messages p-2">
                {(voiceChat.length !== 0)
                    ?
                    (voiceChat.map((data, index) => (
                        <div key={index}>
                            <div className='chat-message my-message text-end m-2'>
                                    <span className={`d-inline-block p-1`}>
                                        {addLineBreakIfLongWord(data.message, 35)}
                                    </span>
                            </div>
                            <div key={index} className={'chat-message other-message m-2 text-white'}>
                                    <span className={`d-inline-block p-1`}>
                                        <AnswerRender message={addLineBreakIfLongWord(data.answer, 35)}/>
                                    </span>
                            </div>
                        </div>
                    )))
                    : <div
                        className="d-flex justify-content-center align-items-center text-white h-100">
                        <div className="text-center">
                            <IconAI color={'#fff'} size={60}/>
                            <p className="h5 my-2">Voice assistant</p>
                            <hr/>
                            <p className="h6">Call assistant</p>
                            <p className="h6"><ArrowDown/></p>
                        </div>
                    </div>
                }
                {audioUrl && <audio ref={voiceAnswer} src={audioUrl}/>}
                <button className="d-none" ref={playAnswer} onClick={handleUserClick}>play</button>
                {waitingAnswer && <p className="text-white text-center">One moment, please...</p>}

                <div ref={messagesEndRef}/>
            </div>

            <ButtonPurple text={'Start Call'} icon={Telephone} iconSize={22} onClick={startCall}/>

            <ConversationVoiceAssistant
                isShowConversation={isShowConversation}
                setIsShowConversation={setIsShowConversation}
                stream={stream}
                setStream={setStream}
                microphone={microphone}
                voiceAnswer={voiceAnswer}
                setAudioUrl={setAudioUrl}
                isAssistantSays={isAssistantSays}
                setIsAssistantSays={setIsAssistantSays}
                waitingAnswer={waitingAnswer}
                isError={isError}
                setIsError={setIsError}
                startRecording={startRecording}
                stopRecording={stopRecording}
            />

            <ModalDescVoiceAssistant/>
            <DeleteChatVoiceAssistant/>
        </div>
    );
}