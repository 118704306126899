export const IconQuiz = ({color = '#000000', size = 100}) => {

    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 95.9 122.88"
            style={{ enableBackground: 'new 0 0 95.9 122.88' }}
            xmlSpace="preserve"
            width={size} height={size}
        >
            <style type="text/css">
                {`
        .st0 { fill-rule: evenodd; clip-rule: evenodd; }
      `}
            </style>
            <g>
                <path
                    className="st0"
                    d="M26.6,66.95c0.67-0.68,1.76-0.69,2.44-0.01c0.68,0.68,0.68,1.78,0.01,2.47l-2.95,2.99l2.95,2.99
        c0.67,0.68,0.66,1.77-0.02,2.45c-0.68,0.68-1.77,0.67-2.43,0l-2.93-2.97l-2.94,2.98c-0.67,0.68-1.77,0.69-2.44,0.01
        c-0.68-0.68-0.68-1.78-0.01-2.47l2.95-2.99l-2.95-2.99c-0.67-0.68-0.66-1.77,0.02-2.45c0.68-0.68,1.77-0.67,2.43,0l2.93,2.97
        L26.6,66.95L26.6,66.95z M37.06,5.04v5c0,1.29-1.03,2.41-2.28,2.5c-0.27,0.09-0.58,0.13-0.89,0.13H24.6v10.35
        c15.56,0,31.13,0,46.69,0V12.68h-9.28c-0.31,0-0.63-0.04-0.89-0.13c-1.25-0.09-2.28-1.21-2.28-2.5v-5
        C51.58,5.04,44.32,5.04,37.06,5.04L37.06,5.04z M23.35,28.16c-1.03,0-2.01-0.45-2.68-1.12c-0.09-0.09-0.13-0.18-0.22-0.27
        c-0.54-0.67-0.89-1.52-0.89-2.41v-5.09H5.58c-0.13,0-0.31,0.04-0.4,0.18C5.09,19.55,5,19.73,5,19.86v97.39
        c0,0.18,0.04,0.31,0.18,0.4c0.09,0.09,0.22,0.18,0.4,0.18c28.69,0,56.05,0,84.74,0c0.18,0,0.31-0.09,0.4-0.18
        c0.13-0.09,0.18-0.22,0.18-0.4V19.86c0-0.13-0.09-0.31-0.18-0.4c-0.09-0.13-0.27-0.18-0.4-0.18H76.34v5.09
        c0,0.89-0.36,1.74-0.89,2.41c-0.09,0.09-0.13,0.18-0.22,0.27c-0.67,0.67-1.65,1.12-2.68,1.12C56.15,28.16,39.75,28.16,23.35,28.16
        L23.35,28.16z M5.62,122.88c-1.52,0-2.95-0.62-3.97-1.65C0.62,120.2,0,118.82,0,117.26V19.86c0-1.56,0.62-2.95,1.65-3.97
        c1.03-1.03,2.41-1.65,3.97-1.65h13.98v-2.77c0-1.03,0.4-1.96,1.12-2.68c0.67-0.67,1.61-1.12,2.68-1.12h8.66V4.2
        c0-1.16,0.49-2.19,1.25-2.95C34.07,0.49,35.09,0,36.25,0c7.8,0,15.59,0,23.39,0c1.16,0,2.19,0.49,2.95,1.25
        c0.76,0.76,1.25,1.79,1.25,2.95v3.48h8.66c1.07,0,2.01,0.45,2.68,1.12c0.71,0.71,1.12,1.65,1.12,2.68v2.77h13.98
        c1.56,0,2.95,0.62,3.97,1.65c1.03,1.03,1.65,2.41,1.65,3.97v97.39c0,1.56-0.62,2.95-1.65,3.97c-1.03,1.03-2.46,1.65-3.97,1.65
        C61.62,122.88,34.28,122.88,5.62,122.88L5.62,122.88z M76.37,99.6c1.38,0,2.54-1.12,2.54-2.54c0-1.38-1.12-2.54-2.54-2.54
        c-4.91,0-28.9,0-33.81,0c-1.43,0-2.54,1.16-2.54,2.54c0,1.43,1.16,2.54,2.54,2.54v0C47.47,99.6,71.46,99.6,76.37,99.6L76.37,99.6
        L76.37,99.6z M76.37,50.8c1.38,0,2.54-1.12,2.54-2.54c0-1.38-1.12-2.54-2.54-2.54c-4.91,0-28.9,0-33.81,0
        c-1.43,0-2.54,1.16-2.54,2.54c0,1.43,1.16,2.54,2.54,2.54C47.47,50.8,71.46,50.8,76.37,50.8L76.37,50.8z M76.37,74.87
        c1.38,0,2.54-1.12,2.54-2.54c0-1.38-1.12-2.54-2.54-2.54c-4.91,0-28.9,0-33.81,0c-1.43,0-2.54,1.16-2.54,2.54
        c0,1.43,1.16,2.54,2.54,2.54C47.47,74.87,71.46,74.87,76.37,74.87L76.37,74.87z M18.39,96.93c-0.69-1.03-0.42-2.43,0.62-3.13
        c1.03-0.69,2.43-0.42,3.13,0.62l1.23,1.82l4.87-5.93c0.79-0.96,2.21-1.1,3.17-0.31c0.96,0.79,1.1,2.21,0.31,3.17l-6.74,8.2
        c-0.15,0.19-0.33,0.36-0.54,0.51c-1.03,0.69-2.43,0.42-3.13-0.62L18.39,96.93L18.39,96.93L18.39,96.93z M18.39,50.66
        c-0.69-1.03-0.42-2.43,0.62-3.13c1.03-0.69,2.43-0.42,3.13,0.62l1.23,1.82l4.87-5.93c0.79-0.96,2.21-1.1,3.17-0.31
        c0.96,0.79,1.1,2.21,0.31,3.17l-6.74,8.2c-0.15,0.19-0.33,0.36-0.54,0.51c-1.03,0.69-2.43,0.42-3.13-0.62L18.39,50.66L18.39,50.66
        L18.39,50.66z"
                    fill={color}
                />
            </g>
        </svg>
    );
}