export const AIIcons = ({icon = 'stop'}) => {

    return (
        <div id="dots" className={`dots dots--${icon}`}>
            <div className="dots__container">
                <div className="dots__dot dots__dot--one"></div>
                <div className="dots__dot dots__dot--two"></div>
                <div className="dots__dot dots__dot--three"></div>
            </div>
        </div>
    );
}