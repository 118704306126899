import {createSelector} from "reselect";

// Выбор всех языков
export const selectAllLanguages = (state) => state.languages;

// Фильтр популярных языков
export const selectPopularLanguages = createSelector(
    [selectAllLanguages],
    (languages) => {
        const popularIds = ['en', 'zh', 'ar', 'fr', 'de', 'es', 'hi'];

        return languages
            .filter(language => popularIds.includes(language.code))
            .sort((a, b) => popularIds.indexOf(a.code) - popularIds.indexOf(b.code));
    }
);