import React, {useContext} from "react";
import {RoomContext} from "../../context/RoomContext";
import {QuizJoin} from "./QuizJoin";
import {QuizLoading} from "./QuizLoading";
import {QuizInProgress} from "./QuizInProgress";
import {QuizFinish} from "./QuizFinish";
import {QuizList} from "./QuizList";
import {YJSContext} from "../../context/YJSContext";

export const Quiz = React.memo(({room_id, room_language, quiz}) => {
    const {localShowQuiz, setLocalShowQuiz} = useContext(RoomContext);
    const {closeQuiz} = useContext(YJSContext);

    const renderQuizContent = () => {
        if (quiz.step === 'list') {
            return <QuizList room_id={room_id}/>
        } else if (quiz.step === 'join') {
            return <QuizJoin room_id={room_id} room_language={room_language} quiz={quiz}/>
        } else if (quiz.step === 'loading') {
            return <QuizLoading/>
        } else if (quiz.step === 'in-progress') {
            return <QuizInProgress room_id={room_id} quiz={quiz}/>
        } else if (quiz.step === 'finish') {
            return <QuizFinish room_id={room_id} quiz={quiz}/>
        }
    }

    return (
        <React.Fragment>
            <div className={`wrapper-quiz-active ${localShowQuiz && 'show'}`}>
                <div className="header-quiz d-flex justify-content-between">
                    <div className="m-2">
                        <button type="button"
                                className="btn btn-outline-secondary"
                                onClick={() => setLocalShowQuiz(false)}>
                            Hide
                        </button>
                    </div>

                    <div>
                        <p onClick={() => console.log(quiz)}
                           className="quiz-name text-white">{quiz.type ? quiz.type.type : 'List of quizzes'}</p>
                    </div>

                    <span className="m-2">
                        <button type="button" className="btn btn-danger"
                                onClick={() => closeQuiz(room_id)}>Close</button>
                    </span>
                </div>
                <div className="content-quiz overflow-auto border-bottom">
                    {renderQuizContent()}
                </div>
            </div>
        </React.Fragment>
    );
});