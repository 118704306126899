import React, {useState} from "react";
import {IconOnlineSales} from "../Icons/IconOnlineSales";
import {BagCheck, BagX, Check, XLg} from "react-bootstrap-icons";
import {IconMonth} from "../Icons/IconMonth";
import {ListFollowingForPayment} from "../ListFollowingForPayment";
import {buyAssistantSubscriptionRequest, buyRoomSubscriptionRequest} from "../../axios/userRequests";
import {useDispatch, useSelector} from "react-redux";
import {formatToFullLondonDate} from "../../utils/formatDate";
import {Tooltip} from "react-tooltip";
import {updateAssistantSubscriptionEnd, updateRoomSubscriptionEnd} from "../../redux/actions/user";

export const ModalPayment = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const [numberMonths, setNumberMonths] = useState(1);
    const [priceAssistant, setPriceAssistant] = useState(10);
    const [selectedFollow, setSelectedFollow] = useState(null);

    // Установить по клику
    const setPriceAndMonth = (quantity) => {
        if (quantity !== numberMonths) {
            setNumberMonths(quantity);
            setPriceAssistant(quantity * 10);
        }
    };

    // Обработка изменения вручную
    const handleChange = (e) => {
        const newValue = parseInt(e.target.value);
        if (!isNaN(newValue)) {
            setNumberMonths(newValue);
            setPriceAssistant(newValue * 10);
        } else {
            setNumberMonths(1);
            setPriceAssistant(10);
        }
    };

    const buyRoomSubscription = async () => {
        try {
            const user_id = selectedFollow ? selectedFollow.id : user.id;
            const res = await buyRoomSubscriptionRequest(user_id);
            dispatch(updateRoomSubscriptionEnd(res));
            console.log(res);
        } catch (e) {
            console.error(e);
        }
    }

    const buyAssistantSubscription = async () => {
        try {
            const user_id = selectedFollow ? selectedFollow.id : user.id;
            const data = {
                user_id: user_id,
                number_months: numberMonths,
            }
            const res = await buyAssistantSubscriptionRequest(data);
            dispatch(updateAssistantSubscriptionEnd(res));
            console.log(res);
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <div className='modal fade' id="modalPayment" tabIndex="-1"
             aria-labelledby="modalPayment" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered">
                <div className="modal-content bg-dark-modal border-modal text-white d-flex">
                    <div className="modal-header justify-content-between">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                            <IconOnlineSales color={'#fff'} size={30}/> <span className='mx-2'>Payment</span>
                        </h1>
                        <div data-bs-theme="dark">
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <p className="text-center h4 py-3">Your Subscription Status</p>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-5 col-12 border-radius-5px m-1 py-4 gradient-border">
                                <p className="brand-color h4 text-center">Room</p>
                                {(user.room_subscription_time.value > 0) ?
                                    <>
                                        <p className="d-flex align-items-end">
                                            <BagCheck color={'green'} size={26} style={{marginRight: '1px'}}/>
                                            <span className="text-success mx-1"
                                                  data-tooltip-id="subscribe-room-days"
                                                  data-tooltip-content={formatToFullLondonDate(user.room_subscription_end)}
                                            >
                                                {user.room_subscription_time.value}
                                            </span> {user.room_subscription_time.unit} remaining.
                                            <Tooltip id="subscribe-room-days"/>
                                        </p>
                                        <hr/>
                                        <p>
                                            Enjoy seamless access to create and join language practice rooms.
                                        </p>
                                    </>
                                    :
                                    <>
                                        <p className="d-flex align-items-end">
                                            <BagX color={'red'} size={26} style={{marginRight: '1px'}}/>
                                            <span className="text-danger mx-1">0</span> {user.room_subscription_time.unit}.
                                        </p>
                                        <hr/>
                                        <p>
                                            To continue enjoying seamless access to create and join language practice
                                            rooms, please make a payment.
                                        </p>
                                    </>
                                }
                            </div>
                            <div className="col-md-5 col-12 border-radius-5px m-1 py-3 gradient-border">
                                <p className="brand-color h4 text-center">Assistant</p>
                                {(user.assistant_subscription_time.value > 0) ?
                                    <>
                                        <p className="d-flex align-items-end">
                                            <BagCheck color={'green'} size={26} style={{marginRight: '1px'}}/>
                                            <span className="text-success mx-1"
                                                  data-tooltip-id="subscribe-assistant-days"
                                                  data-tooltip-content={formatToFullLondonDate(user.assistant_subscription_end)}
                                            >
                                                {user.assistant_subscription_time.value}
                                            </span> {user.assistant_subscription_time.unit} remaining.
                                            <Tooltip id="subscribe-assistant-days"/>
                                        </p>
                                        <hr/>
                                        <p>
                                            Continue improving your language skills with our AI-powered assistants.
                                        </p>
                                    </>
                                    :
                                    <>
                                        <p className="d-flex align-items-end">
                                            <BagX color={'red'} size={26} style={{marginRight: '1px'}}/>
                                            <span className="text-danger mx-1">0</span> {user.assistant_subscription_time.unit}.
                                        </p>
                                        <hr/>
                                        <p>
                                            To continue improving your language skills with our AI-powered assistants,
                                            please make a payment.
                                        </p>
                                    </>
                                }
                            </div>
                        </div>
                        <hr/>
                        <p className="text-center h4 py-3">Make a Payment</p>
                        <div className="d-flex justify-content-center">
                            <ul className="nav nav-pills border-bottom d-flex justify-content-between align-items-center my-2"
                                id="pills-tab"
                                role="tablist">
                                <div className="d-flex">
                                    <li className="nav-item" role="presentation"
                                        onClick={() => setSelectedFollow(null)}>
                                        <p className="social-nav-link pointer active" id="payment-for-me-tab"
                                           data-bs-toggle="pill"
                                           data-bs-target="#pills-payment-for-me" role="tab"
                                           aria-controls="pills-payment-for-me"
                                           aria-selected="true">For me
                                        </p>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <p className="social-nav-link pointer" id="payment-for-follow-tab"
                                           data-bs-toggle="pill"
                                           data-bs-target="#pills-payment-for-follow" role="tab"
                                           aria-controls="pills-payment-for-follow"
                                           aria-selected="false">For someone I follow
                                        </p>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-payment-for-me" role="tabpanel"
                                 aria-labelledby="payment-for-me-tab" tabIndex="0">
                            </div>
                            <div className="tab-pane fade" id="pills-payment-for-follow" role="tabpanel"
                                 aria-labelledby="payment-for-follow-tab"
                                 tabIndex="0">
                                <ListFollowingForPayment selectedFollow={selectedFollow}
                                                         setSelectedFollow={setSelectedFollow}/>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div
                                className="col-lg-5 col-12 border-radius-5px m-1 p-4 border-modal border-shadow d-flex flex-column">
                                <p className="brand-color h4">Room</p>
                                <p><span className="h2">$10</span>/year</p>
                                <hr/>
                                <p className="m-0">
                                    <Check color={'green'} size={26}/> Access to rooms
                                </p>
                                <p className="m-0">
                                    <Check color={'green'} size={26}/> Audio communication
                                </p>
                                <p className="m-0">
                                    <Check color={'green'} size={26}/> Video communication
                                </p>
                                <p className="m-0">
                                    <Check color={'green'} size={26}/> Screen sharing
                                </p>
                                <p className="m-0">
                                    <Check color={'green'} size={26}/> Shared chat
                                </p>
                                <p className="m-0">
                                    <Check color={'green'} size={26}/> Video watching
                                </p>
                                <p className="m-0">
                                    <Check color={'green'} size={26}/> Whiteboard
                                </p>
                                <p className="m-0">
                                    <Check color={'green'} size={26}/> Etherpad
                                </p>
                                <p className="m-0">
                                    <Check color={'green'} size={26}/> Interactive games
                                </p>
                                <hr/>
                                <p className="m-0">
                                    <Check color={'green'} size={26}/> Messenger
                                </p>
                                <hr/>
                                <p>
                                    {!selectedFollow ? (
                                        <span>For me</span>
                                    ) : (
                                        <div className="d-flex align-items-center">
                                            <span>For</span>
                                            <div className="d-flex align-items-center mx-2 border-selected-person px-2">
                                                <img
                                                    src={selectedFollow?.avatar}
                                                    alt="Avatar"
                                                    style={{ width: '30px', marginRight: '5px' }}
                                                    className="img-fluid rounded-circle my-1"
                                                />
                                                {selectedFollow?.name}
                                            </div>
                                        </div>
                                    )}
                                </p>

                                <div className="mt-auto">
                                    <button type="button"
                                            className="btn btn-primary w-100"
                                            onClick={buyRoomSubscription}>
                                        Buy
                                    </button>
                                </div>
                            </div>
                            <div
                                className="col-lg-5 col-12 border-radius-5px m-1 p-4 border-modal border-shadow d-flex flex-column">
                                <p className="brand-color h4">Assistant</p>
                                <p><span className="h2">$10</span>/month</p>
                                <hr/>
                                <p className="m-0">
                                    <Check color={'green'} size={26}/> AI Text Assistant
                                </p>
                                <p className="m-0">
                                    <Check color={'green'} size={26}/> AI Voice Assistant
                                </p>
                                <hr/>
                                <div className="mt-auto">
                                    <div className="d-flex align-items-center my-3">
                                        <IconMonth color={'#fff'} size={40}/>
                                        <XLg className="mx-2"/>
                                        <div
                                            className="d-flex justify-content-center align-items-center btn-number-months pointer"
                                            onClick={() => setPriceAndMonth(1)}>
                                            1
                                        </div>
                                        <div
                                            className="d-flex justify-content-center align-items-center btn-number-months pointer mx-2"
                                            onClick={() => setPriceAndMonth(3)}>
                                            3
                                        </div>
                                        <div
                                            className="d-flex justify-content-center align-items-center btn-number-months pointer"
                                            onClick={() => setPriceAndMonth(12)}>
                                            12
                                        </div>

                                        <input
                                            type="number"
                                            className="form-control text-center rounded"
                                            style={{width: '50px', marginLeft: '10px'}}
                                            value={numberMonths}
                                            onChange={handleChange}
                                            aria-label="count"
                                        />
                                    </div>

                                    <div className="mb-2">
                                        <span
                                            className="h3">${priceAssistant}</span>/{numberMonths} {numberMonths === 1 ? "Month" : "Months"}
                                    </div>
                                    <p>
                                        {!selectedFollow ? (
                                            <span>For me</span>
                                        ) : (
                                            <div className="d-flex align-items-center">
                                                <span>For</span>
                                                <div className="d-flex align-items-center mx-2 border-selected-person px-2">
                                                    <img
                                                        src={selectedFollow?.avatar}
                                                        alt="Avatar"
                                                        style={{ width: '30px', marginRight: '5px' }}
                                                        className="img-fluid rounded-circle my-1"
                                                    />
                                                    {selectedFollow?.name}
                                                </div>
                                            </div>
                                        )}
                                    </p>
                                    <button type="button"
                                            className="btn btn-primary w-100"
                                            onClick={buyAssistantSubscription}>
                                        Buy
                                    </button>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <p className="text-center h4 py-3">Payment History</p>
                        <p className="text-center">No Payment History</p>
                        <hr/>
                        <div className="d-flex justify-content-center">
                            <button type="button" className="btn btn-primary"
                                    data-bs-dismiss="modal">Okay 👍
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}