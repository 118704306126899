import {InfoCircle} from "react-bootstrap-icons";
import React, {useEffect, useState} from "react";
import {formatDateTimeOrDMY} from "../utils/formatDate";

export const PopoverOwnRoom = ({owner, created_at}) => {
    const [isOpenPopover, setIsOpenPopover] = useState(false);
    const toggleOpenPopover = () => {
        setIsOpenPopover(!isOpenPopover);
    };
    useEffect(() => {
        const handleOutsideClick = (event) => {
            // Проверяем, было ли кликнуто вне popover
            if (isOpenPopover && !event.target.closest('.tb-pop-container')) {
                setIsOpenPopover(false);
            }
        };

        // Добавляем обработчик события для клика по всему документу
        document.addEventListener('click', handleOutsideClick);

        return () => {
            // Удаляем обработчик события при размонтировании компонента
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isOpenPopover]);

    return (
        <div className="tb-pop-container" onClick={toggleOpenPopover}>
            <div className={`tb-popover-wrapper ${isOpenPopover ? 'open' : ''}`}>
                <div>
                    <InfoCircle size={24}/>
                </div>
                <div className="tb-popover-content bg-dark-modal border-shadow border-modal text-silver">
                    <p className="mx-2 d-flex align-items-center mt-2">
                        Room owner
                    </p>
                    <hr />
                    <div className="d-flex justify-content-center">
                        <img src={owner.avatar}
                             alt="Web Development Agency | TechieBundle"
                             className="info-owner-room-img"
                        />
                    </div>
                    <p className="info-owner-room">
                        {owner.name}
                    </p>
                    <p className="info-owner-room">
                        Country: {owner.country}
                    </p>
                    <hr/>
                    <p className="info-owner-room">
                        <span className="info-owner-room-data">
                            Room created at:
                        </span> {formatDateTimeOrDMY(created_at)}
                    </p>
                </div>
            </div>
        </div>
    );
};