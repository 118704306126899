/**
 * Определить операционную систему пользователя
 */
export const getOS = () => {
    const userAgent = window.navigator.userAgent;
    let os = "Unknown";

    if (userAgent.indexOf('Win') !== -1) os = 'Windows';
    if (userAgent.indexOf('Mac') !== -1) os = 'MacOS';
    if (userAgent.indexOf('X11') !== -1) os = 'UNIX';
    if (userAgent.indexOf('Linux') !== -1) os = 'Linux';

    return os;
};

/**
 * Определить устройство клиента: мобильное или десктопное.
 */
export const getDeviceType = () => {
    const userAgent = window.navigator.userAgent;
    if (/Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
        return 'Mobile';
    }
    return 'Desktop';
};

/**
 * Определить является лм устройство клиента мобильным.
 */
export const isMobileDevice = () => {
    const userAgent = window.navigator.userAgent;
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
};

/**
 * Определить является лм устройство клиента десктопным.
 */
export const isDesktopDevice = () => {
    const userAgent = window.navigator.userAgent;
    return !/Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
};

/**
 * Получить список доступных камер на устройстве
 */
export const getVideoDevices = async () => {
    try {
        if (isMobileDevice()) {
            return [
                {
                    deviceId: "user",
                    label: "Front"
                },
                {
                    deviceId: "environment",
                    label: "Back"
                }
            ];
        } else {
            const mediaDevices = await navigator.mediaDevices.enumerateDevices();
            return mediaDevices.filter(device => device.kind === 'videoinput');
        }
    } catch (error) {
        console.error('Error fetching media devices:', error);
    }
};

/**
 * Запросить доступ к камере.
 * mobile = true доступ на мобильных устройствах
 * mobile = false доступ на десктопных устройствах
 */
export const getAccessUserVideo = async (device) => {
    try {
        if (isMobileDevice()) {
            return await navigator.mediaDevices.getUserMedia({ video: { facingMode: { exact: device } } });
        } else {
            return await navigator.mediaDevices.getUserMedia({ video: { deviceId: { exact: device } } });
        }
    } catch (error) {
        console.error('Error accessing the video stream:', error);
        throw error;
    }
}

/**
 * Возвращает true если приложение запущено на мобильном устройстве в pwa режиме.
 * Иначе возвращает false.
 */
export const isInMobileStandaloneMode  = () => {
    return isMobileDevice() && (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone);
}