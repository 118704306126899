import React from "react";
import {RoomWrapperButtonsGames} from "./RoomWrapperButtonsGames";
import {Quiz} from "./Quiz/Quiz";
import {Roulette} from "./Roulette/Roulette";

export const RoomGames = React.memo(({room_id, room_language, quiz, roulette}) => {

    return (
        <React.Fragment>
            <RoomWrapperButtonsGames quiz={quiz} roulette={roulette}/>

            {quiz.isActive && <Quiz room_id={room_id} room_language={room_language} quiz={quiz}/>}
            {roulette.isActive && <Roulette room_id={room_id} roulette={roulette} room_language={room_language}/>}
        </React.Fragment>
    );
});