export const IconGames = ({color = '#000000', size = 100}) => {

    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 122.88 113.29"
            style={{enableBackground: 'new 0 0 122.88 113.29'}}
            xmlSpace="preserve"
            width={size} height={size}
        >
            <style type="text/css">
                {`
        .st0 { fill-rule: evenodd; clip-rule: evenodd; }
      `}
            </style>
            <g>
                <path
                    className="st0"
                    d="M72.17,60.87l38-6.11c3.37-0.54,6.58,1.78,7.12,5.15l5.51,34.24c0.54,3.37-1.78,6.58-5.15,7.12l-38,6.11
        c-3.37,0.54-6.58-1.78-7.12-5.15l-5.51-34.24C66.48,64.62,68.8,61.41,72.17,60.87L72.17,60.87z M91.64,76.94l-7.35-0.08
        c0.22-2.37,1.06-4.3,2.55-5.8c1.48-1.49,3.77-2.5,6.85-3.01c3.55-0.59,6.21-0.36,8.02,0.7c1.8,1.06,2.87,2.59,3.2,4.59
        c0.2,1.17,0.05,2.29-0.43,3.35s-1.3,2.05-2.48,2.98c1.09,0.08,1.95,0.25,2.56,0.51c1,0.41,1.82,1.02,2.47,1.85
        c0.65,0.83,1.09,1.87,1.3,3.13c0.26,1.58,0.11,3.17-0.48,4.77c-0.59,1.59-1.59,2.91-3.02,3.95c-1.43,1.04-3.41,1.78-5.95,2.2
        c-2.47,0.41-4.47,0.45-5.99,0.1c-1.53-0.34-2.84-1-3.95-1.96c-1.1-0.97-2.05-2.26-2.83-3.88l7.39-2.27
        c0.55,1.48,1.19,2.46,1.91,2.95c0.72,0.49,1.56,0.65,2.53,0.49c1.01-0.17,1.79-0.68,2.34-1.54c0.55-0.85,0.72-1.9,0.51-3.14
        c-0.21-1.26-0.7-2.18-1.46-2.77c-0.76-0.58-1.7-0.78-2.81-0.6c-0.59,0.1-1.38,0.38-2.36,0.85l-0.51-5.47
        c0.42-0.01,0.75-0.03,0.98-0.07c0.98-0.16,1.74-0.62,2.29-1.35c0.55-0.74,0.75-1.53,0.61-2.39c-0.14-0.83-0.5-1.45-1.06-1.85
        c-0.58-0.41-1.29-0.55-2.15-0.4c-0.89,0.15-1.56,0.53-2.03,1.16C91.83,74.57,91.61,75.57,91.64,76.94L91.64,76.94z M62.77,11.41
        l4.62,27.72l-7.65,1.28l-3.02-18.13c-1.08,1.15-2.15,2.1-3.22,2.87c-1.06,0.77-2.43,1.57-4.08,2.39l-1.03-6.17
        c2.44-1.27,4.27-2.6,5.5-4.01c1.23-1.41,2.11-3.04,2.63-4.9L62.77,11.41L62.77,11.41z M17.77,56.97l36.92,10.88
        c3.28,0.97,5.17,4.44,4.2,7.72l-9.8,33.26c-0.97,3.28-4.44,5.17-7.72,4.2L4.46,102.16c-3.28-0.97-5.17-4.44-4.2-7.72l9.8-33.26
        C11.02,57.9,14.49,56.01,17.77,56.97L17.77,56.97z M44.48,86l-5.06-0.72c-0.28,0.32-0.59,0.63-0.93,0.92
        c-1.28,1.1-3.32,2.13-6.12,3.08c-1.66,0.54-2.77,0.94-3.35,1.18c-0.58,0.25-1.27,0.58-2.08,1.01l10.68,3.16l-1.65,5.56l-20.51-6.06
        c0.83-1.95,2.11-3.65,3.83-5.07c1.72-1.43,4.57-2.88,8.57-4.34c0.76-0.28,1.43-0.54,2.03-0.79l0,0c1.34-0.56,2.28-1.04,2.83-1.46
        c0.79-0.61,1.29-1.26,1.49-1.95c0.22-0.75,0.14-1.47-0.26-2.17c-0.4-0.7-1.02-1.17-1.86-1.42c-0.87-0.26-1.67-0.19-2.38,0.19
        c-0.72,0.39-1.38,1.25-1.98,2.59l-6.67-2.58c0.84-1.85,1.78-3.2,2.81-4.07c1.04-0.87,2.29-1.4,3.76-1.59
        c1.48-0.19,3.36,0.05,5.67,0.74c2.4,0.71,4.19,1.54,5.37,2.48c1.17,0.94,1.98,2.09,2.4,3.45c0.43,1.37,0.44,2.75,0.02,4.16
        c-0.33,1.1-0.88,2.09-1.66,2.99L44.48,86L44.48,86z M36.49,6.19l38-6.11c3.37-0.54,6.58,1.78,7.12,5.15l5.51,34.24
        c0.54,3.37-1.78,6.58-5.15,7.12l-38,6.11c-3.37,0.54-6.58-1.78-7.12-5.15l-5.51-34.24C30.8,9.94,33.12,6.73,36.49,6.19L36.49,6.19z"
                    fill={color}
                />
            </g>
        </svg>
    );
}