import {addDays, isAfter, parseISO} from 'date-fns';

/**
 * Добавить данные в local storage с истечением срока
 */
export const setItemWithExpiry = (key, value, days) => {
    const now = new Date();
    const expiryDate = addDays(now, days);

    const item = {
        value: value,
        expiry: expiryDate.toISOString(),
    };
    localStorage.setItem(key, JSON.stringify(item));
}

/**
 * Получить данные с local storage. Если время истекло, возвращается null.
 */
export const getItemWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (isAfter(now, parseISO(item.expiry))) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}


export const getRoomHistory = () => {
    return JSON.parse(localStorage.getItem('roomHistory')) || [];
}


export const addRoomToHistory = (roomId, maxHistorySize = 10) => {
    // Получаем текущую историю из localStorage
    let roomHistory = JSON.parse(localStorage.getItem('roomHistory')) || [];

    // Фильтруем комнату, если она уже есть в истории
    roomHistory = roomHistory.filter(id => id !== roomId);

    // Добавляем id комнаты в конец истории
    roomHistory = [...roomHistory, roomId];

    // Ограничиваем количество элементов в истории
    if (roomHistory.length > maxHistorySize) {
        roomHistory = roomHistory.slice(-maxHistorySize); // Оставляем только последние maxHistorySize элементов
    }

    // Сохраняем обновленную историю обратно в localStorage
    localStorage.setItem('roomHistory', JSON.stringify(roomHistory));
}
