import {useEffect} from "react";
import {updateAssistantSubscriptionTime, updateRoomSubscriptionTime} from "../redux/actions/user";
import {useDispatch, useSelector} from "react-redux";


/**
 * Обновлять оставшиеся минуты подписок
 */
export const useCheckSubscribesTime = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    // обновлять каждую минуту оставшееся время на room подписку если осталось меньше часа
    useEffect(() => {
        if (!user.id) return;
        const updateTime = () => {
            if (user.room_subscription_time.unit === 'minutes') {
                console.log('update');
                dispatch(updateRoomSubscriptionTime());
            }
        };

        if (user.room_subscription_time.unit === 'minutes') {
            const interval = setInterval(updateTime, 60000); // Обновление каждую минуту

            return () => clearInterval(interval);
        }
    }, [dispatch, user.id, user.room_subscription_time.unit]);

    // обновлять время на room подписку если осталось меньше часа когда вкладка становиться видимой
    useEffect(() => {
        if (!user.id) return;
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                if (user.room_subscription_time.unit === 'minutes') {
                    console.log('update visibility');
                    dispatch(updateRoomSubscriptionTime());
                }
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [dispatch, user.id, user.room_subscription_time.unit]);



    // обновлять каждую минуту оставшееся время на assistant подписку если осталось меньше часа
    useEffect(() => {
        if (!user.id) return;
        const updateTime = () => {
            if (user.assistant_subscription_time.unit === 'minutes') {
                console.log('update');
                dispatch(updateAssistantSubscriptionTime());
            }
        };

        if (user.assistant_subscription_time.unit === 'minutes') {
            const interval = setInterval(updateTime, 60000); // Обновление каждую минуту

            return () => clearInterval(interval);
        }
    }, [dispatch, user.id, user.assistant_subscription_time.unit]);

    // обновлять время на assistant подписку если осталось меньше часа когда вкладка становиться видимой
    useEffect(() => {
        if (!user.id) return;
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                if (user.assistant_subscription_time.unit === 'minutes') {
                    console.log('update visibility');
                    dispatch(updateAssistantSubscriptionTime());
                }
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [dispatch, user.id, user.assistant_subscription_time.unit]);

}