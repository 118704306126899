import React, {useContext} from "react";
import {useSelector} from "react-redux";
import {YJSContext} from "../../context/YJSContext";
import {Slide, toast} from "react-toastify";

export const RouletteList = ({room_id, room_language}) => {
    const rouletteTypes = useSelector(state => state.rouletteTypes);
    const {openRoulette, openCustomRoulette, openUsersRoulette} = useContext(YJSContext);

    const handleStartRoulette = async (type) => {
        try {
            const data = {
                type_id: type.id,
                topic: type.type,
                language: room_language
            }
            await openRoulette(room_id, data);
        } catch (e) {
            toast.error('Something went wrong...', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide,
            });
        }
    }

    return (
        <div className="d-flex justify-content-center">
            <div className="wrapper-list-quizzes my-3">
                <p className="list-roulette pointer" onClick={() => openCustomRoulette(room_id)}>
                    1. Custom Roulette
                </p>
                <p className="list-roulette pointer" onClick={() => openUsersRoulette(room_id)}>
                    2. Users Roulette
                </p>
                {rouletteTypes.map((type, index) => (
                    <p key={index} className="list-roulette pointer" onClick={() => handleStartRoulette(type)}>
                        {index + 3}. {type.type}
                    </p>
                ))}
            </div>
        </div>
    );
}