import React from "react";
import {SocialTab} from "../Social/SocialTab";

export const Social = () => {
    return (
        <div className="offcanvas offcanvas-bottom offcanvas-size-social bg-dark-modal text-white border-modal"
             tabIndex="-1" id="offcanvasSocial"
             aria-labelledby="offcanvasSocial">
            <div className="offcanvas-body">
                <div><SocialTab/></div>
            </div>
        </div>
    );
}