import {IconConnectedPeople} from "../Icons/IconConnectedPeople";
import React from "react";
import {useSelector} from "react-redux";

export const ButtonSocial = ({addClass = ''}) => {
    const user = useSelector(state => state.user);
    const privateChat = useSelector(state => state.privateChat);
    const hasUnreadMessages = () => {
        return privateChat.data.some(chat =>
            chat.messages.some(message => message.is_read === 0 && message.sender_id !== user.id)
        );
    };

    return (
        <>
            {user.id &&
                <div className={`${addClass} social-button ${hasUnreadMessages() && 'no-read'} d-flex align-items-center justify-content-center pointer`}
                     data-bs-toggle="offcanvas" data-bs-target="#offcanvasSocial" aria-controls="offcanvasSocial">
                    <IconConnectedPeople color={'#fff'} size={40} />
                </div>
            }
        </>
    );
}