import React, {useState} from "react";
import {useSelector} from "react-redux";
import {getAllUsersWithFriendshipAndRoomStatus} from "../../redux/selectors/followsSelectors";
import {SocialPeopleList} from "./SocialPeopleList";

export const SocialPeopleTab = () => {
    const user = useSelector(state => state.user);
    const privateChat = useSelector(state => state.privateChat);
    const [openChats, setOpenChats] = useState({});
    const {myFollowing} = useSelector(state => getAllUsersWithFriendshipAndRoomStatus(state, user.id));
    const [searchTerm, setSearchTerm] = useState('');

    const privateChatById = privateChat.data.reduce((acc, chat) => {
        acc[chat.user.id] = chat;
        return acc;
    }, {});

    const toggleOpenChat = (chatId) => {
        setOpenChats(prevState => ({
            ...prevState,
            [chatId]: !prevState[chatId]
        }));
    };

    const filteredUsers = myFollowing?.filter(user =>
        user.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="">
            <ul className="nav nav-pills mx-2" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <p className="social-nav-link pointer active" id="pills-social-people-all-tab" data-bs-toggle="pill"
                       data-bs-target="#pills-social-people-all" role="tab" aria-controls="pills-social-people-all"
                       aria-selected="true">All
                    </p>
                </li>
                <li className="nav-item" role="presentation">
                    <p className="social-nav-link pointer" id="pills-social-people-friends-tab" data-bs-toggle="pill"
                       data-bs-target="#pills-social-friends-all" role="tab" aria-controls="pills-social-friends-all"
                       aria-selected="false">Friends
                    </p>
                </li>
                <li className="nav-item" role="presentation">
                    <p className="social-nav-link pointer" id="pills-social-people-following-tab" data-bs-toggle="pill"
                       data-bs-target="#pills-social-following-all" role="tab"
                       aria-controls="pills-social-following-all"
                       aria-selected="false">Following
                    </p>
                </li>
            </ul>
            <form className="d-flex" role="search" onSubmit={(e) => e.preventDefault()}>
                <input
                    className="form-control me-2 m-2 bg-dark-input text-white"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </form>
            <div className="tab-content wrapper-people-list" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-social-people-all" role="tabpanel"
                     aria-labelledby="pills-social-people-all-tab" tabIndex="0">
                    {filteredUsers?.map((data, index) => (
                        <SocialPeopleList
                            key={index}
                            data={data}
                            index={index}
                            openChats={openChats}
                            toggleOpenChat={toggleOpenChat}
                            privateChatById={privateChatById}
                            typeChat={"all"}
                        />
                    ))}


                </div>
                <div className="tab-pane fade" id="pills-social-friends-all" role="tabpanel"
                     aria-labelledby="pills-social-people-friends-tab"
                     tabIndex="0">
                    {filteredUsers?.filter(user => user.is_friend)?.map((friend, index) => (
                        <SocialPeopleList
                            key={index}
                            data={friend}
                            index={index}
                            openChats={openChats}
                            toggleOpenChat={toggleOpenChat}
                            privateChatById={privateChatById}
                            typeChat={"friend"}
                        />
                    ))}
                </div>
                <div className="tab-pane fade" id="pills-social-following-all" role="tabpanel"
                     aria-labelledby="pills-social-people-following-tab"
                     tabIndex="0">
                    {filteredUsers?.filter(user => !user.is_friend)?.map((follow, index) => (
                        <SocialPeopleList
                            key={index}
                            data={follow}
                            index={index}
                            openChats={openChats}
                            toggleOpenChat={toggleOpenChat}
                            privateChatById={privateChatById}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}