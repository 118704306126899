export const setAssistantChats = (data) => ({
    type: 'SET_ASSISTANT_CHATS',
    payload: data,
});

export const addTextMessageToAssistantChat = (data) => ({
    type: 'ADD_TEXT_MESSAGE_TO_ASSISTANT_CHAT',
    payload: { data }
});

export const addVoiceMessageToAssistantChat = (data) => ({
    type: 'ADD_VOICE_MESSAGE_TO_ASSISTANT_CHAT',
    payload: { data }
});

export const deleteTextAssistantChat = (data) => ({
    type: 'DELETE_TEXT_ASSISTANT_CHAT',
    payload: { data }
});

export const deleteVoiceAssistantChat = (data) => ({
    type: 'DELETE_VOICE_ASSISTANT_CHAT',
    payload: { data }
});