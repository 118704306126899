import React, {useRef} from "react";
import {BoxArrowInRight, XLg} from "react-bootstrap-icons";
import {useAuth} from "../../hooks/useAuth";

export const ModalForUserNotAuth = () => {
    const modalRef = useRef(null);
    const additionalSuccessAction = () => {
        if (modalRef.current) {
            modalRef.current.click();
        }
    };
    const {googleLogin} = useAuth(additionalSuccessAction);

    return (
        <div className='modal fade' id='ModalForUserNotAuth' tabIndex="-1" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-for-not-auth">
                <div className="modal-content">
                    <div className="modal-header justify-content-center">
                        <BoxArrowInRight size={60} color={"white"}/>
                        <span ref={modalRef} className="close pointer" data-dismiss="modal"
                              aria-hidden="true" data-bs-dismiss="modal" aria-label="Close"><XLg size={24}/></span>
                    </div>
                    <div className="modal-body text-center">
                        <p className="modal-question">You are not authorized?</p>
                        <p>You must be logged in to use the app's features.</p>
                        <button className="width-150px btn-dark-default btn-be-purple" data-dismiss="modal"
                                onClick={googleLogin}><span>Sign In</span></button>
                    </div>
                </div>
            </div>
        </div>
    );
}