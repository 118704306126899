import http from "./index";

// получить все личные чаты
export const getAllPrivateChatRequest = async () => {
    try {
        const res = await http.get('private-chat');
        return res.data;
    } catch (err) {
        throw err;
    }
}

// сохранить сообщение в private chat
export const saveMessageToPrivateChat = async (messageData) => {
    try {
        return await http.post('private-chat', messageData)
    } catch (err) {
        throw err;
    }
}

// получить все личные чаты
export const deletePrivateChatRequest = async (data) => {
    try {
        const res = await http.delete('private-chat', {data});
        return res.data;
    } catch (err) {
        throw err;
    }
}