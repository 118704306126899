import {CardText, Flag, GraphUpArrow, InfoCircle, PeopleFill, ShieldLock} from "react-bootstrap-icons";
import React, {useContext} from "react";
import {useSelector} from "react-redux";
import {useFollows} from "../../hooks/useFollows";
import {getAllUsersWithFriendshipAndRoomStatus} from "../../redux/selectors/followsSelectors";
import {Tooltip} from "react-tooltip";
import {YJSContext} from "../../context/YJSContext";

export const ModalInfoRoom = ({room}) => {
    const {addToRoomBlacklist} = useContext(YJSContext);
    const user = useSelector(state => state.user);
    const {myFollowing} = useSelector(state => getAllUsersWithFriendshipAndRoomStatus(state, user.id));
    const {onFollow, unfollow} = useFollows();

    const banUser = async (idBannedUser) => {
        try {
            addToRoomBlacklist(room.room_id, idBannedUser);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="modal fade" id="infoRoom" tabIndex="-1" aria-labelledby="infoRoom"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content bg-dark-modal text-white border-modal">
                    <div className="modal-header d-flex justify-content-between">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                            <InfoCircle size={30}/> <span className='mx-2'>Information Room</span>
                        </h1>
                        <div data-bs-theme="dark">
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <p className="header-section-info-room">General</p>
                        <div className='mx-2'>
                            <p><Flag data-tooltip-id="info-room-language"
                                     data-tooltip-content="Language"/>
                                <span className="mx-2">{room.language}</span>
                            </p>
                            <Tooltip id="info-room-language"/>
                            <p><GraphUpArrow data-tooltip-id="info-room-level"
                                             data-tooltip-content="Level"/>
                                <span className="mx-2">{room.level}</span>
                            </p>
                            <Tooltip id="info-room-level"/>
                            <p>
                                <PeopleFill data-tooltip-id="info-room-users"
                                            data-tooltip-content="Users"/>
                                <span className="mx-2">
                                    {room.connected_users.length} / {(room.quantity === 0 ? 'Unlimited' : room.quantity)}
                                </span>
                            </p>
                            <Tooltip id="info-room-users"/>
                            <p><CardText data-tooltip-id="info-room-desc"
                                         data-tooltip-content="Description"/>
                                <span className="mx-2">{room.description}</span>
                            </p>
                            <Tooltip id="info-room-desc"/>
                            <p><ShieldLock data-tooltip-id="info-room-access"
                                           data-tooltip-content="Access"/>
                                <span className="mx-2">{room.access === 1 ? 'Public' : 'Private'}</span>
                            </p>
                            <Tooltip id="info-room-access"/>
                        </div>
                        <hr/>
                        <div>
                            <p className="header-section-info-room">Room owner</p>
                            <div className="d-flex mx-2">
                                <img src={room.owner.avatar} alt="avatar" className="avatar-user-list me-2"/>
                                <p>{room.owner.name}</p>
                            </div>
                        </div>
                        <hr/>
                        <div>
                            <p className="header-section-info-room">Connected users: {room?.connected_users.length}</p>
                            {room.connected_users.map((currentUser, index) => (
                                <div className="d-flex justify-content-between m-2" key={index}>
                                    <div className="d-flex">
                                        <img src={currentUser.avatar} alt="avatar" className="avatar-user-list me-2"/>
                                        <p>{currentUser.name} {user.id === currentUser.id && '(You)'}</p>
                                    </div>
                                    <div className="d-flex">
                                        {user.id !== currentUser.id && (
                                            <>
                                                {myFollowing?.some((id) => id.id === currentUser.id) ? (
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-secondary mx-2"
                                                        onClick={() => unfollow(currentUser.id)}
                                                    >
                                                        Unfollow
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary mx-2"
                                                        onClick={() => onFollow(currentUser)}
                                                    >
                                                        Follow
                                                    </button>
                                                )}
                                                {room.owner.id === user.id &&
                                                    <button type="button"
                                                            className="btn btn-danger"
                                                            onClick={() => banUser(currentUser.id)}>
                                                        Kick
                                                    </button>
                                                }
                                            </>
                                        )}
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}