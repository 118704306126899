export const IconMonth = ({color = '#000000', size = 100}) => {

    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 119.97"
             width={size} height={size}>
            <title>timetable</title>
            <path
                d="M69.76,4.06C69.76,1.83,72,0,74.71,0s5,1.81,5,4.06V21.8c0,2.23-2.2,4.06-5,4.06s-4.95-1.81-4.95-4.06V4.06ZM87.08,75.55a3.16,3.16,0,1,1,6.31,0V89.36h9.86a3.16,3.16,0,1,1,0,6.31h-13a3.17,3.17,0,0,1-3.16-3.16v-17Zm-72.71,2.5H25.71A1.31,1.31,0,0,1,27,79.36v8.38a1.31,1.31,0,0,1-1.31,1.31H14.37a1.31,1.31,0,0,1-1.31-1.31V79.36a1.31,1.31,0,0,1,1.31-1.31ZM57.79,54.17H69.13a1.32,1.32,0,0,1,.9.36A40.37,40.37,0,0,0,58.66,65.18h-.87a1.31,1.31,0,0,1-1.31-1.31V55.49a1.32,1.32,0,0,1,1.31-1.32Zm-21.71,0H47.42a1.31,1.31,0,0,1,1.31,1.31v8.38a1.31,1.31,0,0,1-1.31,1.31H36.08a1.31,1.31,0,0,1-1.31-1.31V55.48a1.31,1.31,0,0,1,1.31-1.31Zm-21.71,0H25.71A1.31,1.31,0,0,1,27,55.48v8.38a1.31,1.31,0,0,1-1.31,1.31H14.37a1.31,1.31,0,0,1-1.31-1.31V55.48a1.31,1.31,0,0,1,1.31-1.31ZM36.08,78.05H47.42a1.31,1.31,0,0,1,1.31,1.31v8.38a1.31,1.31,0,0,1-1.31,1.31H36.08a1.31,1.31,0,0,1-1.31-1.31V79.36a1.31,1.31,0,0,1,1.31-1.31Zm67.41-18.51a31.41,31.41,0,1,1-43.43,29A31.92,31.92,0,0,1,79.61,59.33a33.06,33.06,0,0,1,23.88.21Zm6.63,10.87c-13-13-34.95-9.33-42.56,7A26.36,26.36,0,1,0,115.2,77a24.36,24.36,0,0,0-5.08-6.62ZM25.33,4.06c0-2.23,2.2-4.06,5-4.06s4.95,1.81,4.95,4.06V21.8c0,2.23-2.21,4.06-4.95,4.06s-5-1.81-5-4.06V4.06ZM99.66,38.8V18.37a2.46,2.46,0,0,0-.73-1.76,2.51,2.51,0,0,0-1.76-.73h-9a2.74,2.74,0,1,1,0-5.48h9a8,8,0,0,1,8,8V50.82a41,41,0,0,0-5.56-1.49V44.26H5.45V97.17a2.46,2.46,0,0,0,.73,1.76,2.51,2.51,0,0,0,1.76.73H52.71a40.27,40.27,0,0,0,1.92,5.54H8a8,8,0,0,1-8-8V18.39a8,8,0,0,1,8-8h9.64a2.74,2.74,0,1,1,0,5.48H8a2.46,2.46,0,0,0-1.76.73,2.51,2.51,0,0,0-.73,1.76V38.8ZM43.13,15.87a2.74,2.74,0,0,1,0-5.48H61.52a2.74,2.74,0,1,1,0,5.48Z"
                fill={color}
            />
        </svg>
    );
}