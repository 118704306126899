// Функция анализа аудио для проверки тишины
export const analyzeAudio = async (blob) => {
    return new Promise((resolve) => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const reader = new FileReader();

        reader.onload = function () {
            audioContext.decodeAudioData(reader.result, (audioBuffer) => {
                const rawData = audioBuffer.getChannelData(0); // Получаем данные из первого канала
                const samples = rawData.length;
                let sum = 0;

                // Рассчитываем RMS для оценки уровня звука
                for (let i = 0; i < samples; i++) {
                    sum += rawData[i] ** 2;
                }

                const rms = Math.sqrt(sum / samples);

                // Пороговое значение RMS. Можно отрегулировать в зависимости от чувствительности
                const silenceThreshold = 0.02;

                // Если RMS меньше порога, то считаем, что запись - это тишина
                resolve(rms < silenceThreshold);
            });
        };

        reader.readAsArrayBuffer(blob);
    });
};