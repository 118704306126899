import React, {createContext, useCallback, useEffect, useRef, useState} from "react";
import {proxy} from "valtio";
import {bind} from "valtio-yjs";
import * as Y from "yjs";
import {WebsocketProvider} from "y-websocket";
import {getQuizRequest} from "../axios/quizRequests";
import {getRouletteRequest} from "../axios/rouletteRequests";
import {updatedItem} from "../utils/rouletteHelpers";
import {useSelector} from "react-redux";
import {getAllUsersWithFriendshipAndRoomStatus} from "../redux/selectors/followsSelectors";
import {getCurrentUnixInUTS} from "../utils/formatDate";

export const YJSContext = createContext(null);

const state = proxy({});
const fiveMinute = 300000 // 5 минут (300000 миллисекунд)

export const YJSProvider = ({children}) => {
    const user = useSelector(state => state.user);
    const {myFollowing} = useSelector(state => getAllUsersWithFriendshipAndRoomStatus(state, user.id));
    const [infoMyFollowing, setInfoMyFollowing] = useState([]);
    const ywsHost = process.env.REACT_APP_YWS_HOST || 'https://speak-stream-yws-server-dev-81cf2bb8945f.herokuapp.com';

    const yDoc = useRef(null);
    const roomsMap = useRef(null);
    const provider = useRef(null);

    useEffect(() => {
        if (!ywsHost) return;

        yDoc.current = new Y.Doc();
        roomsMap.current = yDoc.current.getMap('rooms');
        provider.current = new WebsocketProvider(ywsHost, 'speak-stream', yDoc.current);

        if (provider.current && !provider.current.connected) {
            provider.current.connect();
        }

        const unbindRooms = bind(state, roomsMap.current);

        const processState = () => {
            // Проходим по всем комнатам
            for (const roomId in state) {
                const room = state[roomId];

                // Проверяем, если `connected_users` равно 0
                if (room.connected_users.length === 0) {
                    if ((getCurrentUnixInUTS() - room.updated_at) > fiveMinute) {
                        removeRoomById(room.room_id);
                    }
                }
            }
        };

        processState();

        // Устанавливаем интервал для вызова функции обработки
        const intervalId = setInterval(processState, fiveMinute);

        return () => {
            unbindRooms();
            clearInterval(intervalId);

            // Отключение и очистка
            if (provider.current.connected) {
                provider.current.disconnect();
            }

            yDoc.current.destroy();
            provider.current.destroy();
        };
    }, [ywsHost]);

    useEffect(() => {
        if (!user.id || !provider.current?.awareness) return;

        provider.current.awareness.setLocalState({
            user_id: user.id
        });

        // Очистка состояния при размонтировании
        return () => {
            provider.current.awareness.setLocalState(null);
        };

    }, [user.id]);

    // Отслеживаем myFollowing: онлайн.
    useEffect(() => {
        if (!myFollowing || myFollowing.length === 0 || !provider.current?.awareness) return;

        const followingIds = myFollowing.map(user => user.id);

        const handleAwarenessChange = () => {
            if (!followingIds || followingIds.length === 0) return;

            const allUsers = Array.from(provider.current.awareness.getStates().values());
            const filteredUsers = allUsers.filter(u => u.user_id && followingIds.includes(u.user_id));
            setInfoMyFollowing(filteredUsers);
        };

        provider.current.awareness.on('change', handleAwarenessChange);

        return () => {
            provider.current.awareness.off('change', handleAwarenessChange);
        };

    }, [myFollowing]);


    /**
     * Room
     */

    // Функция для добавления новой комнаты
    const addRoom = (roomData) => {
        state[roomData.room_id] = roomData;
    };

    // Функция для удаления всех комнат с одинаковым room_id
    const removeRoomById = (room_id) => {
        delete state[room_id];
    };

    // Функция для обновления комнаты
    const updateRoom = (data) => {
        if (data.room_id in state) {
            Object.assign(state[data.room_id], data);
        }
    };

    // Функция для обновления доступа к комнате
    const updateRoomAccess = (room_id) => {
        const roomToUpdate = state[room_id];
        if (roomToUpdate) {
            roomToUpdate.access = roomToUpdate.access === 1 ? 0 : 1;
        }
    };

    // Функция для добавления пользователя в комнату
    const addUserToRoom = (room_id, user, session_id) => {
        const roomToUpdate = state[room_id];
        if (roomToUpdate) {
            const userExists = roomToUpdate.connected_users.some(currentUser => currentUser.id === user.id);
            if (!userExists) {
                user.session_id = session_id;
                roomToUpdate.connected_users.push(user);
            }
        }
    };

    // Функция для удаления пользователя из комнаты
    const removeUserFromRoom = useCallback((room_id, user_id) => {
        const roomToUpdate = state[room_id];

        if (roomToUpdate) {
            roomToUpdate.connected_users = roomToUpdate.connected_users.filter(
                currentUser => currentUser.id !== user_id
            );
            roomToUpdate.updated_at = getCurrentUnixInUTS();
        }
    }, []);

    // Функция для добавления пользователя в черный список комнаты
    const addToRoomBlacklist = (room_id, user_id) => {
        const roomToUpdate = state[room_id];
        if (roomToUpdate) {
            if (!roomToUpdate.black_list.includes(user_id)) {
                roomToUpdate.black_list.push(user_id);
            }
        }
    };

    // Функция для удаления пользователя из черного списка комнаты
    const removeFromRoomBlacklist = (room_id, user_id) => {
        const roomToUpdate = state[room_id];
        if (roomToUpdate) {
            roomToUpdate.black_list = roomToUpdate.black_list.filter(id => id !== user_id);
        }
    };

    const isUserInAnyRoom = useCallback((room_id, user_id, sessionId) => {
        const roomToUpdate = state[room_id];
        console.log(111);
        if (roomToUpdate) {
            const userFound = roomToUpdate.connected_users.find(u => u.id === user_id) || null;
            if (userFound) {
                return userFound.session_id !== sessionId;
            }
        }
        return false;
    }, []);


    const removeUserFromAllRooms = useCallback(() => {
        if (!user.id) return;
        const roomsArray = Object.values(state);

        // Проходим по всем комнатам и удаляем пользователя с заданным userId из connected_users
        for (const room of roomsArray) {
            // Фильтруем массив connected_users, оставляя только тех пользователей, у которых id не совпадает с userId
            room.connected_users = room.connected_users.filter(u => u.id !== user.id);
        }
    }, [user.id]);

    /**
     * Quiz
     */

        // Активировать quiz
    const activateQuiz = (room_id) => {
            const roomToUpdate = state[room_id];
            if (roomToUpdate) {
                roomToUpdate.quiz = {
                    ...roomToUpdate.quiz,
                    step: 'list',
                    type: '',
                    quiz: [],
                    users: [],
                    isActive: true
                };
            }
        };

    // Открыть quiz
    const openQuiz = (room_id, type) => {
        const roomToUpdate = state[room_id];
        if (roomToUpdate) {
            roomToUpdate.quiz = {
                ...roomToUpdate.quiz,
                type,
                step: 'join'
            };
        }
    };


    // Проверить есть ли пользователь в quiz
    const isUserInQuiz = (room_id, user_id) => {
        const roomToUpdate = state[room_id];
        if (roomToUpdate && roomToUpdate.quiz && Array.isArray(roomToUpdate.quiz.users)) {
            return roomToUpdate.quiz.users.some(userInQuiz => userInQuiz.id === user_id);
        }
        return false;
    };

    // Присоединиться к викторине
    const joinToQuiz = (room_id, user) => {
        if (!isUserInQuiz(room_id, user.id)) {
            const roomToUpdate = state[room_id];
            const joinedUser = {
                id: user.id,
                name: user.name,
                avatar: user.avatar,
                numberQuestion: 1,
                score: 0,
                finish: false,
            };
            roomToUpdate.quiz.users.push(joinedUser);
        }
    }

    // Выйти из quiz
    const exitFromQuiz = (room_id, user_id) => {
        const roomToUpdate = state[room_id];
        if (roomToUpdate) {
            roomToUpdate.quiz.users = roomToUpdate.quiz.users.filter(userInQuiz => userInQuiz.id !== user_id);
        }
    }

    //Начать quiz
    const startQuiz = async (room_id, data) => {
        const roomToUpdate = state[room_id];
        if (roomToUpdate) {
            if (roomToUpdate.quiz.users.length === 0) return;
            try {
                roomToUpdate.quiz.step = 'loading';
                const [quizResult] = await Promise.all([
                    getQuizRequest(data),
                    new Promise(resolve => setTimeout(resolve, 1000))
                ]);
                roomToUpdate.quiz = {
                    ...roomToUpdate.quiz,
                    quiz: quizResult,
                    step: 'in-progress'
                };
            } catch (e) {
                roomToUpdate.quiz = {
                    ...roomToUpdate.quiz,
                    step: 'join',
                    quiz: [] // очищаем quiz при ошибке
                };
                throw new Error('Failed to open roulette');
            }
        }
    }

    // Следующий вопрос в quiz
    const nextQuizQuestion = (room_id, user_id, currentScore, finish) => {
        const roomToUpdate = state[room_id];
        if (roomToUpdate) {
            const isAllFinishedInQuiz = roomToUpdate.quiz?.users
                ?.filter(user => user.id !== user_id)
                .every(user => user.finish === true);

            roomToUpdate.quiz = {
                ...roomToUpdate.quiz, // сохраняем существующие свойства
                users: roomToUpdate.quiz.users?.map(currentUser =>
                    currentUser.id === user_id
                        ? {
                            ...currentUser,
                            score: currentUser.score + currentScore,
                            numberQuestion: finish ? currentUser.numberQuestion : currentUser.numberQuestion + 1,
                            finish: finish
                        }
                        : currentUser
                ),
                step: (isAllFinishedInQuiz && finish) ? 'finish' : 'in-progress'
            };
        }
    }

    // Получить номер текущего вопроса quiz
    const getCurrentQuestionQuiz = (room_id, user_id) => {
        const roomToUpdate = state[room_id];
        if (roomToUpdate) {
            return roomToUpdate.quiz.users.find(currentUser => currentUser.id === user_id)?.numberQuestion;
        }
    }

    // Закрыть quiz
    const closeQuiz = (room_id) => {
        const roomToUpdate = state[room_id];
        if (roomToUpdate) {
            roomToUpdate.quiz = {isActive: false};
        }
    }

    /**
     * Roulette
     */
        // Активировать roulette
    const activateRoulette = (room_id) => {
            const roomToUpdate = state[room_id];
            if (roomToUpdate) {
                roomToUpdate.roulette = {
                    ...roomToUpdate.roulette,
                    step: 'list',
                    type: '',
                    items: [],
                    isSpinning: false,
                    result: '',
                    isActive: true
                };
            }
        };


    // Открыть roulette
    const openRoulette = async (room_id, data) => {
        const roomToUpdate = state[room_id];
        if (roomToUpdate) {
            try {
                roomToUpdate.roulette = {
                    ...roomToUpdate.roulette,
                    step: 'loading'
                };

                const [rouletteResult] = await Promise.all([
                    getRouletteRequest(data),
                    new Promise(resolve => setTimeout(resolve, 1000))
                ]);

                roomToUpdate.roulette = {
                    ...roomToUpdate.roulette,
                    step: 'spin',
                    type: data.topic,
                    items: rouletteResult
                };
            } catch (e) {
                roomToUpdate.roulette = {
                    ...roomToUpdate.roulette,
                    step: 'list',
                    type: '',
                    items: []
                };
                throw new Error('Failed to open roulette');
            }
        }
    }

    // Открыть custom roulette
    const openCustomRoulette = (room_id) => {
        const roomToUpdate = state[room_id];
        if (roomToUpdate) {
            roomToUpdate.roulette = {
                ...roomToUpdate.roulette,
                step: 'custom',
                type: 'Custom roulette',
                items: [{option: 'Roulette'}, {option: 'Custom'}]
            };
        }
    };


    // Добавить item в roulette
    const addItemToCustomRoulette = (room_id, message) => {
        const roomToUpdate = state[room_id];
        if (roomToUpdate) {
            const newItem = updatedItem({option: message})
            if (roomToUpdate.roulette.items.length === 2 && roomToUpdate.roulette.items[0].option === "Roulette" && roomToUpdate.roulette.items[1].option === "Custom") {
                roomToUpdate.roulette = {
                    ...roomToUpdate.roulette,
                    items: [newItem]
                };
            } else {
                roomToUpdate.roulette = {
                    ...roomToUpdate.roulette,
                    items: [...roomToUpdate.roulette.items, newItem]
                };
            }
        }
    }

    // Убрать item в roulette
    const removeItemFromCustomRoulette = (room_id, index) => {
        const roomToUpdate = state[room_id];
        if (roomToUpdate) {
            let newItems = [
                ...roomToUpdate.roulette.items.slice(0, index),
                ...roomToUpdate.roulette.items.slice(index + 1)
            ];
            roomToUpdate.roulette = {
                ...roomToUpdate.roulette,
                items: newItems.length === 0 ? [{option: "Roulette"}, {option: "Custom"}] : newItems
            };
        }
    }

    // Открыть custom roulette
    const openUsersRoulette = (room_id) => {
        const roomToUpdate = state[room_id];
        if (roomToUpdate) {
            roomToUpdate.roulette = {
                ...roomToUpdate.roulette,
                step: 'users',
                type: 'Users roulette',
                items: [{option: 'Roulette'}, {option: 'Users'}]
            };
        }
    };


    // Добавить пользователя в users roulette
    const joinToUsersRoulette = (room_id, user_name) => {
        const roomToUpdate = state[room_id];
        if (roomToUpdate) {
            const newItem = updatedItem({option: user_name})
            if (roomToUpdate.roulette.items.length === 2 && roomToUpdate.roulette.items[0].option === "Roulette" && roomToUpdate.roulette.items[1].option === "Users") {
                roomToUpdate.roulette = {
                    ...roomToUpdate.roulette,
                    items: [newItem]
                };
            } else {
                roomToUpdate.roulette = {
                    ...roomToUpdate.roulette,
                    items: [...roomToUpdate.roulette.items, newItem]
                };
            }
        }
    };

    // Убрать пользователя из users roulette
    const exitFromUsersRoulette = (room_id, user_name) => {
        const roomToUpdate = state[room_id];
        if (roomToUpdate) {
            const newItems = roomToUpdate.roulette.items.filter(item => item.option !== user_name);
            roomToUpdate.roulette = {
                ...roomToUpdate.roulette,
                items: newItems.length === 0 ? [{option: "Roulette"}, {option: "Users"}] : newItems,
            };
        }
    }

    // Обработчик запуска roulette
    const startSpinRoulette = (room_id) => {
        const roomToUpdate = state[room_id];
        if (roomToUpdate) {
            if (roomToUpdate.roulette.isSpinning) return;

            roomToUpdate.roulette = {
                ...roomToUpdate.roulette,
                result: Math.floor(Math.random() * roomToUpdate.roulette.items.length),
                isSpinning: true
            };
        }
    };


    // Обработчик остановки roulette
    const stopSpinRoulette = (room_id) => {
        const roomToUpdate = state[room_id];
        if (roomToUpdate) {
            roomToUpdate.roulette = {
                ...roomToUpdate.roulette,
                isSpinning: false
            };
            return roomToUpdate.roulette.result;
        }
    }

    // Закрыть roulette
    const closeRoulette = (room_id) => {
        const roomToUpdate = state[room_id];
        if (roomToUpdate) {
            roomToUpdate.roulette = {isActive: false};
        }
    }

    return (
        <YJSContext.Provider value={{
            //info user
            infoMyFollowing,
            isUserInAnyRoom,
            removeUserFromAllRooms,

            //room
            state,
            addRoom,
            removeRoomById,
            updateRoom,
            updateRoomAccess,
            addUserToRoom,
            removeUserFromRoom,
            addToRoomBlacklist,
            removeFromRoomBlacklist,

            //quiz
            activateQuiz,
            openQuiz,
            isUserInQuiz,
            joinToQuiz,
            exitFromQuiz,
            startQuiz,
            nextQuizQuestion,
            getCurrentQuestionQuiz,
            closeQuiz,

            //roulette
            activateRoulette,
            openRoulette,
            openCustomRoulette,
            addItemToCustomRoulette,
            removeItemFromCustomRoulette,
            startSpinRoulette,
            openUsersRoulette,
            joinToUsersRoulette,
            exitFromUsersRoulette,
            stopSpinRoulette,
            closeRoulette,
        }}>
            {children}
        </YJSContext.Provider>
    );
};
