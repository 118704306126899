import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {Home} from "../pages/Home";
import {Room} from "../pages/Room";
import {RoomProvider} from "../context/RoomContext";
import {Assistant} from "../pages/Assistant";
import {RejoinRoom} from "../pages/RejoinRoom";

export const RouterComponent = () => {
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/assistant" element={<Assistant/>}/>
            <Route path="/room/:id" element={<RoomProvider> <Room/> </RoomProvider>}/>
            <Route path="/rejoin-room" element={<RejoinRoom/>}/>
            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    );
};