import React, {useEffect, useRef, useState} from "react";
import {ArrowDown, ArrowUpCircle, InfoCircle, StopCircle} from "react-bootstrap-icons";
import {addLineBreakIfLongWord} from "../../utils/htmlHelpers";
import {useDispatch, useSelector} from "react-redux";
import {messageToTextAssistantRequest} from "../../axios/assistantChatRequest";
import {addTextMessageToAssistantChat} from "../../redux/actions/assistantChat";
import {DeleteChatTextAssistant} from "../offcanvas/DeleteChatTextAssistant";
import {AnswerRender} from "./AnswerRender";
import {Slide, toast} from "react-toastify";
import {isMobileDevice} from "../../utils/deviceHelpers";
import {IconDeleteChat} from "../Icons/IconDeleteChat";
import {IconAI} from "../Icons/IconAI";
import {ModalDescTextAssistant} from "../modals/ModalDescTextAssistant";

export const TextAssistant = ({caption = false}) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const textChat = useSelector(state => state.assistantChat.data.text);
    const [message, setMessage] = useState('');
    const [waitingAnswer, setWaitingAnswer] = useState(false);
    const messagesEndRef = useRef(null);

    const scrollToBottom = (arg = {}) => {
        messagesEndRef.current.scrollIntoView(arg);
    };

    useEffect(() => {
        const tab = document.getElementById('text-assistant-tab');
        tab?.addEventListener('shown.bs.tab', scrollToBottom);

        return () => {
            tab?.removeEventListener('shown.bs.tab', scrollToBottom);
        };
    }, []);


    useEffect(() => {
        scrollToBottom({behavior: "smooth"});
    }, [textChat]);

    useEffect(() => {
        if (waitingAnswer) {
            scrollToBottom({behavior: "smooth"});
        }
    }, [waitingAnswer]);

    const sendForm = async (e) => {
        if (e) e.preventDefault();
        if (message.trim().length === 0) return;

        if (user.language_id === 1 || !user.language_id) {
            toast.warning('Please select the language you want to learn in your profile settings to proceed.', {
                position: "top-center",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide,
            });
            return;
        }

        try {
            setWaitingAnswer(true);

            const limitedHistory = textChat
                .slice(-5)
                .flatMap(({message, answer}) => [
                    {role: "user", content: message},
                    {role: "assistant", content: answer}
                ]);

            const messageData = {
                language: user.language,
                message: message.trim(),
                history: limitedHistory
            };

            console.log(messageData);

            if (messageData.message.length !== 0) {
                const res = await messageToTextAssistantRequest(messageData);
                dispatch(addTextMessageToAssistantChat(res.data));

                console.log(res);
                setMessage("");
            }
            setWaitingAnswer(false);
        } catch (e) {
            console.log(e)
            setWaitingAnswer(false);
            toast.error('Something went wrong...', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide,
            });
        }
    }

    return (
        <div className={`bg-dark-modal text-assistant-chat ${isMobileDevice() && 'mobile'}`}>
            <div className="d-flex justify-content-between border-bottom p-2">
                <div className="text-white pointer mx-1">
                    <InfoCircle
                        size={28}
                        data-bs-toggle="modal"
                        data-bs-target={`#descTextAssistant`}
                    />
                </div>

                {caption && <p className="text-white mb-0">Text Assistant</p>}

                <div className="pointer"
                     data-bs-toggle="offcanvas" data-bs-target="#offcanvasDeleteChatTextAssistant"
                     aria-controls="offcanvasDeleteChatTextAssistant">
                    <IconDeleteChat color={'tomato'} size={35}/>
                </div>
            </div>
            <div className="text-assistant-list-messages p-2">
                {(textChat.length !== 0)
                    ?
                    (textChat.map((data, index) => (
                        <div key={index}>
                            <div className='chat-message my-message text-end m-2'>
                                    <span className={`d-inline-block p-1`}>
                                        {addLineBreakIfLongWord(data.message, 35)}
                                    </span>
                            </div>
                            <div key={index} className={'chat-message other-message m-2 text-white'}>
                                    <span className={`d-inline-block p-1`}>
                                        <AnswerRender message={addLineBreakIfLongWord(data.answer, 35)}/>
                                    </span>
                            </div>
                        </div>
                    )))
                    : <div
                        className="d-flex justify-content-center align-items-center text-white h-100">
                        <div className="text-center">
                            <IconAI color={'#fff'} size={60}/>
                            <p className="h5 my-2">Text assistant</p>
                            <hr/>
                            <p className="h6">Write to the assistant</p>
                            <p className="h6"><ArrowDown/></p>
                        </div>
                    </div>
                }

                {waitingAnswer && <p className="text-white text-center">One moment, please...</p>}

                <div ref={messagesEndRef}/>
            </div>
            <div className="container text-assistant-block-form">
                <form className="d-flex align-items-center py-1" onSubmit={sendForm}>
                    <input
                        className="form-control bg-dark-input text-white"
                        type="text"
                        placeholder="Type a message"
                        aria-label="default input example"
                        onChange={e => setMessage(e.target.value)}
                        disabled={waitingAnswer}
                        value={message}/>
                    <button type="submit" className={`text-white btn-send-message ${message && 'ready'}`}>
                        {!waitingAnswer ?
                            <ArrowUpCircle size={24}/>
                            :
                            <StopCircle size={24}/>
                        }
                    </button>
                </form>
            </div>

            <ModalDescTextAssistant/>
            <DeleteChatTextAssistant/>
        </div>
    );
}