import {useDispatch, useSelector} from "react-redux";
import React, {useContext, useEffect, useState} from "react";
import {setFollows} from "../redux/actions/follow";
import {WSContext} from "../context/WSContext";
import {setPrivateChats} from "../redux/actions/privateChat";
import {ModalUserInfo} from "./modals/ModalUserInfo";
import {ModalDeleteRoom} from "./modals/ModalDeleteRoom";
import {ModalDebug} from "./modals/ModalDebug";
import {getItemWithExpiry, setItemWithExpiry} from "../utils/localStorage";
import {getAllUsersRequest, getUserAuth} from "../axios/userRequests";
import {loadingFalse, loadingTrue, loginUser} from "../redux/actions/user";
import {AuthProfile} from "./offcanvas/AuthProfile";
import {Social} from "./offcanvas/Social";
import {Slide, toast, ToastContainer} from "react-toastify";
import {setLevels} from "../redux/actions/level";
import {setLanguages} from "../redux/actions/language";
import {setCountries} from "../redux/actions/country";
import {YJSContext} from "../context/YJSContext";
import {useSnapshot} from "valtio";
import {LoadingApp} from "./LoadingApp";
import {useWebSocketP2P} from "../hooks/useWebSocketP2P";
import {getAllFollowsRequest} from "../axios/followRequests";
import {getAllPrivateChatRequest} from "../axios/privateChatRequest";
import {getLanguageLevelCountryRequest} from "../axios/languageLevelCountry";
import {ModalPayment} from "./modals/ModalPayment";
import {useCheckSubscribesTime} from "../hooks/useCheckSubscribesTime";

export const BasicComponent = () => {
    const {ws} = useContext(WSContext);
    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);
    const user = useSelector(state => state.user);
    const { state } = useContext(YJSContext);
    const snap = useSnapshot(state);
    const roomsArray = Object.values(snap);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Функция для предотвращения жестов масштабирования
        const preventZoom = (event) => {
            if (event.scale !== 1) {
                event.preventDefault();
            }
        };

        // Отключаем двойное нажатие и жесты масштабирования
        document.addEventListener('gesturestart', preventZoom);
        document.addEventListener('gesturechange', preventZoom);

        // Очистка событий при размонтировании компонента
        return () => {
            document.removeEventListener('gesturestart', preventZoom);
            document.removeEventListener('gesturechange', preventZoom);
        };
    }, []);

    useEffect(() => {
        const getUserAuthAction = async () => {
            if (getItemWithExpiry('token') && user.id === null) {
                try {
                    dispatch(loadingTrue());
                    const res = await getUserAuth();
                    dispatch(loginUser(res.user));
                    setItemWithExpiry('token', res.token, 2);
                } catch (err) {
                    console.log(err);
                } finally {
                    dispatch(loadingFalse());
                }
            }
        }
        getUserAuthAction();
    }, [user.id, dispatch]);

    useEffect(() => {
        const fetchAllFollow = async () => {
          try {
              const res = await getAllFollowsRequest();
              dispatch(setFollows(res));
          } catch (e) {
              toast.error('Something went wrong...', {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  transition: Slide,
              });
          }
        }
        fetchAllFollow();
    }, [dispatch]);

    useEffect(() => {
        const fetchAllUsers = async () => {
            try {
                const res = await getAllUsersRequest();
                setUsers(res);
            } catch (e) {
                toast.error('Something went wrong...', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide,
                });
            }
        }
        fetchAllUsers();
    }, []);


    useEffect(() => {
        if (ws && user.id) {
            ws.emit("auth-user-connect", user.id);
        }
    }, [ws, user]);

    useEffect(() => {
        if (!user.id) return;
        const fetchAllPrivateChat = async () => {
            try {
                const res = await getAllPrivateChatRequest();
                dispatch(setPrivateChats(res))
            } catch (e) {
                toast.error('Something went wrong...', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide,
                });
            }
        }
        fetchAllPrivateChat();
    }, [user.id, dispatch])

    useEffect(() => {
        const fetchLanguageLevelCountry = async () => {
            try {
                const res = await getLanguageLevelCountryRequest();
                dispatch(setLevels(res.levels));
                dispatch(setLanguages(res.languages));
                dispatch(setCountries(res.countries));
            } catch (e) {
                toast.error('Something went wrong...', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide,
                });
            }
        }
        fetchLanguageLevelCountry();
    } ,[dispatch]);

    useWebSocketP2P();
    useCheckSubscribesTime();


    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div>
            {isLoading && <LoadingApp upText="Speak Stream" downText="Foreign Community" />}

            {roomsArray.map((room) => (
                <div key={room.room_id}>
                    {user.id === room.owner.id && (
                        <ModalDeleteRoom room_id={room.room_id}/>
                    )}
                </div>
            ))}

            {users?.map((user, index) => (
                <ModalUserInfo key={index} userFromRoom={user}/>
            ))}

            {user.id &&
                <>
                    <AuthProfile/>
                    <ModalPayment/>
                    <Social/>
                </>
            }

            <ToastContainer/>


            <ModalDebug/>
        </div>
    );
}