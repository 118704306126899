import './App.css';
import {BrowserRouter} from "react-router-dom";
import {WSProvider} from "./context/WSContext";
import React from "react";
import {RouterComponent} from "./components/RouterComponent";
import {BasicComponent} from "./components/BasicComponent";
import {YJSProvider} from "./context/YJSContext";

function App() {
    return (
        <BrowserRouter>
            <YJSProvider>
                <WSProvider>
                    <BasicComponent/>
                    <RouterComponent/>
                </WSProvider>
            </YJSProvider>
        </BrowserRouter>
    );
}

export default App;