import React from "react";
import {IconAI} from "../Icons/IconAI";
import {IconNoticeBoard} from "../Icons/IconNoticeBoard";
import {IconTyping} from "../Icons/IconTyping";

export const ModalDescTextAssistant = () => {

    return (
        <div className='modal fade' id="descTextAssistant" tabIndex="-1"
             aria-labelledby="descTextAssistant" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
                <div className="modal-content bg-dark-modal border-modal text-white d-flex">
                    <div className="modal-header justify-content-between">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                            <IconAI color={'#fff'} size={30}/> <span className='mx-2'>AI Text Assistant</span>
                        </h1>
                        <div data-bs-theme="dark">
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <p className="text-center h4">How to use this?</p>
                        <div className="text-center">
                            <IconTyping color="#fff" size={60} />
                        </div>
                        <p>
                            You can simply write a message, and you will receive an instant response.
                        </p>
                        <p>
                            Our text-based AI assistant is designed to help you learn a foreign language by
                            providing answers to all your questions and supporting you at every stage of your learning
                            journey. With it, you can:
                        </p>
                        <ul className="list-group list-group-numbered">
                            <li className="list-group-item bg-dark border-dark text-white">
                                Practice conversations by writing dialogues.
                            </li>
                            <li className="list-group-item bg-dark border-dark text-white">
                                Ask questions about grammar and vocabulary.
                            </li>
                            <li className="list-group-item bg-dark border-dark text-white">
                                Receive example sentences and tips on how to use words and expressions.
                            </li>
                            <li className="list-group-item bg-dark border-dark text-white">
                                Learn cultural aspects of the language and improve your knowledge through real-time
                                communication.
                            </li>
                        </ul>
                        <p className="mt-2">
                            Use the text assistant as if you were talking to a real teacher, and receive instant
                            feedback anytime!
                        </p>
                        <hr/>
                        <div className="text-center">
                            <IconNoticeBoard color="#fff" size={80} />
                        </div>
                        <p className="h4 mt-2 mb-4">
                            Features of the text assistant:
                        </p>
                        <ul className="list-group list-group-numbered">
                            <li className="list-group-item bg-dark border-dark text-white">
                                The assistant remembers the last 10 questions from your conversation history, allowing
                                you to build on previous interactions.
                            </li>
                            <li className="list-group-item bg-dark border-dark text-white">
                                To start using the text assistant, you need to specify the language you are learning.
                                This will allow the assistant to tailor its responses to your level and needs.
                            </li>
                        </ul>
                        <hr/>
                        <div className="d-flex justify-content-center">
                            <button type="button" className="btn btn-primary"
                                    data-bs-dismiss="modal">Okay 👍
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}