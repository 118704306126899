import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useAuth} from "../hooks/useAuth";
import {ButtonPurple} from "./buttons/ButtonPurple";
import {BoxArrowInRight} from "react-bootstrap-icons";
import {ButtonAuth} from "./buttons/ButtonAuth";

export const Header = () => {
    const [isContainer, setIsContainer] = useState();
    const user = useSelector(state => state.user);
    const {googleLogin} = useAuth();

    useEffect(() => {

        if (document.documentElement.scrollWidth < 992) {
            setIsContainer(false);
        } else {
            setIsContainer(true);
        }
    }, []);

    return (
        <div className={isContainer ? 'container' : ''}>
            <div className="d-flex justify-content-between align-items-center m-2 border-bottom">
                <div>
                    <img src="https://thumbs.dreamstime.com/b/logo-11348404.jpg" alt="Logo" width="50"/>
                    <h2 className="d-inline m-3 company-name-header text-white font-monospace">Speak-Stream</h2>
                </div>
                {user.id === null ?
                    <div className="width-150px">
                        <ButtonPurple
                            text="Sign In"
                            icon={BoxArrowInRight}
                            iconSize={24}
                            onClick={googleLogin}/>
                    </div>
                    :
                    <ButtonAuth/>
                }
            </div>
        </div>
    );
}