import {calculateTimeDifference} from "../../utils/formatDate";

const initialState = {
    id: null,
    name: "",
    avatar: "",
    language_id: null,
    language: "",
    country_id: null,
    code: null,
    country: null,
    email: "",
    room_subscription_end: null,
    assistant_subscription_end: null,
    room_subscription_time: {value: 0, unit: 'minutes'},
    assistant_subscription_time: {value: 0, unit: 'minutes'},
    created_at: "",
    loading: true,
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_USER':
            return {
                ...state,
                ...action.payload,
                room_subscription_time: calculateTimeDifference(action.payload.room_subscription_end),
                assistant_subscription_time: calculateTimeDifference(action.payload.assistant_subscription_end),
                loading: false
            };
        case 'LOGOUT_USER':
            return {
                ...initialState,
                loading: false
            };
        case 'UPDATE_COUNTRY':
            return {
                ...state,
                country_id: action.payload.id,
                code: action.payload.code,
                country: action.payload.country
            };
        case 'UPDATE_LANGUAGE':
            return {
                ...state,
                language_id: action.payload.id,
                language: action.payload.language,
            };
        case 'UPDATE_ROOM_SUBSCRIPTION_TIME':
            return {
                ...state,
                room_subscription_time: calculateTimeDifference(state.room_subscription_end)
            };
        case 'UPDATE_ROOM_SUBSCRIPTION_END':
            return {
                ...state,
                room_subscription_end: action.payload,
                room_subscription_time: calculateTimeDifference(action.payload)
            };
        case 'UPDATE_ASSISTANT_SUBSCRIPTION_TIME':
            return {
                ...state,
                assistant_subscription_time: calculateTimeDifference(state.assistant_subscription_end)
            };
        case 'UPDATE_ASSISTANT_SUBSCRIPTION_END':
            return {
                ...state,
                assistant_subscription_end: action.payload,
                assistant_subscription_time: calculateTimeDifference(action.payload)
            };
        case 'LOADING_TRUE':
            return {
                ...state,
                loading: true
            };
        case 'LOADING_FALSE':
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};