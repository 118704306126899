import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Bell, BellSlash, PeopleFill, PersonHeart, PersonPlusFill} from "react-bootstrap-icons";
import {ButtonBirch} from "../buttons/ButtonBirch";
import {useFollows} from "../../hooks/useFollows";
import {ButtonPurpleBorder} from "../buttons/ButtonPurleBorder";

export const ModalUserInfo = ({userFromRoom}) => {
    const user = useSelector(state => state.user);
    const follows = useSelector(state => state.follows);
    const [follow, setFollow] = useState(
        {
            id: null,
            followers: [],
            following: [],
            friends: [],
            followers_count: 0,
            following_count: 0,
            friends_count: 0,
            is_following: false,
            is_friend: false
        }
    );
    const {onFollow, unfollow} = useFollows();

    const dataAttributeCreateRoom = () => {
        return (!user.id) ? [
            {key: 'data-bs-toggle', value: 'modal'},
            {key: 'data-bs-target', value: '#ModalForUserNotAuth'}
        ] : [];
    }

    useEffect(() => {
        if (!userFromRoom.id) return;

        if (follows.follows.length > 0) {
            const foundFollow = follows.follows.find(follow => follow.id === userFromRoom.id);
            foundFollow.is_following = (user.id) ? foundFollow.followers.some(follower => follower.id === user.id) : false;
            setFollow(foundFollow);
        }
    }, [follows, user, userFromRoom.id]);

    return (
        <div className='modal fade' id={`userInfoModalId=${userFromRoom.id}`} tabIndex="-1"
             aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-dark-modal p-2">

                    <div className="d-flex justify-content-between border-bottom p-2">
                        <div className="d-flex">
                            <img src={userFromRoom.avatar} className="border-radius-5px" width="80" alt="logo"/>
                            <p className="text-white align-self-end mx-3">{userFromRoom.name}</p>
                        </div>
                        <div className="d-flex align-items-center">
                            {(user.id !== userFromRoom.id) &&
                                ((!follow.is_following) ?
                                        <ButtonPurpleBorder
                                            text="Follow"
                                            icon={Bell}
                                            iconSize={16}
                                            attributes={dataAttributeCreateRoom()}
                                            onClick={() => onFollow(userFromRoom)}
                                        />
                                        :
                                        <ButtonBirch
                                            text="Unfollow"
                                            icon={BellSlash}
                                            iconSize={16}
                                            onClick={() => unfollow(userFromRoom.id)}
                                        />
                                )
                            }
                        </div>
                    </div>

                    <div className="user-info d-flex justify-content-center text-white">
                        <div className="box m-2">
                            <span className="parameter text-danger"><PeopleFill size={18}/></span>
                            <span className="value">{follow.friends_count}</span>
                            <span className="parameter">Friends</span>
                        </div>
                        <div className="box m-2">
                            <span className="parameter text-danger"><PersonHeart size={18}/></span>
                            <span className="value">{follow.followers_count}</span>
                            <span className="parameter">Followers</span>
                        </div>
                        <div className="box m-2">
                            <span className="parameter text-danger"><PersonPlusFill size={18}/></span>
                            <span className="value">{follow.following_count}</span>
                            <span className="parameter">Following</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}