import {ButtonOpenModalDebug} from "./buttons/ButtonOpenModalDebug";
import {useSelector} from "react-redux";
import {ModalCreateRoom} from "./modals/ModalCreateRoom";
import {ButtonPurple} from "./buttons/ButtonPurple";
import {useNavigate} from "react-router-dom";
import {IconCreateRoom} from "./Icons/IconCreateRoom";
import {IconAI} from "./Icons/IconAI";
import {IconManual} from "./Icons/IconManual";

export const Filter = () => {
    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    const bsTargetCreateRoom = () => {
        return (user.id) ? '#createRoomModal' : '#ModalForUserNotAuth';
    }

    const dataAttributeCreateRoom = () => {
        return (!user.id) ? [
            {key: 'data-bs-toggle', value: 'modal'},
            {key: 'data-bs-target', value: '#ModalForUserNotAuth'}
        ] : [];
    }

    const goToAssistantPage = () => {
        if (!user.id) return;
        navigate('/assistant');
    }
    return (
        <header className="row my-3">
            <div className="col-6 col-md-3 col-sm-6 my-1">
                <ButtonPurple
                    text="Create Room"
                    icon={IconCreateRoom}
                    color={'#fff'}
                    iconSize={35}
                    attributes={[
                        {key: 'data-bs-toggle', value: 'modal'},
                        {key: 'data-bs-target', value: bsTargetCreateRoom()},
                    ]}
                    className='not-copy-text'
                />
            </div>
            <div className="col-6 col-md-3 col-sm-6 my-1">
                <ButtonOpenModalDebug/>
            </div>
            <div className="col-6 col-md-3 col-sm-6 my-1">
                <ButtonPurple
                    icon={IconManual}
                    iconSize={24}
                    text="How it Works"
                    attributes={[
                        {key: 'data-bs-toggle', value: 'modal'},
                        {key: 'data-bs-target', value: '#ModalInfoApp'},
                    ]}
                />
            </div>
            <div className="col-6 col-md-3 col-sm-6 my-1">
                <ButtonPurple
                    text="Assistant"
                    icon={IconAI}
                    color={'#fff'}
                    iconSize={25}
                    onClick={goToAssistantPage}
                    attributes={dataAttributeCreateRoom()}
                />
            </div>

            <ModalCreateRoom/>
        </header>
    );
}