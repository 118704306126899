import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {ArrowLeft, ArrowUpCircle, CheckAll, EmojiSmile} from "react-bootstrap-icons";
import {useDispatch, useSelector} from "react-redux";
import http from "../../axios";
import {addMessageToPrivateChat, markMessagesAsRead} from "../../redux/actions/privateChat";
import {WSContext} from "../../context/WSContext";
import {
    formatDateDMOrDMY,
    formatDateHM,
    getCurrentTimestampInLondon,
    isDifferentDay
} from "../../utils/formatDate";
import {saveMessageToPrivateChat} from "../../axios/privateChatRequest";
import {MediaMessagePicker} from "../MediaMessagePicker";
import {addLineBreakIfLongWord} from "../../utils/htmlHelpers";
import {YJSContext} from "../../context/YJSContext";

export const SocialChat = ({isOpen, toggleOpen, chat, userInfo}) => {
    const dispatch = useDispatch();
    const {ws} = useContext(WSContext);
    const user = useSelector(state => state.user);
    const [message, setMessage] = useState('');
    const messagesEndRef = useRef(null);
    const wrapperRef = useRef(null);
    const [showMediaMessagePicker, setShowMediaMessagePicker] = useState(false);
    const {infoMyFollowing} = useContext(YJSContext);

    const isOnline = (user_id) => {
        return infoMyFollowing.some(user => user.user_id === user_id);
    }
    const onEmojiClick = (event) => {
        setMessage(prevInput => prevInput + event.emoji);
    };

    const toggleShowMediaMessagePicker = () => {
        setShowMediaMessagePicker(!showMediaMessagePicker);
    };

    const scrollToBottom = (arg = {}) => {
        messagesEndRef.current.scrollIntoView(arg);
    };

    const sendForm = useCallback(async (e) => {
        if (e) e.preventDefault();

        try {
            const messageData = {
                message: message.trim(),
                type: 'text',
                sender_id: user.id,
                receiver_id: userInfo.id,
                is_read: 0
            }

            if (messageData.message.length !== 0) {
                const res = await saveMessageToPrivateChat(messageData);
                const reduxUser = {
                    id: userInfo.id,
                    name: userInfo.name,
                    avatar: userInfo.avatar,
                    country: userInfo.country
                };
                const reduxMessage = {
                    id: res.data,
                    is_read: 0,
                    type: messageData.type,
                    message: messageData.message,
                    sender_id: user.id,
                    created_at: getCurrentTimestampInLondon(),
                    updated_at: getCurrentTimestampInLondon()
                };
                const userForWS = {
                    id: user.id,
                    name: user.name,
                    avatar: user.avatar,
                    country: user.country
                }
                dispatch(addMessageToPrivateChat(reduxUser, reduxMessage));
                if (ws) {
                    ws.emit("send-private-message", {toId: userInfo.id, user: userForWS, message: reduxMessage});
                }

                setMessage("");
            }
        } catch (e) {
            console.log(e)
        }
    }, [dispatch, message, user.id, user.avatar, user.country, user.name,
        userInfo.avatar, userInfo.country, userInfo.id, userInfo.name, ws])

    const sendMessageGif = async (event) => {
        try {
            const messageData = {
                message: event.url,
                type: 'gif',
                sender_id: user.id,
                receiver_id: userInfo.id,
                is_read: 0
            }
            const res = await saveMessageToPrivateChat(messageData);
            const reduxUser = {
                id: userInfo.id,
                name: userInfo.name,
                avatar: userInfo.avatar,
                country: userInfo.country
            };
            const reduxMessage = {
                id: res.data,
                is_read: 0,
                type: messageData.type,
                message: messageData.message,
                sender_id: user.id,
                created_at: getCurrentTimestampInLondon(),
                updated_at: getCurrentTimestampInLondon()
            };
            const userForWS = {
                id: user.id,
                name: user.name,
                avatar: user.avatar,
                country: user.country
            }
            dispatch(addMessageToPrivateChat(reduxUser, reduxMessage));
            setShowMediaMessagePicker(false);
            if (ws) {
                ws.emit("send-private-message", {toId: userInfo.id, user: userForWS, message: reduxMessage});
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if ((!chat) && message === '👋') {
            sendForm();
        }
    }, [message, chat, sendForm]);

    useEffect(() => {
        if (isOpen) {
            scrollToBottom();
        }
    }, [isOpen]);
    useEffect(() => {
        scrollToBottom({behavior: "smooth"});
    }, [chat]);

    const unreadMessagesFromOthers = useMemo(() => {
        if (!chat || !isOpen) return [];
        return chat
            .filter(message => message.sender_id !== user.id && message.is_read === 0)
            .map(message => message.id);
    }, [chat, isOpen, user.id]);

    useEffect(() => {
        if (!user.id) return;
        if (isOpen && unreadMessagesFromOthers?.length > 0) {
            http.put('private-chat/is-read', {ids: unreadMessagesFromOthers})
                .then((res => {
                    console.log(res);
                    dispatch(markMessagesAsRead(userInfo.id, unreadMessagesFromOthers));
                    if (ws) {
                        ws.emit("mark-messages-as-read", {
                            toId: userInfo.id,
                            user_id: user.id,
                            messageIds: unreadMessagesFromOthers
                        });
                    }
                }))
                .catch((err) => {
                    console.log(err);
                })
        }

    }, [user.id, isOpen, chat, userInfo.id, ws, unreadMessagesFromOthers, dispatch]);

    useEffect(() => {
        // Функция для обработки клика вне элемента
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                toggleOpen();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [toggleOpen]);

    const renderMyMessages = (message, index) => {
        switch (message.type) {
            case 'text':
                return (
                    <div key={index} className='chat-message my-message text-end m-2'>
                        {isDifferentDay(message.created_at, chat, index) &&
                            <p className="text-center">{formatDateDMOrDMY(message.created_at)}</p>
                        }
                        <span className={`d-inline-block p-1`}>
                            {addLineBreakIfLongWord(message.message, 35)}
                            <span className="d-inline-block chat-time-icon">
                                <span>{formatDateHM(message.updated_at)}</span>
                                <span className={`is-read ${(message.is_read) ? 'true' : 'false'}`}><CheckAll
                                    size={20}/></span>
                            </span>
                        </span>
                    </div>
                );
            case 'gif':
                return (
                    <div key={index} className='chat-message text-end m-2'>
                        {isDifferentDay(message.created_at, chat, index) &&
                            <p className="text-center">{formatDateDMOrDMY(message.created_at)}</p>
                        }
                        <div>
                            <img className="gif-message" key={index} src={message.message} alt="gif"/>
                        </div>
                        <div className="my-message">
                            <div className={`d-inline-block p-1`}>
                            <span className="d-inline-block chat-time-icon">
                                 <span>{formatDateHM(message.updated_at)}</span>
                                 <span className={`is-read ${(message.is_read) ? 'true' : 'false'}`}><CheckAll
                                     size={20}/></span>
                            </span>
                            </div>
                        </div>
                    </div>
                );
            default:
                return (
                    <div key={index} className='chat-message my-message text-end m-2'>
                        {isDifferentDay(message.created_at, chat, index) &&
                            <p className="text-center">{formatDateDMOrDMY(message.created_at)}</p>
                        }
                        <span className={`d-inline-block p-1`}>
                            {addLineBreakIfLongWord(message.message, 35)}
                            <span className="d-inline-block chat-time-icon">
                                <span>{formatDateHM(message.updated_at)}</span>
                                <span className={`is-read ${(message.is_read) ? 'true' : 'false'}`}><CheckAll
                                    size={20}/></span>
                            </span>
                        </span>
                    </div>
                );
        }
    }

    const renderOtherMessages = (message, index) => {
        switch (message.type) {
            case 'text':
                return (
                    <div key={index} className={'chat-message other-message m-2'}>
                        {isDifferentDay(message.created_at, chat, index) &&
                            <p className="text-center">{formatDateDMOrDMY(message.created_at)}</p>
                        }
                        <span className={`d-inline-block p-1`}>
                            {addLineBreakIfLongWord(message.message, 35)}
                            <span className="d-inline-block chat-time-icon">
                                <span>{formatDateHM(message.updated_at)}</span>
                            </span>
                        </span>
                    </div>
                );
            case 'gif':
                return (
                    <div key={index} className='chat-message m-2'>
                        {isDifferentDay(message.created_at, chat, index) &&
                            <p className="text-center">{formatDateDMOrDMY(message.created_at)}</p>
                        }
                        <div>
                            <img className="gif-message" key={index} src={message.message} alt={"gif"}/>
                        </div>
                        <div className="other-message">
                            <div className={`d-inline-block p-1`}>
                            <span className="d-inline-block chat-time-icon">
                                 <span>{formatDateHM(message.updated_at)}</span>
                            </span>
                            </div>
                        </div>
                    </div>
                );
            default:
                return (
                    <div key={index} className={'chat-message other-message m-2'}>
                        {isDifferentDay(message.created_at, chat, index) &&
                            <p className="text-center">{formatDateDMOrDMY(message.created_at)}</p>
                        }
                        <span className={`d-inline-block p-1`}>
                            {addLineBreakIfLongWord(message.message, 35)}
                            <span className="d-inline-block chat-time-icon">
                                <span>{formatDateHM(message.updated_at)}</span>
                            </span>
                        </span>
                    </div>
                );
        }
    }

    return (
        <div ref={wrapperRef} className={`bg-dark-modal wrapper-social-chat ${isOpen ? 'show' : ''}`}>
            <div className="border-bottom d-flex align-items-center justify-content-between social-chat-header">
                <div className="pointer btn-back" onClick={toggleOpen}><ArrowLeft size={30}/></div>
                <p className="mx-auto my-0">{userInfo?.name}</p>
                <img
                    src={userInfo.avatar}
                    alt={`Avatar`}
                    className={`img-fluid rounded-circle pointer social-list-people-img ${isOnline(userInfo.id) && 'online'}`}/>
            </div>
            <div className="social-chat-list-messages">
                {(chat)
                    ? chat.map((message, index) => (
                        (message.sender_id === user.id ?
                                renderMyMessages(message, index)
                                :
                                renderOtherMessages(message, index)
                        )
                    ))
                    : <div
                        className="d-flex flex-column justify-content-center align-items-center text-center h-100 no-messages-yet">
                        <p>No messages here yet...</p>
                        <p>Send a message or tap on the greeting below.</p>
                        <p className="pointer" onClick={() => setMessage('👋')}>👋</p>
                    </div>
                }

                <div ref={messagesEndRef}/>
            </div>
            <div className="container chat-block-form">
                <form className="d-flex align-items-center py-1" onSubmit={sendForm}>
                    <div className="me-2 pointer position-relative">
                        <EmojiSmile size={28} color={'silver'} onClick={toggleShowMediaMessagePicker}/>
                        <MediaMessagePicker
                            key={userInfo.id}
                            keyId={userInfo.id}
                            showMediaMessagePicker={showMediaMessagePicker}
                            toggleShowMediaMessagePicker={toggleShowMediaMessagePicker}
                            onEmojiClick={onEmojiClick}
                            sendMessageGif={sendMessageGif}
                        />
                    </div>
                    <input
                        className="form-control bg-dark-input text-white"
                        type="text"
                        placeholder="Type a message"
                        aria-label="default input example"
                        onChange={e => setMessage(e.target.value)}
                        value={message}/>
                    <button type="submit" className={`text-white btn-send-message ${message && 'ready'}`}>
                        <ArrowUpCircle size={24}/>
                    </button>
                </form>
            </div>
        </div>
    );
}