import React from "react";
import {IconAI} from "../Icons/IconAI";
import {IconNoticeBoard} from "../Icons/IconNoticeBoard";
import {IconVoiceAssistant} from "../Icons/IconVoiceAssistant";

export const ModalDescVoiceAssistant = () => {

    return (
        <div className='modal fade' id="descVoiceAssistant" tabIndex="-1"
             aria-labelledby="descVoiceAssistant" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
                <div className="modal-content bg-dark-modal border-modal text-white d-flex">
                    <div className="modal-header justify-content-between">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                            <IconAI color={'#fff'} size={30}/> <span className='mx-2'>AI Voice Assistant</span>
                        </h1>
                        <div data-bs-theme="dark">
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <p className="text-center h4">How to use this?</p>
                        <div className="text-center">
                            <IconVoiceAssistant color="#fff" size={60}/>
                        </div>
                        <p>
                            Simply speak to the assistant, and you will receive an instant voice response.
                        </p>
                        <p>
                            Our voice-based AI assistant is designed to help you learn a foreign language through spoken
                            interactions, providing you with real-time feedback and support throughout your learning
                            journey. With it, you can:
                        </p>
                        <ul className="list-group list-group-numbered">
                            <li className="list-group-item bg-dark border-dark text-white">
                                Practice speaking by having natural conversations in the language you are learning.
                            </li>
                            <li className="list-group-item bg-dark border-dark text-white">
                                Ask questions about grammar and vocabulary.
                            </li>
                            <li className="list-group-item bg-dark border-dark text-white">
                                Talk about any topic you like, allowing for open-ended conversations that help you
                                practice naturally.
                            </li>
                            <li className="list-group-item bg-dark border-dark text-white">
                                Improve your listening skills and comprehension through real spoken exchanges.
                            </li>
                        </ul>
                        <p className="mt-2">
                            Use the voice assistant as if you were having a conversation with a native speaker or tutor,
                            and receive instant feedback to improve your language abilities.
                        </p>
                        <hr/>
                        <div className="text-center">
                            <IconNoticeBoard color="#fff" size={80}/>
                        </div>
                        <p className="h4 mt-2 mb-4">
                            Features of the voice assistant:
                        </p>
                        <ul className="list-group list-group-numbered">
                            <li className="list-group-item bg-dark border-dark text-white">
                                The assistant remembers the last 10 questions from your conversation history, allowing
                                you to build on previous interactions.
                            </li>
                            <li className="list-group-item bg-dark border-dark text-white">
                                To start using the voice assistant, simply specify the language you are learning,
                                allowing it to adapt its responses to your level and specific needs.
                            </li>
                        </ul>
                        <hr/>
                        <div className="d-flex justify-content-center">
                            <button type="button" className="btn btn-primary"
                                    data-bs-dismiss="modal">Okay 👍
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}