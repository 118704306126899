import {ChatDots} from "react-bootstrap-icons";
import {PopoverSocialConfirmUnfollow} from "../PopoverSocialConfirmUnfollow";
import {SocialChat} from "./SocialChat";
import React, {useContext} from "react";
import {YJSContext} from "../../context/YJSContext";

export const SocialPeopleList = ({data, index, toggleOpenChat, openChats, privateChatById, typeChat = 'all'}) => {
    const {infoMyFollowing} = useContext(YJSContext);
    const isOnline = infoMyFollowing.some(user => user.user_id === data.id);

    return (
        <div>
            <div className="d-flex align-items-center social-list-people">
                <div className="me-auto d-flex align-items-center">
                    <img
                        src={data.avatar}
                        alt={`Avatar`}
                        className={`img-fluid rounded-circle pointer social-list-people-img ${isOnline && 'online'}`}
                    />
                    <p className="mb-0 ms-2">{data.name}</p>
                </div>
                <div className="ms-auto d-flex align-items-center">
                    {data.is_friend &&
                        <div className="me-2 pointer" onClick={() => toggleOpenChat(`${typeChat}${index}`)}>
                            <ChatDots size={20}/>
                        </div>
                    }

                    <div className="">
                        <PopoverSocialConfirmUnfollow id={data.id} name={data.name}/>
                    </div>
                </div>
            </div>
            {(data.is_friend && openChats[`${typeChat}${index}`]) &&
                <SocialChat index={`${typeChat}${index}`}
                            isOpen={openChats[`${typeChat}${index}`]}
                            toggleOpen={() => toggleOpenChat(`${typeChat}${index}`)}
                            chat={privateChatById[data.id]?.messages}
                            userInfo={data}
                />
            }
        </div>
    );
}