import http from "./index";

// получить все подписки
export const getAllFollowsRequest = async () => {
    try {
        const res =  await http.get('follows');
        return res.data;
    } catch (err) {
        throw err;
    }
}

// подписаться на пользователя
export const followRequests = async (my_id, follower_id) => {
    try {
        return await http.post('follows', {
            follower_id: my_id,
            following_id: follower_id
        })
    } catch (err) {
        throw err;
    }
}

// отписаться от пользователя по id
export const unfollowRequest = async (my_id, follower_id) => {
    try {
        return await http.delete('follows', {
            data: {
                follower_id: my_id,
                following_id: follower_id
            }
        })
    } catch (err) {
        throw err;
    }
}