export const IconDeleteChat = ({color = '#000000', size = 100}) => {

    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 106.07" width={size} height={size}>
            <title>chat-delete</title>
            <defs>
                <style>{`.cls-1{fill-rule:evenodd;}`}</style>
            </defs>
            <path className="cls-1"
                  d="M71.55,54.18A30.12,30.12,0,0,1,94.8,45.43V9a1.54,1.54,0,0,0-.47-1.1,1.59,1.59,0,0,0-1.1-.48H9A1.64,1.64,0,0,0,7.41,9V75.46a1.57,1.57,0,0,0,.47,1.11l.1.11A1.64,1.64,0,0,0,9,77H23.67a3.71,3.71,0,0,1,3.7,3.71v13L43.65,78.09a3.68,3.68,0,0,1,2.56-1H62.79a30,30,0,0,1,8.76-22.87Zm30.66-7.31A30.07,30.07,0,1,1,64.12,84.45H47.68L26.44,104.83A3.71,3.71,0,0,1,20,102.37V84.45H9a9,9,0,0,1-6.06-2.38l-.28-.26A9,9,0,0,1,0,75.46V9A9,9,0,0,1,9,0H93.23a9,9,0,0,1,9,9V46.87ZM25.43,55.24a3.71,3.71,0,1,1,0-7.41H59.82a3.71,3.71,0,0,1,0,7.41Zm0-21.93a3.71,3.71,0,1,1,0-7.41H76.8a3.71,3.71,0,0,1,0,7.41Zm57.16,36h20.59a2.07,2.07,0,0,1,.5,0,1.45,1.45,0,0,1,.71.38,1.31,1.31,0,0,1,.4.83,1.36,1.36,0,0,1,0,.35L103,89.08v0h0v.1h0c0,.08,0,.16-.06.22h0a1.22,1.22,0,0,1-.1.24,1.41,1.41,0,0,1-1.24.76H84.11l-.3,0a1.26,1.26,0,0,1-.29-.12h0a1.51,1.51,0,0,1-.76-1.21L81,71a1.85,1.85,0,0,1,0-.41,1.42,1.42,0,0,1,.4-.81,1.55,1.55,0,0,1,.75-.39,2.12,2.12,0,0,1,.43,0ZM81,62.58h8V61.9h0v-.06a1.42,1.42,0,0,1,.44-1h0a1.43,1.43,0,0,1,1-.43h4.85a1.52,1.52,0,0,1,1,.43,1.47,1.47,0,0,1,.43,1h0v.68h8a1.16,1.16,0,0,1,.73.34h0a1.2,1.2,0,0,1,.34.8h0V66a.64.64,0,0,1-.64.64H80.48a.64.64,0,0,1-.64-.64h0V63.75h0a.2.2,0,0,1,0-.07h0A1.16,1.16,0,0,1,81,62.58h0Z"
                  fill={color}
            />
        </svg>
    );
}