import React from "react";
import {IconQuiz} from "../Icons/IconQuiz";
import {useFullscreenImage} from "../../hooks/useFullscreenImage";

export const ModalInfoQuiz = () => {
    const {openFullScreen, FullscreenOverlay} = useFullscreenImage();

    return (
        <div className='modal fade' id="infoQuiz" tabIndex="-1"
             aria-labelledby="infoQuiz" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
                <div className="modal-content bg-dark-modal border-modal text-white d-flex">
                    <div className="modal-header justify-content-between">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                            <IconQuiz color={'#fff'} size={30}/> <span className='mx-2'>Quiz</span>
                        </h1>
                        <div data-bs-theme="dark">
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <p className="text-center h4">Speak Stream <span className="brand-color">Quiz</span> Game: Test Your Knowledge and Have Fun!</p>
                        <div>
                            <img
                                src={`${process.env.PUBLIC_URL}/images/info-quiz.png`}
                                alt="Main"
                                className="w-100 my-3 pointer image-border"
                                onClick={() => openFullScreen(`${process.env.PUBLIC_URL}/images/info-quiz.png`)}
                            />
                        </div>
                        <p>
                            The Quiz game on Speak Stream is a fun and competitive way to practice your language skills
                            and broaden your knowledge. It brings players together to test their understanding on a
                            variety of topics while enhancing their learning experience.
                        </p>
                        <hr/>
                        <p className="my-4">
                            How to Play the Quiz Game:
                        </p>
                        <ul className="list-group list-group-numbered">
                            <li className="list-group-item bg-dark border-dark text-white">
                                <span className="brand-color">Choose a Category</span>: Players can start by selecting a
                                category that interests them.
                            </li>
                            <li className="list-group-item bg-dark border-dark text-white">
                                <span className="brand-color">AI-Generated Questions</span>: Once the game starts,
                                artificial intelligence generates 10 questions based on the selected category. Each
                                question comes with four multiple-choice answers to choose from.
                            </li>
                            <li className="list-group-item bg-dark border-dark text-white">
                                <span className="brand-color">Time Limit</span>: Players have 20 seconds to answer each
                                question.
                            </li>
                            <li className="list-group-item bg-dark border-dark text-white">
                                <span className="brand-color">Scoring System</span>: The faster a player answers
                                correctly, the more points they earn. Speed and accuracy are both key, rewarding those
                                who think quickly and confidently.
                            </li>
                            <li className="list-group-item bg-dark border-dark text-white">
                                <span className="brand-color">Winning the Game</span>: At the end of the quiz, the
                                player with the highest score is declared the winner. It’s a great way to challenge
                                friends or other learners and see who comes out on top!
                            </li>
                        </ul>
                        <p className="mt-4">
                            The Quiz game makes learning enjoyable and engaging. It encourages players to think on their
                            feet, apply their knowledge, and have fun while practicing their language skills.
                        </p>
                        <hr/>
                        <div className="d-flex justify-content-center">
                            <button type="button" className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#gamesRoom">Okay 👍
                            </button>
                        </div>

                        {FullscreenOverlay}
                    </div>
                </div>
            </div>
        </div>
    );
}