import {useDispatch, useSelector} from "react-redux";
import React, {useRef} from "react";
import {deleteChatVoiceAssistantRequest} from "../../axios/assistantChatRequest";
import {deleteVoiceAssistantChat} from "../../redux/actions/assistantChat";
import {Slide, toast} from "react-toastify";
import {IconAI} from "../Icons/IconAI";

export const DeleteChatVoiceAssistant = () => {
    const dispatch = useDispatch();
    const offcanvasRef = useRef(null);
    const user = useSelector(state => state.user);
    const voiceChat = useSelector(state => state.assistantChat.data.voice);
    const deleteChat = async () => {
        try {
            if (voiceChat.length === 0) {
                offcanvasRef.current.click();
                return;
            }

            await deleteChatVoiceAssistantRequest(user.id);
            dispatch(deleteVoiceAssistantChat());
        } catch (e) {
            console.log(e)
            toast.error('Something went wrong...', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide,
            });
        }
        offcanvasRef.current.click();
    }

    return (
        <div
            className="offcanvas offcanvas-top offcanvas-size-delete-assistant bg-dark-modal text-white border-modal"
            tabIndex="-1" id="offcanvasDeleteChatVoiceAssistant"
            aria-labelledby="offcanvasDeleteChatVoiceAssistant">
            <div className="offcanvas-body">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="profile-header d-flex align-items-end">
                        <IconAI size={30} color={'#fff'}/> <span className='mx-2'>Voice Assistant</span>
                    </div>
                    <div data-bs-theme="dark">
                        <button ref={offcanvasRef} type="button" className="btn-close" data-bs-dismiss="offcanvas"
                                aria-label="Close"></button>
                    </div>
                </div>
                <hr/>
                <div className="center bg-dark-modal d-flex flex-column justify-content-center align-items-center">
                    <p className="text-center">Do you really want to delete chat?</p>
                    <div className="text-center">
                        <button type="button" className="btn btn-danger" onClick={deleteChat}>Delete</button>
                    </div>
                </div>
            </div>
        </div>
    )
}