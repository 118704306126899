import {PersonPlus} from "react-bootstrap-icons";

export const ButtonOpenModalDebug = () => {
    return (
        <div className="d-inline">
            <button
                className="w-100 btn-dark-default btn-be-purple"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#ModalDebugApp"
            >
                <PersonPlus size={24} /> Debug
            </button>
        </div>
    );
}