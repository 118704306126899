export const LoadingApp = ({upText = '', downText = ''}) => {

    return (
        <div className="loader-app">
            <div className="up-text-wrapper">
                <p className="text-anime-to-up text-center">{upText}</p>
            </div>
            <div className="loader-outer">
                <div className="loader-inner">
                </div>
            </div>
            <div className="down-text-wrapper">
                <p className="text-anime-to-down text-center">{downText}</p>
            </div>
        </div>
    );
}