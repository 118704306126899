import React from 'react';

export const QuizScoreboard = React.memo(({users, currentUser}) => (
    <div className="d-flex justify-content-center">
        <div className="wrapper-wight-content">
            {users
                .slice()
                .sort((a, b) => b.score - a.score)
                .map((user, index) => (
                    <div
                        className={`d-flex align-items-center invite-user-content p-2 text-white border-bottom ${
                            user.id === currentUser.id && "quiz-progress-score-me"
                        }`}
                        key={user.id}
                    >
                        <div className="border-right mx-1">
                            <p className="position-quiz text-center">{index + 1}</p>
                        </div>
                        <img src={user.avatar} alt="avatar" className="me-2"/>
                        <p className="mb-0 mx-2">{user.name}</p>
                        <p className="mb-0">{user.score}</p>
                    </div>
                ))}
        </div>
    </div>
));
