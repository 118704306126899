import {useDispatch, useSelector} from "react-redux";
import React, {useCallback, useContext, useEffect} from "react";
import {followUser, unfollowUser} from "../redux/actions/follow";
import {addMessageToPrivateChat, deletePrivateChat, markMessagesAsRead} from "../redux/actions/privateChat";
import {WSContext} from "../context/WSContext";
import {Slide, toast} from "react-toastify";
import {BoxArrowLeft} from "react-bootstrap-icons";
import {useNavigate} from "react-router-dom";

/**
 * Обработчик ws событий для p2p
 */
export const useWebSocketP2P = () => {
    const navigate = useNavigate();
    const {ws} = useContext(WSContext);
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    const joinRoom = useCallback((room) => {
        navigate(`/room/${room.room_id}`);
    }, [navigate]);

    useEffect(() => {
        if (!ws || !user.id) return;

        // Обработчик когда пользователь подписался
        ws.on('followed-user', ({follower, following}) => {
            console.log("follow user", follower, following);
            dispatch(followUser(follower, following));
        });

        // Обработчик когда пользователь отписался
        ws.on('unfollowed-user', ({follower_id, following_id}) => {
            console.log("unfollow user", follower_id, following_id);
            dispatch(unfollowUser(follower_id, following_id));
        });

        // Обработчик для принятых личных сообщений.
        ws.on('sent-private-message', ({user, message}) => {
            console.log("new private message: ", user, message);
            dispatch(addMessageToPrivateChat(user, message));
        });

        // Обработчик для сообщений помеченных как прочитанное.
        ws.on('marked-messages-as-read', ({user_id, messageIds}) => {
            console.log("mark Messages As Read: ", user_id, messageIds);
            dispatch(markMessagesAsRead(user_id, messageIds));
        });

        // Обработчик для удаления личного чата
        ws.on('deleted-private-chat', ({user_id}) => {
            console.log("deleted private chat: ", user_id);
            dispatch(deletePrivateChat(user_id));
        });

        // Обработчик для приглашения пользователя в комнату
        ws.on('invite-user', ({room, user}) => {
            toast.info(
                <div>
                    {user.name} invited you into the room.{' '}
                    <span className='text-underline text-silver' onClick={() => joinRoom(room)}>
                         {'  '} <BoxArrowLeft/> Join
                    </span>
                </div>,
                {
                    position: 'bottom-right',
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                    transition: Slide,
                }
            );
        });

        return () => {
            if (ws) {
                ws.off('followed-user');
                ws.off('unfollowed-user');
                ws.off('sent-private-message');
                ws.off('marked-messages-as-read');
                ws.off('deleted-private-chat');
                ws.off('invite-user');
            }
        };
    }, [ws, user.id, joinRoom, dispatch]);
}