import React, {useContext, useState} from "react";
import {YJSContext} from "../context/YJSContext";
import {useSelector} from "react-redux";
import {getAllUsersWithFriendshipAndRoomStatus} from "../redux/selectors/followsSelectors";
import {Check} from "react-bootstrap-icons";

export const ListFollowingForPayment = ({selectedFollow, setSelectedFollow}) => {
    const user = useSelector(state => state.user);
    const {myFollowing} = useSelector(state => getAllUsersWithFriendshipAndRoomStatus(state, user.id));
    const [searchTerm, setSearchTerm] = useState('');
    const {infoMyFollowing} = useContext(YJSContext);
    const isOnline = (user_id) => {
        return infoMyFollowing.some(user => user.user_id === user_id);
    }

    // Фильтрация по имени
    const filteredFollows = myFollowing
        ?.filter(person =>
            person.name?.toLowerCase().includes(searchTerm.toLowerCase())
        )

    return (
        <div className="mx-2">
            {myFollowing &&
                <form className="d-flex" role="search" onSubmit={(e) => e.preventDefault()}>
                    <input
                        className="form-control me-2 m-2 bg-dark-input text-white"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </form>
            }
            <div className="wrapper-follows-list-for-payment">
                {myFollowing
                    ?
                    filteredFollows.map((person) => (
                        <div className={`d-flex align-items-center social-list-people pointer
                        ${selectedFollow?.id === person.id && 'border-selected-person'}`}
                             key={person.id} onClick={() => setSelectedFollow(person)}>
                            <div className="me-auto d-flex align-items-center">
                                <img
                                    src={person.avatar}
                                    alt={`Avatar`}
                                    className={`img-fluid rounded-circle social-list-people-img ${isOnline(person.id) && 'online'}`}
                                />
                                <div>
                                    <p className={`mb-0 ms-2 social-list-people-name`}>{person.name}</p>
                                </div>

                            </div>

                            {selectedFollow?.id === person.id && <Check color={'green'} size={30}/>}

                        </div>
                    ))
                    :
                    <p className="text-center my-2">
                        You're not following anyone.
                    </p>
                }
            </div>
        </div>
    );
}