import React from "react";
import {useSelector} from "react-redux";
import {Search} from "react-bootstrap-icons";
import {selectPopularLanguages} from "../redux/selectors/languageSelectors";

export const FilterRoom = ({selectedLanguage, setSelectedLanguage, selectedLevel, setSelectedLevel}) => {
    const levels = useSelector(state => state.levels);
    const languages = useSelector(state => state.languages);
    const popularLanguages = useSelector(selectPopularLanguages);

    return (
        <div className="d-flex border-bottom my-2 p-2">
            <div className="d-flex align-items-center">
                <Search color={'#fff'} size={25} />
            </div>
            <div className="mx-1">
                <select
                    className={`form-select text-white bg-dark-input pointer`}
                    aria-label="language-select"
                    value={selectedLanguage}
                    name="language"
                    onChange={(e) => setSelectedLanguage(e.target.value)}
                >
                    <option value="Any">Any Language</option>

                    <optgroup label="Popular Languages" className="brand-color">
                        {popularLanguages.map((lang, index) => (
                            <option key={index} value={lang.language}
                                    className="text-white">{lang.language}</option>
                        ))}
                    </optgroup>

                    <optgroup label="All Languages" className="brand-color">
                        {languages.map((lang, index) => (
                            index > 0 && (
                                <option key={index} value={lang.language}
                                        className="text-white">{lang.language}</option>
                            )
                        ))}
                    </optgroup>
                </select>
            </div>

            <div className="mx-1">
                <select
                    className={`form-select text-white bg-dark-input pointer`}
                    aria-label="level-select"
                    value={selectedLevel}
                    name="level"
                    onChange={(e) => setSelectedLevel(e.target.value)}
                >
                    <option value="Any">Any Level</option>
                    {/* Опция для "Any" */}
                    {levels.map((lvl, index) => (
                        <option key={index} value={lvl.level}>{lvl.level}</option>
                    ))}
                </select>
            </div>
        </div>
    )
}