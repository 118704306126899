import {googleLogout, useGoogleLogin} from '@react-oauth/google';
import {loginUser, logoutUser} from "../redux/actions/user";
import {setItemWithExpiry} from "../utils/localStorage";
import {useDispatch, useSelector} from "react-redux";
import {useContext} from "react";
import {WSContext} from "../context/WSContext";
import {userAuth, userLogout} from "../axios/userRequests";
import {useNavigate} from "react-router-dom";

export const useAuth = (additionalSuccessAction) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    const {ws} = useContext(WSContext);

    const googleLogin = useGoogleLogin({
        flow: 'auth-code',
        onSuccess: async (codeResponse) => {
            try {
                const res = await userAuth(codeResponse.code);
                dispatch(loginUser(res.user));
                setItemWithExpiry('token', res.token, 2);
                if (additionalSuccessAction) {
                    additionalSuccessAction();
                }
            } catch (err) {
                console.log(err);
            }
        },
        onError: errorResponse => console.log(errorResponse),
    });

    const logOut = async () => {
        try {
            await userLogout();
            if (ws) {
                ws.emit('auth-disconnect', {id: user.id});
            }
            if (additionalSuccessAction) {
                additionalSuccessAction();
            }
            googleLogout();
            dispatch(logoutUser());
            localStorage.removeItem('token');
            navigate('/');
        } catch (err) {
            console.log(err);
        }
    };

    return {googleLogin, logOut};

}