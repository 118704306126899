import {useSelector} from "react-redux";
import React, {useContext} from "react";
import {YJSContext} from "../../context/YJSContext";

export const QuizList = ({room_id}) => {
    const quizTypes = useSelector(state => state.quizTypes);
    const {openQuiz} = useContext(YJSContext);

    return (
        <div className="d-flex justify-content-center">
            <div className="wrapper-list-quizzes my-3">
                {quizTypes.map((type, index) => (
                    <p key={index} className="list-roulette pointer" onClick={() => openQuiz(room_id, type)}>
                        {index + 1}. {type.type}
                    </p>
                ))}
            </div>
        </div>
    );
}