import { useState } from 'react';

export const useFullscreenImage = () => {
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [isVisible, setIsVisible] = useState(false);

    const openFullScreen = (imageUrl) => {
        setCurrentImage(imageUrl);
        setIsFullscreen(true);
        setTimeout(() => setIsVisible(true), 10);
    };

    const closeFullScreen = () => {
        setIsVisible(false);
        setTimeout(() => {
            setIsFullscreen(false);
            setCurrentImage(null);
        }, 300);
    };

    const FullscreenOverlay = isFullscreen && (
        <div
            className={`fullscreen-overlay ${isVisible ? 'visible' : ''}`}
            onClick={closeFullScreen}
        >
            <img
                src={currentImage}
                alt="Fullscreen"
                className={`fullscreen-image ${isVisible ? 'visible' : ''}`}
            />
        </div>
    );

    return { openFullScreen, FullscreenOverlay };
}
