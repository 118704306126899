import React from "react";
import {EmojiGrin, FiletypeGif} from "react-bootstrap-icons";
import GifPicker from "gif-picker-react";
import EmojiPicker from "emoji-picker-react";

export const MediaMessagePicker = ({
                                       keyId,
                                       showMediaMessagePicker,
                                       toggleShowMediaMessagePicker,
                                       onEmojiClick,
                                       sendMessageGif
                                   }) => {

    return (
        <div className={`bg-dark-modal wrapper-emoji-gif ${showMediaMessagePicker && 'show'}`}>
            <ul className="nav nav-pills border-bottom d-flex justify-content-between align-items-center" id="pills-tab"
                role="tablist">
                <div className="d-flex">
                    <li className="nav-item" role="presentation">
                        <p className="social-nav-link pointer active" id={`social-emoji-tab-${keyId}`}
                           data-bs-toggle="pill"
                           data-bs-target={`#pills-social-emoji-${keyId}`} role="tab"
                           aria-controls={`pills-social-emoji-${keyId}`}
                           aria-selected="true"><EmojiGrin/> Emoji
                        </p>
                    </li>
                    <li className="nav-item" role="presentation">
                        <p className="social-nav-link pointer" id={`social-gif-tab-${keyId}`} data-bs-toggle="pill"
                           data-bs-target={`#pills-social-gif-${keyId}`} role="tab"
                           aria-controls={`pills-social-gif-${keyId}`}
                           aria-selected="false"><FiletypeGif/> Gif
                        </p>
                    </li>
                </div>
                <button type="button" className="btn-close" aria-label="Close"
                        onClick={toggleShowMediaMessagePicker}></button>
            </ul>

            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id={`pills-social-emoji-${keyId}`} role="tabpanel"
                     aria-labelledby={`social-emoji-tab-${keyId}}`} tabIndex="0">
                    <EmojiPicker
                        onEmojiClick={onEmojiClick}
                        emojiStyle={'native'}
                        lazyLoadEmojis={true}
                        width={360}
                        height={405}
                        theme={'dark'}
                    />
                </div>
                <div className="tab-pane fade" id={`pills-social-gif-${keyId}`} role="tabpanel"
                     aria-labelledby={`social-gif-tab-${keyId}`}
                     tabIndex="0">
                    <GifPicker
                        tenorApiKey={process.env.REACT_APP_GIF_API_KEY}
                        onGifClick={sendMessageGif}
                        width={360}
                        height={405}
                        theme={'dark'}
                    />
                </div>
            </div>
        </div>
    );
}