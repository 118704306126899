import {QuestionCircle, Trash3, XCircle} from "react-bootstrap-icons";
import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {deletePrivateChat} from "../redux/actions/privateChat";
import {WSContext} from "../context/WSContext";
import {deletePrivateChatRequest} from "../axios/privateChatRequest";
import {Slide, toast} from "react-toastify";

export const PopoverConfirmDeleteChat = ({id, name}) => {
    const dispatch = useDispatch();
    const {ws} = useContext(WSContext);
    const user = useSelector(state => state.user);
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const handleDeletePrivateChat = async () => {
        try {
            const data = {
                myId: user.id,
                otherId: id,
            }
            await deletePrivateChatRequest(data);
            dispatch(deletePrivateChat(id));
            if (ws) {
                ws.emit("delete-private-chat", {toId: id, user_id: user.id});
            }
        } catch (e) {
            toast.error('Something went wrong...', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide,
            });
        }
    }


    useEffect(() => {
        if (!id) return;
        const handleOutsideClick = (event) => {
            // Проверяем, было ли кликнуто вне popover
            const classClosest = '.social-pop-container' + id;
            if (isOpen && !event.target.closest(classClosest)) {
                setIsOpen(false);
            }
        };

        // Добавляем обработчик события для клика по всему документу
        document.addEventListener('click', handleOutsideClick);

        return () => {
            // Удаляем обработчик события при размонтировании компонента
            document.removeEventListener('click', handleOutsideClick);

        };
    }, [isOpen, id]);

    return (
        <div className={`ms-auto d-flex align-items-center social-pop-container${id}`}>
            <div className="pointer" onClick={toggleOpen}>
                <Trash3 size={20} color={'tomato'}/>
            </div>
            <div className={`border-bottom wrapper-social-popover ${isOpen ? 'show' : ''}`}>
                <div className="d-flex h-100">
                    <div className="popover-content-block d-flex flex-column text-center p-1">
                        <p className="border-bottom">Do you want to delete the chat?</p>
                        <p>The chat will be deleted for you and <b>{name}</b>.</p>
                        <div className="mt-auto">
                            <button onClick={toggleOpen} className="" type="button">
                                <XCircle size={20}/>
                            </button>
                            <button className="" type="button" onClick={handleDeletePrivateChat}>
                                <Trash3 size={20}/>
                            </button>
                        </div>
                    </div>
                    <div className="popover-icon-block text-center text-white">
                        <QuestionCircle size={40}/>
                    </div>
                </div>
            </div>
        </div>
    );
}