export const IconManual = ({color = '#000000', size = 100}) => {

    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 116.42 122.88"
            style={{ enableBackground: 'new 0 0 116.42 122.88' }}
            xmlSpace="preserve"
            width={size} height={size}
        >
            <style type="text/css">
                {`
        .st0 { fill-rule: evenodd; clip-rule: evenodd; }
      `}
            </style>
            <g>
                <path
                    className="st0"
                    d="M11.98,100.05l0.29-52.03H6.4v66.21c8.07-2.08,16.17-3.41,24.33-3.46c7.53-0.05,15.07,1,22.61,3.56 c-4.69-3.8-9.92-6.59-15.5-8.41c-7.03-2.29-14.63-3.05-22.42-2.38c-1.76,0.14-3.3-1.17-3.45-2.92 C11.96,100.41,11.96,100.23,11.98,100.05L11.98,100.05L11.98,100.05z M58.21,0c15.66,0,28.55,11.92,30.09,27.17 c4.24-0.01,10.6,0.21,13.53,0.64c1.59,0.23,2.72,1.6,2.72,3.16l0,0v10.66h0.01h8.66c1.76,0,3.19,1.43,3.19,3.19v73.57 c0,1.77-1.43,3.2-3.19,3.2c-0.36,0-0.71-0.06-1.04-0.17c-8.91-2.55-17.75-4.24-26.52-4.3c-8.54-0.06-17.08,1.45-25.66,5.23 c-0.53,0.35-1.16,0.54-1.82,0.54c-0.64,0.01-1.28-0.18-1.82-0.54c-8.57-3.79-17.11-5.29-25.66-5.23c-8.76,0.06-17.6,1.75-26.52,4.3 c-0.32,0.11-0.67,0.17-1.03,0.17C1.43,121.59,0,120.17,0,118.4V44.82c0-1.76,1.43-3.19,3.19-3.19h9.1l0.06-10.67 c0.01-1.54,1.09-2.8,2.54-3.11l0,0c2.76-0.58,9.14-0.92,13.26-0.99C29.84,11.75,42.65,0,58.21,0L58.21,0L58.21,0z M88.28,33.57 c-1.54,14.08-12.74,25.25-26.83,26.76v47.9c5.42-3.62,10.96-6.59,16.65-8.62c6.49-2.31,13.16-3.41,20.07-2.86V45.3l0,0 c-0.02-0.15-0.04-0.32-0.04-0.48c0-0.16,0.01-0.32,0.04-0.48V33.8C96.11,33.6,91.87,33.52,88.28,33.57L88.28,33.57z M55.06,60.34 C40.81,58.86,29.5,47.49,28.11,33.21c-3.48,0.01-7.46,0.16-9.4,0.43l-0.36,63.3c7.4-0.26,14.64,0.67,21.45,2.9 c5.42,1.77,10.55,4.35,15.26,7.77l0,0V60.34L55.06,60.34z M64.55,113.84c7.06-2.22,14.11-3.13,21.15-3.09 c8.16,0.05,16.26,1.39,24.33,3.47v-66.2h-5.47v52.32c0,1.77-1.43,3.19-3.19,3.19c-0.26,0-0.5-0.03-0.74-0.08 c-6.99-1.1-13.77-0.19-20.38,2.17C74.93,107.52,69.7,110.36,64.55,113.84L64.55,113.84L64.55,113.84z M67.06,48.36h-17.7v-2.98 l3.33,0v-18.2h-3.33v-2.37h8.27c2.09,0,4.15-0.25,6.18-0.76v21.33h3.25V48.36L67.06,48.36L67.06,48.36z M59.4,10.38 c1.37,0,2.45,0.4,3.24,1.18c0.78,0.78,1.17,1.86,1.17,3.24c0,1.4-0.67,2.65-2.02,3.74c-1.35,1.09-2.93,1.64-4.71,1.64 c-1.35,0-2.41-0.38-3.23-1.13c-0.82-0.76-1.23-1.75-1.23-2.98c0-1.55,0.67-2.88,2-4.01C55.94,10.94,57.54,10.38,59.4,10.38 L59.4,10.38L59.4,10.38z"
                    fill={color}
                />
            </g>
        </svg>
    );
}