import http from "./index";

// получить всех пользователей
export const getAllUsersRequest = async () => {
    try {
        const res =  await http.get('users');
        return res.data;
    } catch (err) {
        throw err;
    }
}

// аутентифицировать пользователя
export const userAuth = async (code) => {
    try {
        const res = await http.post('auth', {code: code});
        return res.data;
    } catch (err) {
        throw err;
    }
}


// разлогинить пользователя
export const userLogout = async () => {
    try {
        const res = await http.post('users/logout');
        return res.data;
    } catch (err) {
        throw err;
    }
}

// получить аутентифицированного пользователя
export const getUserAuth = async () => {
    try {
        const res = await http.get('get-user-auth');
        return res.data;
    } catch (err) {
        throw err;
    }
}

// обновить страну пользователя
export const updateUserCountryRequest = async (country_id) => {
    try {
        const res = await http.put('user-country', country_id);
        return res.data;
    } catch (err) {
        throw err;
    }
}

// обновить изучаемый язык пользователя
export const updateUserLanguageRequest = async (language_learning_id) => {
    try {
        const res = await http.put('user-language', language_learning_id);
        return res.data;
    } catch (err) {
        throw err;
    }
}

// купить room подписку
export const buyRoomSubscriptionRequest = async (user_id) => {
    try {
        const res = await http.post('users/room-subscription', {user_id: user_id});
        return res.data;
    } catch (err) {
        throw err;
    }
}

// купить assistant подписку
export const buyAssistantSubscriptionRequest = async (data) => {
    try {
        const res = await http.post('users/assistant-subscription', data);
        return res.data;
    } catch (err) {
        throw err;
    }
}
