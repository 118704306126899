import {Header} from "../components/Header";
import {Filter} from "../components/Filter";
import React, {useContext, useEffect} from "react";
import {WSContext} from "../context/WSContext";
import {ModalForUserNotAuth} from "../components/modals/ModalForUserNotAuth";
import {ModalInfoApp} from "../components/modals/ModalInfoApp";
import {useCloseModal} from "../hooks/useCloseModal";
import {ListRooms} from "../components/ListRooms";
import {ButtonSocial} from "../components/buttons/ButtonSocial";

export const Home = () => {
    const {ws} = useContext(WSContext);
    useCloseModal();

    useEffect(() => {
        if (ws) {
            ws.emit("home-connect");
        }
    }, [ws]);

    useEffect(() => {
        return () => {
            if (ws) {
                ws.emit("home-leave-home-page");
            }
        };
    }, [ws]);

    return (
        <div className='container'>
            <Header/>
            <Filter/>
            <ListRooms/>

            <ButtonSocial addClass={"position-home-social-button"}/>

            <ModalForUserNotAuth/>
            <ModalInfoApp/>
        </div>
    );
};