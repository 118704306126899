import React, {useEffect, useRef, useState} from "react";
import {IconAI} from "../Icons/IconAI";
import {useFullscreenImage} from "../../hooks/useFullscreenImage";
import {IconAllRooms} from "../Icons/IconAllRooms";
import {IconConnectedPeople} from "../Icons/IconConnectedPeople";
import {IconGames} from "../Icons/IconGames";
import {EnvelopeAt} from "react-bootstrap-icons";

export const ModalInfoApp = () => {
    const sections = useRef({});
    const [activeSection, setActiveSection] = useState('info-app-main');
    const {openFullScreen, FullscreenOverlay} = useFullscreenImage();

    useEffect(() => {
        const sectionIds = ['info-app-main', 'info-app-rooms', 'info-app-assistant', 'info-app-messenger', 'info-app-contact'];

        sectionIds.forEach(id => {
            sections.current[id] = document.getElementById(id);
        });

        const observer = new IntersectionObserver(
            (entries) => {
                const visibleEntries = entries
                    .filter(entry => entry.isIntersecting)
                    .sort((a, b) => a.boundingClientRect.top - b.boundingClientRect.top);

                if (visibleEntries.length > 0) {
                    setActiveSection(visibleEntries[0].target.id);
                }
            },
            {threshold: 0.5, rootMargin: '0px 0px -50% 0px'}
        );

        // Локальная копия sections.current для очистки
        const currentSections = {...sections.current};

        // Наблюдаем за каждым разделом
        sectionIds.forEach(id => {
            observer.observe(currentSections[id]);
        });

        return () => {
            // Отменяем наблюдение при размонтировании
            sectionIds.forEach(id => {
                observer.unobserve(currentSections[id]);
            });
        };
    }, []);

    return (
        <div className='modal fade' id="ModalInfoApp" tabIndex="-1"
             aria-labelledby="ModalInfoApp" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
                <div className="modal-content bg-dark-modal border-modal text-white d-flex">
                    <div className="modal-header justify-content-between">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                            <IconAI color={'#fff'} size={30}/> <span className='mx-2'>Welcome to <span
                            className="brand-color">Speak Stream!</span></span>
                        </h1>
                        <div data-bs-theme="dark">
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                    </div>
                    <div className="modal-header px-0">
                        <div id="simple-list-example"
                             className="d-flex flex-wrap simple-list-example-scrollspy">
                            <a className={`social-nav-link ${activeSection === 'info-app-main' ? 'active' : ''}`}
                               href={"#info-app-main"}>Main</a>
                            <a className={`social-nav-link ${activeSection === 'info-app-rooms' ? 'active' : ''}`}
                               href={"#info-app-rooms"}>Rooms</a>
                            <a className={`social-nav-link ${activeSection === 'info-app-assistant' ? 'active' : ''}`}
                               href={"#info-app-assistant"}>Assistant</a>
                            <a className={`social-nav-link ${activeSection === 'info-app-messenger' ? 'active' : ''}`}
                               href={"#info-app-messenger"}>Messenger</a>
                            <a className={`social-nav-link ${activeSection === 'info-app-contact' ? 'active' : ''}`}
                               href={"#info-app-contact"}>Contact</a>
                        </div>
                    </div>
                    <div data-bs-spy="scroll" data-bs-target="#simple-list-example" data-bs-offset="0"
                         data-bs-smooth-scroll="true" className="modal-body scrollspy-example" tabIndex="0">
                        <p className="text-center h4" id="info-app-main">How to use this?</p>
                        <div>
                            <img
                                src={`${process.env.PUBLIC_URL}/images/main.png`}
                                alt="Main"
                                className="w-100 my-3 pointer image-border"
                                onClick={() => openFullScreen(`${process.env.PUBLIC_URL}/images/main.png`)}
                            />
                        </div>
                        <p>
                            <span className="brand-color">Speak Stream</span> is an innovative platform for learning
                            and practicing foreign languages through
                            live communication with people from all over the world. You can easily create a room for
                            audio or video communication, specifying your proficiency level and the language you
                            want to
                            practice. All rooms are displayed on the main page, and any user can join, or you can
                            make
                            your room private and invite friends for a more personal experience.
                        </p>
                        <hr/>
                        <p className="h4">Features of Speak Stream:</p>
                        <p className="h4 brand-color my-4" id="info-app-rooms">
                            <IconAllRooms color={'#fff'} size={20}/> Rooms
                        </p>
                        <div>
                            <img
                                src={`${process.env.PUBLIC_URL}/images/room.png`}
                                alt="Main"
                                className="w-100 my-3 pointer image-border"
                                onClick={() => openFullScreen(`${process.env.PUBLIC_URL}/images/room.png`)}
                            />
                        </div>
                        <p className="my-3">
                            Create rooms for language practice: Communicate with people who want to learn the same
                            language in open or private rooms. Practicing becomes more engaging when you can share
                            your knowledge with others.
                        </p>
                        <ul className="list-group list-group-numbered my-4">
                            <li className="list-group-item bg-dark border-dark text-white">
                                <span className="brand-color">Audio, video, screen sharing</span>: Enjoy real-time
                                communication and collaboration with audio, video calls, and screen sharing to enhance
                                your learning experience.
                            </li>
                            <li className="list-group-item bg-dark border-dark text-white">
                                <span className="brand-color">Shared chat</span>: Each room includes a shared chat
                                to facilitate communication during practice sessions.
                            </li>
                            <li className="list-group-item bg-dark border-dark text-white">
                                <span className="brand-color">Video watching</span>: Enjoy the ability to co-watch
                                videos with other participants, making learning more interactive and engaging.
                            </li>
                            <li className="list-group-item bg-dark border-dark text-white">
                                <span className="brand-color">Whiteboard</span>: An interactive whiteboard is available
                                for drawing and sketching, which helps visualize concepts, diagrams, or even play
                                drawing games to make language practice more immersive.
                            </li>
                            <li className="list-group-item bg-dark border-dark text-white">
                                <span className="brand-color">Etherpad</span>: A collaborative text editor that allows
                                users to create and edit texts in real time. It’s perfect for group writing exercises,
                                drafting content together, or working on language assignments.
                            </li>
                        </ul>
                        <p className="my-3">
                            <span style={{marginRight: '10px'}} className="align-top">
                                <IconGames color={'#fff'} size={30}/>
                            </span>
                            <span className="h5 brand-color">Interactive games</span>: Play language-based games that
                            make learning fun and collaborative.
                        </p>
                        <ul className="list-group list-group-numbered my-4">
                            <li className="list-group-item bg-dark border-dark text-white">
                                <span className="brand-color">Quizzes</span>: AI-generated quizzes on topics that
                                interest you, helping you learn through an enjoyable adventure.
                            </li>
                            <li className="list-group-item bg-dark border-dark text-white">
                                <span className="brand-color">Roulettes</span>: Experience three types of
                                roulettes — AI-generated questions, random participant selection, or the ability to
                                add your own topics.
                            </li>
                        </ul>
                        <hr/>
                        <p className="h4 brand-color my-4" id="info-app-assistant">
                            <IconAI color={'#fff'} size={25}/> AI-powered assistants
                        </p>
                        <div>
                            <img
                                src={`${process.env.PUBLIC_URL}/images/assistant.png`}
                                alt="Main"
                                className="w-100 my-3 pointer image-border"
                                onClick={() => openFullScreen(`${process.env.PUBLIC_URL}/images/assistant.png`)}
                            />
                        </div>
                        <ul className="list-group list-group-numbered">
                            <li className="list-group-item bg-dark border-dark text-white">
                                <span className="brand-color">Text Assistant</span>: Use the text assistant for
                                questions and language exercises. It is designed to help you practice grammar,
                                expand vocabulary, and answer language-related questions, making your learning
                                experience more efficient.
                            </li>
                            <li className="list-group-item bg-dark border-dark text-white">
                                <span className="brand-color">Voice Assistant</span>: Speak with the voice assistant
                                to improve your speaking skills. The voice assistant acts like a real teacher,
                                helping you engage in live conversation and providing the experience of having a
                                true mentor.
                            </li>
                        </ul>
                        <hr/>
                        <p className="h4 brand-color my-4" id="info-app-messenger">
                            <IconConnectedPeople color={'#fff'} size={25}/> Messenger
                        </p>
                        <div className="d-flex">
                            <img
                                src={`${process.env.PUBLIC_URL}/images/messenger-people.png`}
                                alt="Main"
                                className="w-50 my-3 pointer image-border"
                                onClick={() => openFullScreen(`${process.env.PUBLIC_URL}/images/messenger-people.png`)}
                            />
                            <img
                                src={`${process.env.PUBLIC_URL}/images/messenger-messages.png`}
                                alt="Main"
                                className="w-50 my-3 pointer image-border"
                                onClick={() => openFullScreen(`${process.env.PUBLIC_URL}/images/messenger-messages.png`)}
                            />
                        </div>
                        <p>
                            Messenger and subscriptions: Add friends, follow users, and send direct messages.
                            Connect with those who share your interests and stay in touch for joint language
                            sessions.
                        </p>
                        <hr/>
                        <p>
                            Speak Stream is your world of limitless communication, making the process of learning a
                            language exciting and productive. Join, create rooms, invite friends, and learn together
                            in a fun and effective way!
                        </p>
                        <hr/>
                        <p className="h4 brand-color my-4" id="info-app-contact">
                            <EnvelopeAt color={'#fff'} size={25}/> Contact
                        </p>
                        <p>
                            If you have any questions or need support, feel free to reach out at
                            <a href="mailto:example@gmail.com" className="brand-color mx-2">example@gmail.com</a>.
                        </p>

                        <hr/>
                        <div className="d-flex justify-content-center">
                            <button type="button" className="btn btn-primary"
                                    data-bs-dismiss="modal">Let's go 👍
                            </button>
                        </div>

                        {FullscreenOverlay}
                    </div>
                </div>
            </div>
        </div>
    );
}