import React, {useContext} from "react";
import {RoomContext} from "../../context/RoomContext";
import {RouletteList} from "./RouletteList";
import {RouletteLoading} from "./RouletteLoading";
import {RouletteSpin} from "./RouletteSpin";
import {RouletteUsers} from "./RouletteUsers";
import {RouletteCustom} from "./RouletteCustom";
import {YJSContext} from "../../context/YJSContext";

export const Roulette = ({room_id, roulette, room_language}) => {
    const {localShowRoulette, setLocalShowRoulette} = useContext(RoomContext);
    const {closeRoulette} = useContext(YJSContext);

    const renderRouletteContent = () => {
        if (roulette.step === 'list') {
            return <RouletteList room_id={room_id} room_language={room_language}/>
        } else if (roulette.step === 'loading') {
            return <RouletteLoading/>
        } else if (roulette.step === 'spin') {
            return <RouletteSpin room_id={room_id} roulette={roulette}/>
        } else if (roulette.step === 'users') {
            return <RouletteUsers room_id={room_id} roulette={roulette}/>
        } else if (roulette.step === 'custom') {
            return <RouletteCustom room_id={room_id} roulette={roulette}/>
        }
    }

    return (
        <React.Fragment>
            <div className={`wrapper-quiz-active ${localShowRoulette && 'show'}`}>
                <div className="header-quiz d-flex justify-content-between">
                    <div className="m-2">
                        <button type="button"
                                className="btn btn-outline-secondary"
                                onClick={() => setLocalShowRoulette(false)}>
                            Hide
                        </button>
                    </div>

                    <div>
                        <p className="quiz-name text-white">{roulette.type ? roulette.type : 'Roulette'}</p>
                    </div>

                    <span className="m-2">
                        <button type="button" className="btn btn-danger"
                                onClick={() => closeRoulette(room_id)}>Close</button>
                    </span>
                </div>
                <div className="content-quiz overflow-auto border-bottom">
                    {renderRouletteContent()}
                </div>
            </div>
        </React.Fragment>
    );
}