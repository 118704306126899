import React, {useContext} from "react";
import {useSelector} from "react-redux";
import {YJSContext} from "../../context/YJSContext";

export const QuizFinish = ({room_id, quiz}) => {
    const user = useSelector(state => state.user);
    const {activateQuiz} = useContext(YJSContext);

    return (
        <div>
            <div className="text-center my-2">
                <button className="btn btn-primary width-250px" type="button" onClick={() => activateQuiz(room_id)}>Go
                    to list
                </button>
            </div>
            {quiz.users
                .slice() // Создаем копию массива
                .sort((a, b) => b.score - a.score) // Сортируем по убыванию score
                .map((currentUser, index) => {
                    if (index === 0) {
                        return (
                            <div key={currentUser.id} className="d-flex justify-content-center">
                                <div className="bg-success text-center wrapper-wight-content">
                                    <h3 className="text-white">Winner</h3>
                                    <div
                                        className={`d-flex justify-content-center align-items-center invite-user-content p-2 text-white`}
                                    >
                                        <img src={currentUser.avatar} alt="avatar" className="me-2"/>
                                        <p className="mb-0 mx-2">{currentUser.name}</p>
                                        <p className="mb-0">{currentUser.score}</p>
                                    </div>
                                    <hr/>
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <div key={currentUser.id} className="d-flex justify-content-center my-2">
                                <div
                                    className={`wrapper-wight-content d-flex align-items-center invite-user-content p-2 text-white border-bottom ${currentUser.id === user.id && 'quiz-progress-score-me'}`}
                                >
                                    <div className="border-right mx-1">
                                        <p className="position-quiz text-center">{index + 1}</p>
                                    </div>
                                    <img src={currentUser.avatar} alt="avatar" className="me-2"/>
                                    <p className="mb-0 mx-2">{currentUser.name}</p>
                                    <p className="mb-0">{currentUser.score}</p>
                                </div>
                            </div>
                        );
                    }
                })}
        </div>
    );
}