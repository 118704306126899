import http from "./index";

// получить languages, levels, countries
export const getLanguageLevelCountryRequest = async () => {
    try {
        const res =  await http.get('languages-and-levels');
        return res.data;
    } catch (err) {
        throw err;
    }
}