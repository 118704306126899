import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {ButtonPurple} from "../components/buttons/ButtonPurple";
import {IconRejoin} from "../components/Icons/IconRejoin";
import {IconGoToHome} from "../components/Icons/IconGoToHome";

export const RejoinRoom = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const redirected = localStorage.getItem('redirectedToRejoin');
        if (redirected !== 'true') {
            navigate('/');
        }

        return () => {
            localStorage.removeItem('redirectedToRejoin');
        };
    }, [navigate]);

    return (
        <div className="wrapper-rejoin h-100 w-100 d-flex flex-column justify-content-center align-items-center">

            <p className="text-white text-center h2">Speak Stream</p>

            <p className="text-white text-center my-4 h2">
                <hr/>
                You have been temporarily disconnected from the room.
            </p>
            <div className="d-flex m-4 w-100 rejoin-buttons">
                <div className="d-flex flex-column align-items-center w-100 mx-2">
                    <IconRejoin color={'#fff'} size={60}/>
                    <ButtonPurple
                        onClick={() => navigate(-1)}
                        text="Return to Room"
                        className="my-2"
                    />
                </div>

                <div className="d-flex flex-column align-items-center w-100 mx-2">
                    <IconGoToHome color={'#fff'} size={60}/>
                    <ButtonPurple
                        onClick={() => navigate('/')}
                        text="Go to Home"
                        className="my-2"
                    />
                </div>
            </div>
        </div>
    );
}