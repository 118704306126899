import {ButtonPurple} from "../buttons/ButtonPurple";
import {Mic, MicMute, TelephoneX} from "react-bootstrap-icons";
import {AIIcons} from "../AIIcons";
import {isMobileDevice} from "../../utils/deviceHelpers";

export const ConversationVoiceAssistant = ({
                                               isShowConversation,
                                               setIsShowConversation,
                                               stream,
                                               setStream,
                                               microphone,
                                               voiceAnswer,
                                               setAudioUrl,
                                               isAssistantSays,
                                               setIsAssistantSays,
                                               waitingAnswer,
                                               isError,
                                               setIsError,
                                               startRecording,
                                               stopRecording

                                           }) => {

    const stopCall = () => {
        try {
            if (isError.isError) {
                setIsError({isError: false, message: ''})
            }
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
                setStream(null);
            }
            setAudioUrl(null);
            stopRecording(true);
            setIsShowConversation(false);
            setIsAssistantSays(false);
            voiceAnswer.current?.pause();
            voiceAnswer.current = null;
        } catch (e) {
            console.error(e);
        }
    }

    const renderCurrentAction = () => {
        if (isError.isError) {
            return (
                <>
                    <p className="h5 text-danger text-center my-4">{isError.message}</p>
                    <AIIcons icon={'stop'}/>
                </>
            )
        }
        if (isAssistantSays) {
            return (
                <>
                    <p className="h3 text-white text-center my-4">Let me tell you this 😊</p>
                    <AIIcons icon={'listening'}/>
                </>
            )
        }

        if (microphone) {
            return (
                <>
                    <p className="h3 text-white text-center my-4">Speak Now</p>
                    <AIIcons icon={'spinning'}/>
                </>
            )
        }
        if (waitingAnswer) {
            return (
                <>
                    <p className="h3 text-white text-center">One moment, please...</p>
                    <p className="h3 text-white text-center">Thinking...</p>
                    <AIIcons icon={'pondering'}/>
                </>
            )
        }
        if (!microphone && !waitingAnswer) {
            return (
                <>
                    <p className="h3 text-white text-center my-4">Awaiting Your Voice</p>
                    <AIIcons icon={'stop'}/>
                </>
            )
        }
    }

    return (
        <div className={`wrapper-conversation ${isShowConversation && 'show'}`}>
            <div className="d-flex justify-content-center text-white my-3">
                <div>
                    <p className="text-center h1">AI Voice Assistant</p>
                    <hr/>
                    <p className="text-center h2">Conversation</p>
                </div>
            </div>
            <div className="d-grid justify-content-center align-items-center h-50">
                <div>
                    {renderCurrentAction()}
                </div>

            </div>

            <div className="button-container-conversation">
                <div className="w-100 mx-3">
                    {microphone ?
                        <ButtonPurple
                            text={`${isMobileDevice() ? 'Mic On' : 'Microphone On'}`}
                            icon={Mic}
                            iconSize={22}
                            onClick={stopRecording}/>
                        :
                        <ButtonPurple
                            text={`${isMobileDevice() ? 'Mic Off' : 'Microphone Off'}`}
                            icon={MicMute}
                            iconSize={22}
                            color={'#DC3545FF'}
                            disabled={waitingAnswer}
                            className={'text-danger'}
                            onClick={startRecording}/>
                    }

                </div>
                <div className="w-100 mx-3">
                    <ButtonPurple
                        text={"End Call"}
                        icon={TelephoneX}
                        iconSize={22}
                        color={'#DC3545FF'}
                        className={'text-danger'}
                        onClick={stopCall}/>
                </div>
            </div>
        </div>
    );
}