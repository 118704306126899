const initialState = {
    data: {
        text: [],
        voice: []
    },
    check: false
};

export const assistantChatReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ASSISTANT_CHATS':
            return {
                ...state,
                data: {
                    text: action.payload.text,
                    voice: action.payload.voice
                },
                check: true,

            };

        case 'ADD_TEXT_MESSAGE_TO_ASSISTANT_CHAT':
            return {
                ...state,
                data: {
                    ...state.data,
                    text: [...state.data.text, action.payload.data]
                }
            };

        case 'ADD_VOICE_MESSAGE_TO_ASSISTANT_CHAT':
            return {
                ...state,
                data: {
                    ...state.data,
                    voice: [...state.data.voice, action.payload.data]
                }
            };

        case 'DELETE_TEXT_ASSISTANT_CHAT': {
            return {
                ...state,
                data: {
                    ...state.data,
                    text: []
                }
            };
        }

        case 'DELETE_VOICE_ASSISTANT_CHAT': {
            return {
                ...state,
                data: {
                    ...state.data,
                    voice: []
                }
            };
        }


        default:
            return state;
    }
};