import http from "./index";

// получить чаты от text и voice assistant
export const getAssistantChatByUserIdRequest = async () => {
    try {
        const res = await http.get('assistant');
        return res.data;
    } catch (err) {
        throw err;
    }
}

// отправить сообщение к text assistant
export const messageToTextAssistantRequest = async (messageData) => {
    try {
        return await http.post('assistant/text', messageData)
    } catch (err) {
        throw err;
    }
}

// удалить чат с text assistant
export const deleteChatTextAssistantRequest = async () => {
    try {
        return await http.delete('assistant/text')
    } catch (err) {
        throw err;
    }
}

// отправить сообщение к voice assistant
export const messageToVoiceAssistantRequest = async (data) => {
    try {
        return await http.post('assistant/voice', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            responseType: 'blob',
        })
    } catch (err) {
        throw err;
    }
}

// удалить чат с voice assistant
export const deleteChatVoiceAssistantRequest = async () => {
    try {
        return await http.delete('assistant/voice')
    } catch (err) {
        throw err;
    }
}