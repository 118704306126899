import React, {useMemo} from 'react';
import {PopoverOwnRoom} from "./PopoverOwnRoom";
import {BoxArrowInLeft, ShieldLock, Trash} from "react-bootstrap-icons";
import {useSelector} from "react-redux";
import {ButtonPurple} from "./buttons/ButtonPurple";
import {useNavigate} from "react-router-dom";
import {Slide, toast} from "react-toastify";
import {truncateWithEllipsis} from "../utils/htmlHelpers";
import {Tooltip} from "react-tooltip";

export const Card = React.memo(({room}) => {
    const navigate = useNavigate();
    const user = useSelector(state => state.user);

    const isShowIconDeleteRoom = () => {
        return user.id === room.owner.id;
    }

    const isBan = useMemo(() => room.black_list.includes(user.id), [room.black_list, user.id]);
    const isPrivate = useMemo(() => (room.owner.id !== user.id && !room.access), [room.owner.id, user.id, room.access]);
    const isFull = useMemo(() => (room.quantity !== 0 && room.connected_users.length >= room.quantity), [room.quantity, room.connected_users]);


    const dataAttributeCreateRoom = () => {
        return (!user.id) ? [
            {key: 'data-bs-toggle', value: 'modal'},
            {key: 'data-bs-target', value: '#ModalForUserNotAuth'}
        ] : [];
    }
    const joinToRoom = () => {
        if (user.id) {
            if (isBan) {
                toast.warn('The owner of the room has banned you and blocked access to the room.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Slide,
                });
                console.log(room);
            } else if (isPrivate) {
                toast.warn('This is a private room.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Slide,
                });
            } else if (isFull) {
                toast.warn('The room is full.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Slide,
                });
            } else {
                localStorage.setItem('roomAnime', true);
                navigate(`/room/${room.room_id}`);
            }
        }
    }

    if (!room) {
        return null;
    }
    return (
        <div className="col">
            <div className="card-room text-white d-flex flex-column">
                <div className="header-card-room border-bottom">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            <h3>{room.language}</h3>
                            <span className="m-2">{room.level}</span>
                        </div>
                        <div className="d-flex">
                            {isShowIconDeleteRoom() && (
                                <div className="m-1">
                                    <Trash
                                        className="pointer"
                                        color={"tomato"}
                                        size={24}
                                        data-bs-toggle="modal"
                                        data-bs-target={`#confirmRoomDeleteModalId=${room.room_id}`}
                                    />
                                </div>
                            )}
                            <div className="m-1">
                                <PopoverOwnRoom owner={room.owner} created_at={room.created_at}/>
                            </div>
                        </div>
                    </div>
                    <p data-tooltip-id={`room-desc-${room.room_id}`}
                       data-tooltip-content={room.description}
                    >{truncateWithEllipsis(room.description, 34)}</p>
                    <Tooltip id={`room-desc-${room.room_id}`}/>
                </div>
                <div className="d-flex flex-wrap align-items-center card-room-users">
                    {room.connected_users.length !== 0 ?
                        (room.connected_users.map((userFromRoom, index) => (
                            <div key={index}>
                                <img src={userFromRoom.avatar} alt={`Avatar ${index + 1}`}
                                     className="img-fluid rounded-circle pointer m-1 modal-border"
                                     data-bs-toggle="modal"
                                     data-bs-target={`#userInfoModalId=${userFromRoom.id}`}
                                />
                            </div>
                        )))
                        :
                        <div className="w-100">
                            <p className="text-center">Speak Stream</p>
                        </div>
                    }
                </div>
                <div className="d-flex justify-content-between align-items-center m-1 mt-auto">
                    <span>{room.connected_users.length}/{room.quantity === 0 ? 'Unlimited' : room.quantity}</span>
                    <div className="d-flex align-items-center">
                        {isPrivate &&
                            <>
                                <ShieldLock color={'#42A5FFE2'}
                                            size={30}
                                            className="mx-2"
                                            data-tooltip-id="private-room"
                                            data-tooltip-content="This is a private room."/>
                                <Tooltip id="private-room"/>
                            </>
                        }
                        <div className="width-150px">
                            <ButtonPurple
                                text="Join"
                                icon={BoxArrowInLeft}
                                iconSize={24}
                                onClick={joinToRoom}
                                attributes={dataAttributeCreateRoom()}
                                className={((isBan || isPrivate || isFull) ? 'not-allowed' : '')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
