import {useFullscreenImage} from "../../hooks/useFullscreenImage";
import React from "react";
import {ArrowRepeat} from "react-bootstrap-icons";

export const ModalInfoRoulette = () => {

    const {openFullScreen, FullscreenOverlay} = useFullscreenImage();

    return (
        <div className='modal fade' id="infoRoulette" tabIndex="-1"
             aria-labelledby="infoRoulette" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
                <div className="modal-content bg-dark-modal border-modal text-white d-flex">
                    <div className="modal-header justify-content-between">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                            <ArrowRepeat size={26}/> <span className='mx-2'>Roulette</span>
                        </h1>
                        <div data-bs-theme="dark">
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <p className="text-center h4">Speak Stream <span className="brand-color">Roulette</span>: Spin
                            Your Way to Fun and Learning!</p>
                        <div>
                            <img
                                src={`${process.env.PUBLIC_URL}/images/info-roulette.png`}
                                alt="Main"
                                className="w-100 my-3 pointer image-border"
                                onClick={() => openFullScreen(`${process.env.PUBLIC_URL}/images/info-roulette.png`)}
                            />
                        </div>
                        <p>
                            The Roulette game on Speak Stream adds an element of surprise and creativity to your
                            language practice. There are three types of roulettes, each offering a unique way to learn,
                            interact, and have fun with others.
                        </p>
                        <hr/>
                        <p className="my-4">
                            Types of Roulettes:
                        </p>
                        <ul className="list-group list-group-numbered">
                            <li className="list-group-item bg-dark border-dark text-white">
                                <span className="brand-color">AI Question Roulette</span>: In this type, users can
                                select
                                a topic, and artificial intelligence will generate 10 questions based on that topic. The
                                roulette is then filled with these questions, and each time it spins, a random question
                                is chosen. Players can take turns answering the questions, or you can make up your own
                                rules to create a different type of game.
                            </li>
                            <li className="list-group-item bg-dark border-dark text-white">
                                <span className="brand-color">User Roulette</span>: In this version, participants can
                                add themselves to the roulette wheel. When spun, the roulette will randomly select one
                                of the participants. This type is perfect for choosing who goes next, assigning roles,
                                or just adding a fun random element to your session
                            </li>
                            <li className="list-group-item bg-dark border-dark text-white">
                                <span className="brand-color">Custom Roulette</span>: The third type allows users to add
                                their own questions or any values they wish. The roulette will then spin and pick a
                                random value. This is perfect for adding a personal touch to the game, whether you want
                                to challenge your friends, create a fun scenario, or come up with your own creative game
                                ideas.
                            </li>
                        </ul>
                        <p className="mt-4">
                            The Speak Stream Roulette is designed to make language practice entertaining and
                            unpredictable. Whether you want to test your knowledge, add some randomness to your
                            conversations, or just have fun with friends, the roulette offers a versatile way to keep
                            everyone engaged and entertained. Give it a spin and see where it takes you!
                        </p>
                        <hr/>
                        <div className="d-flex justify-content-center">
                            <button type="button" className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#gamesRoom">Okay 👍
                            </button>
                        </div>

                        {FullscreenOverlay}
                    </div>
                </div>
            </div>
        </div>
    );
}